import {IExt} from "../../../ext-types/Ext";
import {ExtWindow} from "../../../ext-types/window/ExtWindow";
import {IGIS} from "../../../igis-base";
import {ProjectInfo} from "@igis-common/model/ProjectInfo";
import {ExtFormFieldDate} from "../../../ext-types/form/field/ExtFormFieldDate";
import {ExtButton} from "../../../ext-types/button/ExtButton";
import {ExtToolbar} from "../../../ext-types/toolbar/ExtToolbar";
import {Report} from "@igis-common/model/Report";
import {DateTime, DateTimeUnit} from "luxon";
import {ExtFormFieldCheckbox} from "../../../ext-types/form/field/ExtFormFieldCheckbox";
import {ExtFormFieldContainer} from "../../../ext-types/form/ExtFormFieldContainer";

declare var Ext: IExt;

const COMP_IDS = {
  DATE_FROM: Ext.id(),
  DATE_TO: Ext.id(),
  DATE_PANEL: Ext.id(),
  DATE_MENU_BUTTON: Ext.id(),
  MALFUNCTION: Ext.id(),
  BATCH: Ext.id(),
  ITEM_CNT_TEXT: Ext.id()
}

import * as pathIconTimePreset from '../../../icons/mTime.png';
import {ExtToolbarTextItem} from "../../../ext-types/toolbar/ExtToolbarTextItem";

/**
 * General dialog/window configuration
 */
const ReportFilterDialogDefinition = {
  xtype: 'window',
  layout: 'fit',
  title: 'Bericht-Optionen',
  minWidth: 100,
  modal: true,
  closeAction: 'hide',
  buttonAlign: 'left',
  fbar: [
    { xtype: 'button', text: 'Bericht erstellen' },
    { xtype: 'tbtext', id:COMP_IDS.ITEM_CNT_TEXT, flex: 1}
  ],
  items: [{
    xtype: 'form',
    fieldDefaults: {
      labelWidth: 110,
      labelStyle: 'white-space: nowrap;'
    },
    layout: {
      type: 'vbox',
      align: 'stretch'
    },
    bodyPadding: 10,
    items: [{
      xtype: 'fieldcontainer',
      id: COMP_IDS.DATE_PANEL,
      //fieldLabel: 'Datum',
      layout: {
        type: 'hbox',
        align: 'middle'
      },
      items: [{
        xtype: 'datefield',
        hideLabel: true,
        width: 110,
        id: COMP_IDS.DATE_FROM
      }, {
        xtype: 'label',
        text: ' bis '
      }, {
        xtype: 'datefield',
        width: 110,
        hideLabel: true,
        id: COMP_IDS.DATE_TO
      }, {
        xtype: 'button',
        hideLabel: true,
        icon: pathIconTimePreset,
        tooltip: 'Vorauswahl',
        id: COMP_IDS.DATE_MENU_BUTTON
      }]
    }, {
      xtype: 'checkbox',
      id: COMP_IDS.BATCH,
      fieldLabel: 'in Bearbeitung'
    }, {
      xtype: 'checkbox',
      id: COMP_IDS.MALFUNCTION,
      fieldLabel: 'Mangelliste'
    }]
  }]
}

export class ReportFilterWindow {
  private readonly dlg: ExtWindow;
  private readonly dateFrom: ExtFormFieldDate;
  private readonly dateTo: ExtFormFieldDate;
  private readonly dateButton: ExtButton;
  private readonly ckMalfunction: ExtFormFieldCheckbox;
  private readonly ckBatch: ExtFormFieldCheckbox;
  private readonly datePanel: ExtFormFieldContainer;

  private readonly itemCntLabel: ExtToolbarTextItem;

  private report: Report;

  constructor(private igis: IGIS) {
    this.dlg = <ExtWindow>igis.gui.Ext.create(ReportFilterDialogDefinition);
    // get references to ui components and initialize them
    const Ext = this.igis.gui.Ext;

    this.dateFrom = <ExtFormFieldDate>Ext.getCmp(COMP_IDS.DATE_FROM);
    this.dateTo = <ExtFormFieldDate>Ext.getCmp(COMP_IDS.DATE_TO);
    this.dateButton = <ExtButton>Ext.getCmp(COMP_IDS.DATE_MENU_BUTTON);
    this.ckMalfunction = <ExtFormFieldCheckbox>Ext.getCmp(COMP_IDS.MALFUNCTION);
    this.ckBatch = <ExtFormFieldCheckbox>Ext.getCmp(COMP_IDS.BATCH);
    this.datePanel = <ExtFormFieldContainer>Ext.getCmp(COMP_IDS.DATE_PANEL);
    this.itemCntLabel = <ExtToolbarTextItem>Ext.getCmp(COMP_IDS.ITEM_CNT_TEXT);

    this.setupListeners();


    // set button handler
    // get handle to toolbar
    const toolbar = <ExtToolbar>this.dlg.getDockedItems('toolbar[dock="bottom"]')[0];
    const saveButton = <ExtButton>toolbar.child('button'); // There should be only one child
    saveButton.setHandler(this.onSaveClick, this);

    this.createDateMenu(Ext);
  }

  private setupListeners() {

    this.dateFrom.addListener('change', () => { this.dateListener()});
    this.dateTo.addListener('change', () => { this.dateListener()});

    this.ckMalfunction.addListener('change', () => { this.ckListener() })
    this.ckBatch.addListener('change', () => { this.ckListener() })
  }

  private ckListener() {
    if (this.ckMalfunction.getValue() || this.ckBatch.getValue()) {
      this.dateFrom.setDisabled(true);
      this.dateTo.setDisabled(true);
      this.dateTo.setValue(undefined);
      this.dateFrom.setValue(undefined);
      this.dateButton.setDisabled(true);
    } else {
      this.dateFrom.setDisabled(false);
      this.dateTo.setDisabled(false);
      this.dateButton.setDisabled(false);
    }
    this.anyChangeListener();
  }

  private dateListener() {
    if (this.dateFrom.getValue() || this.dateTo.getValue()) {
      this.ckMalfunction.setValue(0);
      this.ckMalfunction.setDisabled(true);
      this.ckBatch.setValue(0);
      this.ckBatch.setDisabled(true);
    } else {
      this.ckMalfunction.setDisabled(false);
      this.ckBatch.setDisabled(false);
    }
    this.anyChangeListener();
  }

  private anyChangeListener() {
    const filter = this.extractFilter();
    const layer = this.report.layer;
    if (layer) {
      this.igis.countReportParams(this.report.id, layer.id, filter).then(itemCnt => {
        console.log('item count: ' + itemCnt);
        if (itemCnt !== null) {
          this.itemCntLabel.setHtml("Einträge: " + itemCnt);
        }
      })
    }

  }

  private createDateMenu(Ext: IExt) {
    const menu = {
      xtype: 'menu',
      items: [{
        text: 'alles',
        handler: (item, event) => {
          this.dateFrom.setValue(undefined);
          this.dateTo.setValue(undefined);
        }
      }, {
        xtype: 'menuseparator'
      }, {
        text: 'aktuelles Jahr',
        handler: (item, event) => {
          this.setDateValue(0, 'year');
        }
      }, {
        text: 'voriges Jahr',
        handler: (item, event) => {
          this.setDateValue(-1, 'year');
        }
      }, {
        xtype: 'menuseparator'
      }, {
        text: 'aktuelles Quartal',
        handler: (item, event) => {
          this.setDateValue(0, 'quarter');
        }
      }, {
        text: 'voriges Quartal',
        handler: (item, event) => {
          this.setDateValue(-1, 'quarter');
        }
      }
      ]
    };
    (<ExtButton>Ext.getCmp(COMP_IDS.DATE_MENU_BUTTON)).setMenu(menu);
  }

  /**
   * Preset date
   * @param offset current=0,-1 last (type),...
   * @param type 'year' or 'quarter'
   */
  public setDateValue(offset: number, type: DateTimeUnit): void {

    const baseDate = DateTime.now().startOf(type);
    const baseDateCorr = baseDate.plus({days: offset});

    const startDate = baseDateCorr.startOf(type).toISODate() ?? '';
    const endDate = baseDateCorr.endOf(type).toISODate() ?? '';

    this.dateFrom.setValue(startDate);
    this.dateTo.setValue(endDate);
  }

  private extractFilter() {
    // compile report filters
    const availFilter = this.report.availFilter;
    const malfunction = availFilter.hasMalfunction && this.ckMalfunction.getValue() ? true : undefined;
    const open = availFilter.hasBatch && this.ckBatch.getValue() ? true : undefined;

    let from: string | undefined = undefined;
    let to: string | undefined = undefined;

    if (availFilter.hasDate) {
      // format dates
      const selDateFrom = this.dateFrom.getValue();
      const selDateTo = this.dateTo.getValue();
      if (selDateFrom) {
        from = (<Date>selDateFrom).toISOString();
      }
      if (selDateTo) {
        to = (<Date>selDateTo).toISOString();
      }
    }

    return {
      malfunction, from, to, open
    };
  }

  public onSaveClick() {
    const filter = this.extractFilter();
    const layer = this.report.layer;
    if (layer) {
      this.igis.fillReport(this.report.id, layer.id, filter);
    }

    // close ourself
    this.dlg.close();
  }

  public show(report: Report): void {

    this.report = report;

    this.itemCntLabel.setHtml('');

    const availFilter = report.availFilter;

    this.dateFrom.setValue(undefined);
    this.dateTo.setValue(undefined);
    this.ckMalfunction.setValue(0);
    this.ckBatch.setValue(0);

    this.dateFrom.setDisabled(!availFilter.hasDate);
    this.dateTo.setDisabled(!availFilter.hasDate);
    this.datePanel.setVisible(availFilter.hasDate);

    this.ckMalfunction.setDisabled(!availFilter.hasMalfunction);
    this.ckMalfunction.setVisible(availFilter.hasMalfunction);

    this.ckBatch.setDisabled(!availFilter.hasBatch);
    this.ckBatch.setVisible(availFilter.hasBatch);

    this.anyChangeListener();

    this.dlg.show();
  }
}
