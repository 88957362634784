
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class FIGroupInfoGen extends Model {
  protected _name: string;
  protected _items: string[];

  constructor(json: {name: string, items: string[]}, public _api: IGISApi) {
    super();
    this._name = json.name;
    this._items = json.items;
    this.onNew();
  }
}
