
import {Interval} from '../Interval';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class IntervalsGen extends Model {
  protected _intervals: Interval[];

  constructor(json: {intervals: {dataSetId: number, dataSetName: string, unit: string, defaultInterval: number, interval: number}[]}, public _api: IGISApi) {
    super();
    this._intervals =  json.intervals.map(item => new Interval(item, _api));
    this.onNew();
  }
}
