import {FeatureActionResultGen} from "./generated/FeatureActionResultGen";

export class FeatureActionResult extends FeatureActionResultGen {
  get layerId(): number {
    return this._layerId;
  }

  get guid(): string {
    return this._featureGUID;
  }

  get wkt(): string {
    return this._wkt;
  }
}
