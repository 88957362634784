
import {WhoamiGen} from "./generated/WhoamiGen";

export class Whoami extends WhoamiGen {

  get username(): string {
    return this._username;
  }

}
