import {Component} from "@igis-common/component/Component";
import {StorageEntry} from "@igis-common/model/StorageEntry";
import {Feature} from "@igis-common/model/Feature";

export class FileUploadComponent extends Component {

  protected uploadedFiles: StorageEntry[] = [];

  init(): void {

    // we only react on finished uploads if we are in mobile mode
    if (this.app.isMobile()) {
      // register for file uploads and save them in a list
      this.app.api._uploadFile$.subscribe((uploadResult) => {
        console.log('add new uploaded file');
        this.uploadedFiles.push(uploadResult);
      })

      // reset list of uploaded files
      this.app.feature.beginDataEntry$.subscribe(() => {
        console.log('reset uploaded files');
        this.uploadedFiles = [];
      })

      this.app.api.newDataEntryId$.subscribe((newEntryId) => {
        console.log('associating all files');
        let file = this.uploadedFiles.pop();
        // associate files with new data entry
        while (file) {
          this.app.api.assocFile({
            dataEntryId: newEntryId,
            fileGUID: file.uuid
          })
          file = this.uploadedFiles.pop();
        }
      })
    }
  }

  public uploadFile(file, feature: Feature): void {
    this.app.api.uploadFile(file, feature).then(uploadResult => {
    })
  }
}
