import {FeatureGroup, GeoJSON, LatLng, LatLngBounds, LayerGroup, Map as LeafletMap} from "leaflet";
import {Layer, VISIBILITY} from "@igis-common/model/Layer";
import {Subscription} from "rxjs";
import {IGISAppBase} from "@igis-common/IGISAppBase";
import {DataSet} from "@igis-common/model/DataSet";
import {Feature, LevelFeature} from "@igis-common/model/Feature";
import {ImageMapFeaturePoint} from "@igis-common/leaflet/ImageMapFeaturePoint";
import {ImageFeature} from "@igis-common/leaflet/ImageFeature";
import {SearchResult} from "@igis-common/model/SearchResult";

interface IFeatureMap {
  [key: string]: ImageFeature
}

export class ImageFeatureLayer extends LayerGroup {

  protected visSubscription: Subscription | null = null;
  protected reloadSubscription: Subscription | null = null;
  protected dataSetSubscription: Subscription | null = null;
  protected selFeatureSubscription: Subscription | null = null;
  protected searchSubscription: Subscription | null = null;
  protected updateFeatureSubscription: Subscription | null = null;

  protected curDataSet: DataSet | null = null;
  protected curSelFeature: Feature | null = null;

  protected features: IFeatureMap = {};
  protected featureLayer: GeoJSON | null = null;

  protected curSelFeatureMarker: ImageFeature | null = null;
  protected curSearchMarkers: ImageFeature[] = [];

  constructor(public layer: Layer, public levelFeature: LevelFeature, public app: IGISAppBase) {
    super();
  }

  protected onNewSelectedFeature(feature: Feature | null) {
    // remove the css-class from the old selected feature
    this.curSelFeature = feature;
    this.markFeature(feature);
  }

  protected onNewSearchResult(searchResult: SearchResult | null): void {
    if (!searchResult) {
      this.clearSearchMarker();
      return;
    }
    // mark items from search result
    let bounds: LatLngBounds | null = null;
    for (const layerRes of searchResult.layerResults) {
      for (const res of layerRes.resultEntries) {
        const guid = res.guid;
        const marker = this.getFeatureObject(guid);
        if (marker) {
          marker.markSearch();
          this.curSearchMarkers.push(marker);
          // add to bounds
          const expr = new LatLng(res.y, res.x);
          const expr1 = new LatLng(res.y+1, res.x+1); // we need this second expression to prevent a NaN error in leaflet
          if (!bounds) {
            bounds = new LatLngBounds(expr, expr1);
          } else {
            bounds.extend(expr);
          }
        } else {
          console.log('did not find marker on map');
        }
      }
    }

    if (bounds) {
      this._map?.fitBounds(bounds, {padding: [50, 50], maxZoom: 10});
    }
  }

  protected clearSearchMarker(): void {
    for (const marker of this.curSearchMarkers) {
      marker.unmarkSearch();
    }
    this.curSearchMarkers = [];
  }

  protected markFeature(feature: Feature | null) {
    if (this.curSelFeatureMarker) {
      this.curSelFeatureMarker.deselect();
      this.curSelFeatureMarker = null;
    }
    if (!feature) {
      return;
    }
    const marker = this.getFeatureObject(feature.guid);
    if (marker) {
      this.curSelFeatureMarker = marker;
      marker.select();
    }
  }

  public getVisibleFeatures(): ImageFeature[] {
    const featureList: ImageFeature[] = [];

    for (const featureGUID in this.features) {
      const marker = this.getFeatureObject(featureGUID);
      if (marker && this._map.getBounds().contains(marker.getLatLng())) {
        featureList.push(marker);
      }
    }

    return featureList;
  }

  public onAdd(map: LeafletMap): this {
    if (!this.featureLayer) {
      // create feature layer and add to map
      this.featureLayer = new GeoJSON(undefined, {
        pointToLayer: (geoJsonPoint, latLng) => {
          const marker = new ImageMapFeaturePoint(this.levelFeature, this.layer, this.curDataSet,
            this.curSelFeature, geoJsonPoint, latLng, this.app);
          this.features[marker.getGUID()] = marker;
          return marker;
        }
      });
      this.featureLayer.addTo(map);
    }

    this.features = {};
    this.reload();

    if (!this.visSubscription) {
      this.visSubscription = this.layer.visibilityChange$.subscribe(newVis => {
        if (this.featureLayer) {
          switch (newVis) {
            case VISIBILITY.VISIBLE:
              this.featureLayer.addTo(map);
              break;
            default:
              this.featureLayer.remove();
              break;
          }
        }
      })
    }

    if (!this.reloadSubscription) {
      this.reloadSubscription = this.layer.reload$.subscribe((event) => {
        this.reload();
      })
    }

    if (!this.dataSetSubscription) {
      this.dataSetSubscription = this.app.feature.selectDataSet$.subscribe(dataSet => {
        if (this.curDataSet !== dataSet) {
          this.curDataSet = dataSet;
          this.setDataSetSymbols(dataSet);
        }
      })
    }

    if (!this.selFeatureSubscription) {
      this.selFeatureSubscription = this.app.feature.selectFeature$.subscribe(feature => this.onNewSelectedFeature(feature));
    }

    if (!this.searchSubscription) {
      this.searchSubscription = this.app.search.searchResult$.subscribe(searchResult => this.onNewSearchResult(searchResult));
    }

    if (!this.updateFeatureSubscription) {
      this.updateFeatureSubscription = this.app.feature.updatedFeature$.subscribe(feature => {
        if (feature) {
          const guid = feature.guid;
          // trigger reload if the changed feature is on our layer
          if (this.features[guid]) {
            this.reload();
          }
        }
      })
    }


    return this;
  }

  protected setDataSetSymbols(dataSet: DataSet | null): void {
    for (let guid in this.features) {
      const feature = this.features[guid];
      feature.setDataSetSymbol(dataSet);
    }
  }

  public onRemove(map: LeafletMap): this {
    if (this.visSubscription) {
      this.visSubscription.unsubscribe();
      this.visSubscription = null;
    }
    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe();
      this.reloadSubscription = null;
    }
    if (this.dataSetSubscription) {
      this.dataSetSubscription.unsubscribe();
      this.dataSetSubscription = null;
    }
    if (this.selFeatureSubscription) {
      this.selFeatureSubscription.unsubscribe();
      this.selFeatureSubscription = null;
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
      this.searchSubscription = null;
    }
    if (this.featureLayer) {
      this.featureLayer.remove();
      this.featureLayer = null;
    }
    this.features = {};
    return this;
  }

  protected async reload() {
    if (!this._map) {
      return;
    }
    if (!this.featureLayer) {
      return;
    }
    // fetch data from server
    const featureCollection = await this.layer.fetchFeatureCollection(this.levelFeature);
    this.featureLayer.clearLayers(); // remove all old layers (=features)
    this.features = {};
    if (featureCollection) {
      const parsedGeoJSON = featureCollection.parsedGeoJSON;
      if (parsedGeoJSON) {
        this.featureLayer.addData(parsedGeoJSON); // TODO: error in leaflet types? it should accept a string..
      }
      // re-set the currently selected feature
      this.markFeature(this.curSelFeature);
    } else {
      console.error('no feature collection');
    }
  }

  public getFeatureObject(guid: string): ImageFeature | null {
    return this.features[guid];
  }
}
