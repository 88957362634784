import {UploadResultGen} from "./generated/UploadResultGen";
import {StorageEntry} from "@igis-common/model/StorageEntry";

export class UploadResult extends UploadResultGen {

  get storageEntries(): StorageEntry[] {
    return this._storageEntries;
  }

  get featureGUID(): string {
    return this._featureGUID;
  }

}
