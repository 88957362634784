import {PrintExtentSizeConfig, ScaleConfig} from "@igis-common/leaflet/printextent/PrintExtentSelect";
import {LatLng} from "leaflet";
import * as proj4 from "./proj4-src";

export enum PrintFormat {
  A4 = "A4", A3 = "A3", A2 = "A2", A1 = "A1", A0 = "A0"
}

export enum PrintOrientation {
  h, q
}

type PaperFormat = {
  w: number;
  h: number;
}

type Point = {
  x: number;
  y: number;
}

type ProjBoundingBox = {
  p1: Point;
  p2: Point;
}

export class PrintConfig {

  static EPSG_31255 = "+proj=tmerc +lat_0=0 +lon_0=13.33333333333333 +k=1 +x_0=0 +y_0=-5000000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.4232 +units=m +no_defs";
  static EPSG_LATLNG = "EPSG:4326";
  static EPSG_3857 = "EPSG:3857";

  static printCapabilities = {
    'formats': [
      {'name': 'A4', 'value': PrintFormat.A4},
      {'name': 'A3', 'value': PrintFormat.A3},
      {'name': 'A2', 'value': PrintFormat.A2},
      {'name': 'A1', 'value': PrintFormat.A1},
      {'name': 'A0', 'value': PrintFormat.A0},
    ],
    'orientations': [
      {'name': 'Hoch', 'value': PrintOrientation.h},
      {'name': 'Quer', 'value': PrintOrientation.q}
    ],
    'scales': [
      {"name": "1:100", "value": 100},
      {"name": "1:200", "value": 200},
      {"name": "1:250", "value": 250},
      {"name": "1:500", "value": 500},
      {"name": "1:1'000", "value": 1000},
      {"name": "1:2'000", "value": 2000},
      {"name": "1:3'000", "value": 3000},
      {"name": "1:5'000", "value": 5000},
      {"name": "1:7'500", "value": 7500},
      {"name": "1:10'000", "value": 10000}
    ]
  }

  public static getGridInterval(mapScale: number): number {
    let grid_interval = 10;
    if (mapScale > 100 && mapScale <= 250) {
      grid_interval = 25;
    } else if (mapScale > 250 && mapScale <= 1000) {
      grid_interval = 50;
    } else if (mapScale > 1000 && mapScale <= 2500) {
      grid_interval = 100;
    } else if (mapScale > 2500 && mapScale <= 5000) {
      grid_interval = 250;
    } else if (mapScale > 5000 && mapScale <= 12000) {
      grid_interval = 500;
    } else if (mapScale > 12000 && mapScale <= 25000) {
      grid_interval = 1000;
    } else if (mapScale > 25000 && mapScale <= 50000) {
      grid_interval = 2000;
    } else if (mapScale > 50000 && mapScale <= 100000) {
      grid_interval = 5000;
    } else if (mapScale > 100000 && mapScale <= 500000) {
      grid_interval = 10000;
    } else if (mapScale > 500000 && mapScale <= 1000000) {
      grid_interval = 50000;
    } else if (mapScale > 1000000 && mapScale <= 5000000) {
      grid_interval = 100000;
    } else if (mapScale > 5000000 && mapScale <= 10000000) {
      grid_interval = 250000;
    } else if (mapScale > 10000000 && mapScale <= 50000000) {
      grid_interval = 2500000;
    } else if (mapScale > 50000000 && mapScale <= 100000000) {
      grid_interval = 5000000;
    } else if (mapScale > 100000000) {
      grid_interval = 10000000;
    }
    return grid_interval;
  }

  /**
   * Returns the map dimensions in mm for the given layout and orientation.
   * @param format
   * @param orientation
   */
  public static getMapDims(format: PrintFormat, orientation: PrintOrientation): PaperFormat {
    switch (format) {
      case PrintFormat.A4:
        switch (orientation) {
          case PrintOrientation.h:
            return {w: 204.408, h: 270.102};
          case PrintOrientation.q:
            return {w: 288.991, h: 184.707};
        }
        break;
      case PrintFormat.A3:
        switch (orientation) {
          case PrintOrientation.h:
            return {w: 289.999, h: 398.595};
          case PrintOrientation.q:
            return {w: 413.030, h: 274.939};
        }
        break;
      // TODO: fix A2 & A1
      case PrintFormat.A2:
        switch (orientation) {
          case PrintOrientation.h:
            return {w: 412.561, h: 567.484};
          case PrintOrientation.q:
            return {w: 582.170, h: 388.622};
        }
        break;
      case PrintFormat.A1:
        switch (orientation) {
          case PrintOrientation.h:
            return {w: 583.119, h: 817.568};
          case PrintOrientation.q:
            return {w: 822.826, h: 559.265};
        }
        break;
      case PrintFormat.A0:
        switch (orientation) {
          case PrintOrientation.h:
            return {w: 828.071, h: 1161.775};
          case PrintOrientation.q:
            return {w: 1162.997, h: 796.401};
        }
        break;
    }
  }

  /**
   * Calculates a bounding box of two points around the map center for the given paper dimensions and scale.
   * Coordinates are in EPSG31255
   * @param mapCenter
   * @param scale
   * @param paperMapDims
   */
  public static calcPaperBoundingBoxAroundCenter(mapCenter: LatLng, scale: number, paperMapDims: PaperFormat): ProjBoundingBox {
    const p = {
      x: mapCenter.lng,
      y: mapCenter.lat
    }
    // project map center 31255 (unit is then meters)
    const mapCenterProj = proj4(PrintConfig.EPSG_LATLNG, PrintConfig.EPSG_31255, p);

    const w_m = paperMapDims.w / 1000 * scale; // map width in meters
    const h_m = paperMapDims.h / 1000 * scale; // map height in meters

    const mapCorner1proj = {
      x: mapCenterProj.x - w_m / 2,
      y: mapCenterProj.y - h_m / 2
    }
    const mapCorner2proj = {
      x: mapCenterProj.x + w_m / 2,
      y: mapCenterProj.y + h_m / 2
    }

    return {
      p1: mapCorner1proj,
      p2: mapCorner2proj
    }
  }

  /**
   * Calculates an array of print extent settings.
   * Uses the w/h ratio median.
   * @param format
   * @param orientation
   * @param mapCenter
   */
  public static setupPrintScales(format: PrintFormat, orientation: PrintOrientation, mapCenter: LatLng): PrintExtentSizeConfig {
    const scales: ScaleConfig[] = [];

    // convert our mapscales into longitude values based on print-map width
    const mapDims = PrintConfig.getMapDims(format, orientation);

    const ratios: number[] = [];

    for (let givenScaleObject of PrintConfig.printCapabilities.scales) {
      const givenScale = givenScaleObject.value;

      const bb31255 = PrintConfig.calcPaperBoundingBoxAroundCenter(mapCenter, givenScale, mapDims);

      // re-project into lat/lng
      const mapCorner1 = proj4(PrintConfig.EPSG_31255, PrintConfig.EPSG_LATLNG, bb31255.p1);
      const mapCorner2 = proj4(PrintConfig.EPSG_31255, PrintConfig.EPSG_LATLNG, bb31255.p2);

      const lngDiff = Math.abs(mapCorner2.x - mapCorner1.x);
      const latDiff = Math.abs(mapCorner2.y - mapCorner1.y);

      const ratio = latDiff / lngDiff;
      ratios.push(ratio);

      scales.push({
        dLon: lngDiff,
        id: givenScale
      })
    }

    let ratio = ratios[ratios.length / 2]; // take middle ratio? -> at this scales there should not be much difference

    return {
      lat2LonRatio: ratio,
      scales
    }
  }
}

