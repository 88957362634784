
import {LayerInfoGen} from "./generated/LayerInfoGen";
import {FIGroupInfo} from "./FIGroupInfo";
import {Attribute} from "@igis-common/model/Attribute";

export interface AttributeMap {
  [attrName: string]: Attribute
}

export class LayerInfo extends LayerInfoGen {

  protected _attributeMap: AttributeMap | null = null;

  get fiGroups(): FIGroupInfo[] {
    return this._groups;
  }

  get attributes(): AttributeMap {
    if(!this._attributeMap) {
      // build map
      this._attributeMap = {};
      for(let attr of this._attributes) {
        this._attributeMap[attr.name] = attr;
      }
    }
    return this._attributeMap;
  }

  get shortInfoEntries(): string[] {
    return this._shortInfoEntries;
  }

}
