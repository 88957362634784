
import {ExportGen} from "./generated/ExportGen";

export class Export extends ExportGen {

  get name(): string {
    return this._name;
  }

  get id(): number {
    return this._id;
  }

}
