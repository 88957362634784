
import {StorageEntry} from '../StorageEntry';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class OptionGen extends Model {
  protected _name: string;
  protected _value: string;
  protected _visImage: StorageEntry | undefined;

  constructor(json: {name: string, value: string, visImage?: {uuid: string, filename: string, isPic: boolean, isPanorama: boolean, hasThumb: boolean, comment: string, href: string}}, public _api: IGISApi) {
    super();
    this._name = json.name;
    this._value = json.value;
    this._visImage = json.visImage ? new StorageEntry(json.visImage, _api): undefined;
    this.onNew();
  }
}
