import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../igis-base";
import {COMP_IDS} from "./GUIDefinitions";
import {ExtGridPanel} from "../../ext-types/grid/ExtGridPanel";
import {first} from "rxjs/operators";
import {Level} from "@igis-common/model/Level";

export class LevelListGUIComponent extends Component {

  protected levelList: ExtGridPanel;

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {
    this.app.gui.gui$.pipe(first()).subscribe((Ext) => {
      this.levelList = <ExtGridPanel>Ext.getCmp(COMP_IDS.LEVEL_TREE);

      this.levelList?.on('selectionchange', (view, nodes) => {
        if (nodes && nodes.length > 0) {
          const levelId = nodes[0].data.id;
          if (levelId) {
            this.app.levelFeature.selectLevel(levelId);
          }
        }
      });

      this.app.levelFeature.levelList$.subscribe((levelList) => {
        if (levelList) {
          // TODO: should be receive the selected level too, or do we agree it is always the first?
          this.updateList(levelList);
        } else {
          this.disable();
        }
      })
    })
  }

  protected async updateList(levelList: Level[]) {
    this.levelList.setDisabled(false);

    const store = this.levelList.getStore();
    store.removeAll();
    const storeData: [string, number][] = [];
    let firstLevelId: number|null = null;
    for (let level of levelList) {
      if (!firstLevelId) {
        firstLevelId = level.id;
      }
      storeData.push([level.name, level.id]);
    }
    store.loadData(storeData);

    // now select the first level
    this.levelList.getSelectionModel().selectRange(0,0);
  }

  protected disable(): void {
    this.levelList.getStore().removeAll();
    this.levelList.setDisabled(true);
  }
}
