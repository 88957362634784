import {Component} from "@igis-common/component/Component";
import {IGISAppBase} from "@igis-common/IGISAppBase";
import {Observable, Subject} from "rxjs";
import {MapComponent} from "@igis-common/component/MapComponent";
import {DataSetListResult} from "@igis-common/model/DataSetListResult";
import {Layer} from "@igis-common/model/Layer";


/**
 * Component for handling search requests.
 */
export class ListPastDueComponent extends Component {

  protected pastDueResultSubject = new Subject<DataSetListResult[] | null>();
  public pastDueResult$: Observable<DataSetListResult[] | null> = this.pastDueResultSubject;

  protected curMap: MapComponent | null = null;


  constructor(app: IGISAppBase) {
    super(app);
  }

  public init() {
    this.app.mapChange$.subscribe(map => {
      this.curMap = map;
    })
  }

  public async listPastDue(): Promise<DataSetListResult[] | null> {
    if (!this.curMap) {
      return null;
    }

    // get list of visible layers
    const layers = this.curMap.getActiveQueryableChildren();
    const layerIds = layers.map((layer) => layer.id);
    return this.app.api.listPastDue({layerIds}).then(pastDueResult => {
      if (pastDueResult && pastDueResult.data) {
        const data = pastDueResult.data;
        const res = data.getDataSetResultsOrdered();
        this.pastDueResultSubject.next(res);
        return res;
      } else {
        this.pastDueResultSubject.next(null);
        return null;
      }
    })
  }

  public clear(): void {
    this.pastDueResultSubject.next(null);
  }
}
