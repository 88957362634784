import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../igis-base";
import {first} from "rxjs/operators";
import {ExtWindow} from "../../ext-types/window/ExtWindow";
import {IExt} from "../../ext-types/Ext";
import {ProjectListEntry} from "@igis-common/model/ProjectListEntry";
import {ExtFormFieldCombo} from "../../ext-types/form/field/ExtFormFieldCombo";
import {sortByLCName} from "@igis-common/api/Util";
import {ExtForm} from "../../ext-types/form/ExtForm";

const w = 350;
const h = 150;

export class ProjectSelectComponent extends Component {

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {
    this.app.gui.gui$.pipe(first()).subscribe((Ext) => {
      this.app.needProjectSelect$.subscribe((projectList) => {
        // show dialog for selecting project
        this.showDialog(Ext, projectList);
      })
    })
  }

  protected showDialog(Ext: IExt, projectList: ProjectListEntry[]): void {
    const app = this.app;

    // convert projectlist to array store

    // sort beforehand
    sortByLCName(projectList);

    const projectArray: [string, number][] = [];
    for (let project of projectList) {
      projectArray.push([project.name, project.id]);
    }

    const ProjectSelectDialogDefinition = {
      xtype: 'window',
      title: 'InfraGIS Projekt wählen',
      modal: true,
      layout: 'fit',
      frame: true,
      resizable: false,
      closable: false,
      width: w,
      height: h,
      items: [{
        xtype: 'form',
        bodyPadding: 10,
        items: [{
          xtype: 'combo',
          id: 'ProjectSelectCombo',
          editable: false,
          allowBlank: false,
          store: {
            xtype: 'arraystore',
            data: projectArray,
            fields: ['name', 'value']
          },
          valueField: 'value',
          displayField: 'name'
        }],
        defaultButton: 'buttonOk',
        buttons: [{
          text: 'Auswählen',
          formBind: true,
          id: 'buttonOk',
          handler: function () {
            const form = <ExtForm>this.up('form').getForm();
            const projectCombo = Ext.getCmp<ExtFormFieldCombo>('ProjectSelectCombo');
            if (!form.isValid()) {
              console.log('nothing selected');
            } else {
              // disable form until we return
              this.disable();
              // make the request
              app.selectProject(<number>projectCombo.getValue())
                .then(success => {
                  if (success) {
                    // close the dialog
                    this.up('window').destroy();
                  } else {
                    Ext.Msg.alert('Fehlgeschlagen', 'Fehler beim Auswählen des Projektes');
                    this.enable();
                  }
                }).catch(() => {
                // there was an error
                this.enable();
              })
            }
          }
        }],
        defaults: {
          anchor: '100%',
          labelWidth: 120
        }
      }]
    }

    const dlg = <ExtWindow>Ext.create(ProjectSelectDialogDefinition);
    dlg.show();
  }
}
