
import {Option} from '../Option';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class DataFieldGen extends Model {
  protected _id: number;
  protected _name: string;
  protected _readonly: boolean;
  protected _mandatory: boolean;
  protected _type: number;
  protected _attributeName: string;
  protected _orderNr: number;
  protected _hidden: boolean;
  protected _optionsAreTemplates: boolean;
  protected _insertPrevious: boolean;
  protected _initValue: string;
  protected _maxCharLength: number;
  protected _condEnabled: string;
  protected _condVisible: string;
  protected _options: Option[];

  constructor(json: {id: number, name: string, readonly: boolean, mandatory: boolean, type: number, attributeName: string, orderNr: number, hidden: boolean, optionsAreTemplates: boolean, insertPrevious: boolean, initValue: string, maxCharLength: number, condEnabled: string, condVisible: string, options: {name: string, value: string, visImage?: {uuid: string, filename: string, isPic: boolean, isPanorama: boolean, hasThumb: boolean, comment: string, href: string}}[]}, public _api: IGISApi) {
    super();
    this._id = json.id;
    this._name = json.name;
    this._readonly = json.readonly;
    this._mandatory = json.mandatory;
    this._type = json.type;
    this._attributeName = json.attributeName;
    this._orderNr = json.orderNr;
    this._hidden = json.hidden;
    this._optionsAreTemplates = json.optionsAreTemplates;
    this._insertPrevious = json.insertPrevious;
    this._initValue = json.initValue;
    this._maxCharLength = json.maxCharLength;
    this._condEnabled = json.condEnabled;
    this._condVisible = json.condVisible;
    this._options =  json.options.map(item => new Option(item, _api));
    this.onNew();
  }
}
