import {FIAttr} from "@igis-common/model/FIAttr";
import {FIGroupInfo} from "@igis-common/model/FIGroupInfo";

/**
 * A combination of an FIGroupInfo + the rendered attributes of a specific feature.
 * Used to group the attributes of a feature and display e.g. in the feature info accordion.
 */
export class FIGroupDto {
  private readonly _attributes: FIAttr[];
  private readonly _fiGroup: FIGroupInfo;

  constructor(fiGroup: FIGroupInfo) {
    this._fiGroup = fiGroup;

    this._attributes = [];
  }

  get name(): string {
    return this._fiGroup.name;
  }

  get attributes(): FIAttr[] {
    return this._attributes;
  }

  get fiGroup(): FIGroupInfo {
    return this._fiGroup;
  }

  get common(): boolean {
    return false;
  }
}
