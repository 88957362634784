import {BasemapLayerGen} from "./generated/BasemapLayerGen";

export class BasemapLayer extends BasemapLayerGen {

  get leafletUrl(): string {
    return this._url;
  }

  get attribution(): string {
    return this._attribution;
  }

  get name(): string {
    return this._name;
  }

  get id(): number {
    return this._id;
  }

  get opacity(): number {
    return this._opacity;
  }

  get defaultVisible(): boolean {
    return this._defaultVisible;
  }
}
