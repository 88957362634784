
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class LastPosGen extends Model {
  protected _zoom: number;
  protected _lat: number;
  protected _lng: number;

  constructor(json: {zoom: number, lat: number, lng: number}, public _api: IGISApi) {
    super();
    this._zoom = json.zoom;
    this._lat = json.lat;
    this._lng = json.lng;
    this.onNew();
  }
}
