import {ProjectInfoGen} from "./generated/ProjectInfoGen";
import {LastPos} from "@igis-common/model/LastPos";
import {Layer, LayerIdMap, LayerMap} from "@igis-common/model/Layer";
import {BasemapLayer} from "@igis-common/model/BasemapLayer";
import {DataSet} from "@igis-common/model/DataSet";
import {Report} from "@igis-common/model/Report";
import {sortByLCCategoryName, sortByLCName, sortByOrderNr} from "@igis-common/api/Util";
import {Observable, ReplaySubject, Subject} from "rxjs";
import {RootLayer} from "@igis-common/RootLayer";
import {BasemapLayerGroup} from "@igis-common/model/BasemapLayerGroup";
import {BasemapMapLayer} from "@igis-common/leaflet/BasemapMapLayer";
import {ProjectPolicy} from "@igis-common/api/policy/ProjectPolicy";
import {IGISConst} from "@igis-common/const";

export class ProjectInfo extends ProjectInfoGen {

  protected policy: ProjectPolicy | null = null;
  protected _imageLayers: Layer[] = [];
  protected _rootLayer!: Layer;

  protected dataSetOpenCntSubject = new ReplaySubject<number>(1);
  public dataSetOpenCnt$: Observable<number> = this.dataSetOpenCntSubject;

  /**
   * Returns the project's name.
   */
  get name(): string {
    return this._name;
  }

  get askNewsletter(): boolean {
    return this._askNewsletter;
  }

  get username(): string {
    return this._username;
  }

  get lastPosition(): LastPos {
    return this._lastPos;
  }

  get hasChangelogs(): boolean {
    return this._changelogs;
  }

  get rootLayer(): Layer {
    return this._rootLayer;
  }

  get layers(): Layer[] {
    return this._layers;
  }

  public resetChangelogs(): void {
    this._changelogs = false;
  }

  get basemapLayers(): BasemapLayer[] {
    return this._basemaps;
  }

  /**
   * Get list of all datasets that are defined on all layers. Includes the AddFeature datasets
   */
  public getFeatureDataSets(): DataSet[] {
    let dataSets: DataSet[] = [];
    for (let layer of this._layers) {
      const layerDataSets = layer.getFeatureDataSets();
      for (let layerDataSet of layerDataSets) {
        dataSets.push(layerDataSet);
      }
      if (layer.addFeatureDataSet) {
        dataSets.push(layer.addFeatureDataSet);
      }
    }
    for (const layer of this._imageLayers) {
      const layerDataSets = layer.getFeatureDataSets();
      for (let layerDataSet of layerDataSets) {
        dataSets.push(layerDataSet);
      }
      if (layer.addFeatureDataSet) {
        dataSets.push(layer.addFeatureDataSet);
      }
    }
    return dataSets;
  }

  /**
   * Returns all Layers that have a DataSet associated with them
   */
  public getDataSetLayers(): Layer[] {
    let layers: Layer[] = [];
    for (const layer of this._layers) {
      const layerDataSets = layer.getFeatureDataSets();
      if (layerDataSets.length > 0) {
        layers.push(layer);
      }
    }
    sortByLCName(layers);
    return layers;
  }

  public getLayerForReport(reportId: number): Layer | null {
    for (const layer of this._layers) {
      const layerReports = layer.reports;
      if (layerReports) {
        for (let report of layerReports) {
          if (report.id === reportId) {
            return layer;
          }
        }
      }
    }
    return null;
  }

  public getLayerById(layerId: number): Layer | null {
    for (const layer of this._layers) {
      if (layer.id === layerId) {
        return layer;
      }
    }
    for (const layer of this._imageLayers) {
      if (layer.id === layerId) {
        return layer;
      }
    }
    return null;
  }

  public getLayerByWMSId(layerName: string): Layer | null {
    for (const layer of this._layers) {
      if (layer.wmsName === layerName) {
        return layer;
      }
    }
    return null;
  }

  public getDataSetById(setId: number): DataSet | null {
    const setList = this.getFeatureDataSets();
    for (const set of setList) {
      if (set.id === setId) {
        return set;
      }
    }
    console.warn("wrong set id", setId);
    return null;
  }

  public transformProjectInfo() {

    // remove image layers from overall layer list
    const imageLayers: Layer[] = [];
    const regLayers: Layer[] = [];
    for (const layer of this._layers) {
      if (layer.isImageLayer) {
        imageLayers.push(layer);
      } else {
        regLayers.push(layer);
      }
    }
    this._layers = regLayers;
    this._imageLayers = imageLayers;

    // as we are relying on the WMS layer tree we have to re-add the image layers as children to their parents
    for (const imageLayer of imageLayers) {
      const parentId = imageLayer.parentId;
      const parentLayer = this.getLayerById(parentId);
      if (parentLayer) {
        parentLayer.addImageLayer(imageLayer);
      }
    }

    // fix layer data
    for (const layer of this._layers) {
      if (layer.addFeatureDataSet) {
        const dataSet = layer.addFeatureDataSet;
        dataSet.fixDataSetLayers(this, false);
      }
    }

    // fill dataset lists of layers
    for (const dataSet of this._dataSets) {
      dataSet.fixDataSetLayers(this);
      // register for open count changes
      dataSet.openEntryCnt$.subscribe((cnt) => {
        this.onNewDataSetEntryCnt();
      })
    }

    // build layer tree
    const layersById: LayerMap = {};
    for (const layer of this._layers) {
      layersById[layer.id] = layer;
    }
    const rootGroups: Layer[] = [];
    for (const layer of this._layers) {
      layer.buildTree(layersById);
      // find layers with no parent
      if (!layer.hasParent()) {
        rootGroups.push(layer);
      }
    }

    // build complete layer tree
    const rootLayer = new RootLayer(this.name, this._api);
    for (const rootGroup of rootGroups) {
      rootLayer.addChild(rootGroup);
      rootGroup.checkChildrenVisible();
    }

    // order root layers by their orderNr
    rootLayer.sortChildren();

    // add basemap layers to the main tree in a special group
    const basemapGroup = new BasemapLayerGroup('Hintergrundkarte', this._api);
    for (const basemap of this.basemapLayers) {
      basemapGroup.addChild(new BasemapMapLayer(basemap));
    }
    rootLayer.addChild(basemapGroup);
    this._rootLayer = rootLayer;

    // set visibility from previously saved config
    if (this._invisibleLayers && this._visibleLayers && this._invisibleBasemapLayers) {
      // convert to map for better performance
      const invLayerMap: LayerIdMap = {};
      for (let layerId of this._invisibleLayers) {
        invLayerMap[layerId] = true;
      }
      const visibleLayerMap: LayerIdMap = {};
      for (let layerId of this._visibleLayers) {
        visibleLayerMap[layerId] = true;
      }
      const invBMLayerMap: LayerIdMap = {};
      for (let bmLayerId of this._invisibleBasemapLayers) {
        invBMLayerMap[bmLayerId] = true;
      }
      this._rootLayer.setVisibilityFromUserConfig(invLayerMap, visibleLayerMap, invBMLayerMap);
    }

    // build a new list of layers without groups, and in order of the tree -> which is the layer drawing order
    const layerList: Layer[] = [];
    for (const rootLayer of this._rootLayer.children) {
      rootLayer.addNonGroupLayers(layerList);
    }
    console.log(layerList.length + " non-group layers");

    // replace our main list
    this._layers = layerList;
    this._rootLayer.visible = true;

    this.setPolicy(); // sets policy in all layers
  }

  public setPolicy(policyString: string | undefined = undefined) {
    if (!policyString) {
      policyString = this._policy; // use our own policy from the project info -> TODO: remove me
    }
    this.policy = ProjectPolicy.fromPolicyString(policyString);
    // update policy in all layers
    for (const layer of this._layers) {
      layer.projectPolicy = this.policy; // we need a reference to the project policy to determine permissions
    }
    for (const layer of this._imageLayers) {
      layer.projectPolicy = this.policy;
    }
  }

  public hasExportPermission(): boolean {
    if(!this.policy) {
      return false;
    }
    return this.policy.hasGlobalPermission(IGISConst.PERM_EXPORT_SHP);
  }

  /**
   * Make a recount of the open data entries and publish that number
   * @protected
   */
  public onNewDataSetEntryCnt() {
    // make a recount
    let openDataEntriesCnt = 0;
    for (let set of this._dataSets) {
      openDataEntriesCnt += set.openDataEntryCount;
    }
    this.dataSetOpenCntSubject.next(openDataEntriesCnt);
  }

  public dumpLayerTree() {
    for (let rootLayer of this._rootLayer.children) {
      rootLayer.dumpLayerTree();
    }
  }

  public isGPSGeometryEnabled(): boolean {
    return this._gps;
  }
}
