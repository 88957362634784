import {Component} from "@igis-common/component/Component";
import {IGISAppBase} from "@igis-common/IGISAppBase";
import {from, Observable, Subject, take, timer} from "rxjs";
import {concatMap, filter, map} from "rxjs/operators";
import {FileResult} from "@igis-common/model/FileResult";


/**
 * Component for handling print requests.
 * Handles print spooling, contains a queue of currently printing reports/maps
 */
export class FileGenComponent extends Component {

  /**
   * Emits if currently print requests are running
   */
  protected genRequestActiveSubject = new Subject<boolean>();
  public genRequestActive$: Observable<boolean> = this.genRequestActiveSubject;

  private genFailedSubject = new Subject<string>();
  public genFailed$: Observable<string> = this.genFailedSubject;

  private genPollingSubject = new Subject<string>();
  private printRequestCnt = 0;

  constructor(app: IGISAppBase) {
    super(app);
  }

  public init() {
    this.setupPrintPolling();

    // feed print request results to our own queue
    this.app.api.fileRequestResult$.subscribe(fileResult => {
      this.genPollingSubject.next(fileResult.uuid);
    })
  }

  private setupPrintPolling(): void {
    this.genPollingSubject.subscribe(uuid => {
      console.log(`starting polling for ${uuid}`);
      this.startPrintPollingForUUID(uuid);
    })
  }

  protected pollingFinishedFor(uuid: string, result: FileResult) {
    this.printRequestCnt--;
    if (this.printRequestCnt <= 0) {
      this.printRequestCnt = 0;
      this.genRequestActiveSubject.next(false);
    }
    if (result.status === "success") {
      // we need to redirect to this location to download the file
      this.app.api.printDownload(uuid);
    } else {
      console.log("print failed!");
      this.genFailedSubject.next('Fehler beim Generieren der Daten. Bitte kontaktieren Sie den InfraGIS Support');
    }
  }

  protected startPrintPollingForUUID(uuid: string) {

    // increment request cnt
    this.printRequestCnt++;
    this.genRequestActiveSubject.next(true);

    timer(500, 1000)
      .pipe(concatMap(() => from(this.app.api.filePoll(uuid))))
      .pipe(filter(result => result != null))
      // @ts-ignore
      .pipe(map(response => response.data))
      .pipe(filter(pollData => pollData != null && (pollData.status === "success" || pollData.status === "failed")))
      .pipe(take(1))
      // @ts-ignore
      .subscribe((result) => this.pollingFinishedFor(uuid, result));
  }
}
