
import {SearchResultGen} from "./generated/SearchResultGen";
import {LayerSearchResult} from "@igis-common/model/LayerSearchResult";
import {MapComponent} from "@igis-common/component/MapComponent";

export class SearchResult extends SearchResultGen {

  protected _map: MapComponent | null = null;

  get layerResults(): LayerSearchResult[] {
    return this._layerResults;
  }

  get map(): MapComponent | null {
    return this._map;
  }

  set map(map: MapComponent | null) {
    this._map = map;
  }

}
