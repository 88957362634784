// Override Ext.button.Button to preserve the tooltip over button enable/disable actions
// hint: we need to do this in js instead of ts, otherwise the ts compiler fucks up "this"
window['Ext'] = require('ExtBase'); // we need to put this in global namespace, otherwise this shitty framework does not work...

// set some default values for buttons / tooltip buttons
Ext.define("Ext.igis.Button", {
    override: 'Ext.button.Button',
    //savedTooltip: "",

    /*initComponent: function () {
        this.savedTooltip = this.tooltip;
        this.callParent(arguments);
    },

    setTooltip: function (tooltip) {
       this.savedTooltip = tooltip;
        this.callParent(arguments);
    },*/

    setDisabled: function (disabled) {
        this.callParent(arguments);
        if(disabled) {
            this.addCls('igis-button-disable');
        } else {
            this.removeCls('igis-button-disable');
        }
        //this.setTooltip(this.savedTooltip);
        return this;
    },

    // inject svg to icon template
    iconTpl: '<span id="{id}-btnIconEl" data-ref="btnIconEl" role="presentation" unselectable="on" class="{baseIconCls} ' +
        '<tpl if="iconComp"> fa-stack fa-1x </tpl>' +
        '{baseIconCls}-{ui} {iconCls} {glyphCls}{childElCls}" style="' +
        '<tpl if="iconUrl">background-image:url({iconUrl});</tpl>' +
        '<tpl if="glyph">' + '<tpl if="glyphFontFamily">' +
        'font-family:{glyphFontFamily};' +
        '</tpl>' +
        '">{glyph}' +
        '<tpl else>' +
        '">' +
        '</tpl>' +
        '<tpl if="iconComp">{iconComp}</tpl>' +
        '</span>',

    // extend template values
    getTemplateArgs: function () {
        const args = this.callParent();
        args.iconComp = this.iconComp;
        if (this.iconComp) {
            args.text = '';
        }
        return args;
    },

    scale: 'medium'
});

Ext.define("Ext.panel.FATool", {
    override: 'Ext.panel.Tool',

    typeIconClasses: {
        'close': 'fa-duotone fa-circle-x',
        'minimize': 'fa-duotone fa-window-minimize',
        'maximize': 'fa-duotone fa-window-maximize',
        'restore': 'fa-duotone fa-window-restore',
        'toggle': '',
        'gear': '',
        'prev': '',
        'next': '',
        'pin': '',
        'unpin': '',
        'right': '',
        'left': '',
        'down': '',
        'up': '',
        'refresh': 'fa-duotone fa-rotate-right',
        'plus': '',
        'minus': '',
        'search': '',
        'save': '',
        'help': '',
        'print': '',
        'expand-left': 'fa-duotone fa-circle-chevron-left',
        'expand-right': 'fa-duotone fa-circle-chevron-right',
        'collapse': '',
        'collapse-left': 'fa-duotone fa-circle-chevron-left',
        'collapse-right': 'fa-duotone fa-circle-chevron-right',
        'collapse-top': 'fa-duotone fa-circle-minus',
        'expand-bottom': 'fa-duotone fa-circle-plus'
    },

    renderTpl: [
        '<div id="{id}-toolEl" data-ref="toolEl" class="{className} {childElCls}" role="presentation">' +
        '</div>'
    ],

    calculateClassName: function() {
        var me = this,
            result = me.baseCls + '-tool-el ';
        if (me.type) {
            // lookup
            let iconClass = me.typeIconClasses[me.type];
            if (!iconClass){
                console.log("did not find icon class for type " + me.type);
                iconClass = 'fa-duotone fa-home';
            }
            result += iconClass + ' ';
        } else {
            if (me.iconCls) {
                result += me.iconCls;
            }
        }
        // do not add x-tool-img to allow iconCls full sway
        return result;
    }
});

// some translations
Ext.define("Ext.locale.de.window.Window", {
    override: "Ext.window.Window",
    closeToolText: 'Schließen'
});
