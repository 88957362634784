import {LastPosGen} from "./generated/LastPosGen";

export class LastPos extends LastPosGen {

  get lat(): number {
    return this._lat;
  }

  get lng(): number {
    return this._lng;
  }

  get zoom(): number {
    return this._zoom;
  }

  public getKeys() {
    return {
      lat: this.lat,
      lng: this.lng,
      zoom: this.zoom
    }
  }

}
