import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../igis-base";
import {Subject} from "rxjs";
import {first, takeUntil} from "rxjs/operators";
import {Layer, LayerTreeNodeConfig, VISIBILITY} from "@igis-common/model/Layer";
import {COMP_IDS} from "./GUIDefinitions";
import {MapComponent} from "@igis-common/component/MapComponent";
import {ExtTreeStore, ExtTreeStoreConfig} from "../../ext-types/tree/ExtTreeStore";
import {ExtTree} from "../../ext-types/tree/ExtTree";
import {ExtSelectionModel} from "../../ext-types/selection/ExtSelectionModel";
import {ExtTreeNode} from "../../ext-types/tree/ExtTreeNode";
import {ExtPanelTool} from "../../ext-types/panel/ExtPanelTool";
import {IExt} from "../../ext-types/Ext";


const iconClsVisible = 'fa-duotone fa-eye x-tree-node-black';
const iconClsInvisible = 'fa-duotone fa-eye';

export class LayerTreeComponent extends Component {

  protected layerTree: ExtTree;
  protected treeStore: ExtTreeStore;

  protected Ext: IExt;

  protected rootChange$ = new Subject<boolean>();
  protected rootLayer: Layer;

  protected map: MapComponent;

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {
    this.app.gui.gui$.pipe(first()).subscribe((Ext) => {
      this.Ext = Ext;
      // get reference to tree panel
      this.layerTree = Ext.getCmp<ExtTree>(COMP_IDS.LAYER_TREE);

      this.setupTools(Ext);

      // add listener for click event to set layer active
      this.layerTree.addListener('click', (node) => {
        this.map?.setLayersActive(node?.attributes);
      })

      this.layerTree.on('beforecheckchange', (node, checked, event) => {
        // trigger visible processing in layer object
        (<LayerTreeNodeConfig>node.data).layer.visible = !checked;
      })

      let treeStoreConfig: ExtTreeStoreConfig = {
        root: {
          expanded: false,
          text: '(Projekt wird geladen...)',
          children: []
        }
      }
      this.treeStore = Ext.create<ExtTreeStore>('Ext.data.TreeStore', treeStoreConfig);
      this.treeStore.on('nodeappend', this.onTreeNodeAppend, this);
      this.treeStore.on('nodeinsert', this.onTreeNodeAppend, this);
      this.layerTree.setStore(this.treeStore);

      const selModel = this.layerTree.getSelectionModel();
      selModel.on('selectionchange', this.onLayerSelChange, this);

      this.app.mapRoot$.subscribe(({curMap, rootLayer}) => {

        this.map = curMap;

        this.updateTree(rootLayer);
        this.rootLayer = rootLayer;

        // subscribe to active layer events on the map
        curMap.activeLayers$.pipe(takeUntil(this.rootChange$)).subscribe((newActiveLayers) => {
          const selModel = this.layerTree.getSelectionModel();
          if (!newActiveLayers || newActiveLayers.length == 0) {
            // deselected active layer
            selModel.select(this.layerTree.getRootNode());
          } else {
            // find the first active layer and select it in the tree
            const activeLayer = newActiveLayers[0];
            const layerRecord = this.layerTree.getStore().findRecord('text', activeLayer); // TODO: change to layer id when loading all layers from projectinfo
            if (layerRecord) {
              selModel.select(layerRecord);
            }
          }
        })

        curMap.activeLayerLock$.pipe(takeUntil(this.rootChange$)).subscribe((layerLock) => {
          console.log('should lock tree ' + layerLock);
          const selModel = this.layerTree.getSelectionModel();
          selModel.setLocked(layerLock);
        })
      })
    })

  }

  public onLayerSelChange(selModel: ExtSelectionModel, selRecords: ExtTreeNode[]): void {
    const selRecord = selRecords.length > 0 ? selRecords[0] : null;
    if (selRecord) {
      // convert to layer
      const layer = (<LayerTreeNodeConfig>selRecord.data).layer;
      console.log('selecting layer ' + layer.name);
      this.map.setLayersActive([layer]);
    }
  }

  public onTreeNodeAppend(store: ExtTreeStore, node: ExtTreeNode): void {
    const treeNodeConfig = <LayerTreeNodeConfig>node.data;
    if (!treeNodeConfig.registered) {
      const layer = treeNodeConfig.layer;
      if (!layer) { // that is maybe the initial root node from GUIDefinitions.ts
        return;
      }
      // we unregister as soon as the root layer changes and the tree is rebuilt
      // otherwise this produces a memory leak?
      layer.visibilityChange$.pipe(takeUntil(this.rootChange$)).subscribe(newVisibility => {

        switch (newVisibility) {
          case VISIBILITY.VISIBLE:
            node.set('iconCls', iconClsVisible);
            node.set('checked', true);
            break;
          case VISIBILITY.INVISIBLE:
            node.set('iconCls', iconClsInvisible);
            node.set('checked', true);
            break;
          case VISIBILITY.PARENT_INVISIBLE:
            node.set('iconCls', iconClsInvisible);
            break;
        }
      })
      treeNodeConfig.registered = true;
    }
  }

  protected setupTools(Ext: IExt) {
    const toolSave = <ExtPanelTool>Ext.getCmp(COMP_IDS.LAYER_TREE_SAVE);
    toolSave.on("click", this.onClickSave, this);

    const toolRestore = <ExtPanelTool>Ext.getCmp(COMP_IDS.LAYER_TREE_RESTORE);
    toolRestore.on("click", this.onClickRestore, this);
  }

  protected onClickSave() {
    if (this.rootLayer) {
      const invLayerIds: number[] = [];
      const visLayerIds: number[] = [];
      const bmLayerIds: number[] = [];
      this.rootLayer.fillVisibilityLists(invLayerIds, visLayerIds, bmLayerIds);
      this.app.api.saveLayerConfig({invisibleLayers: invLayerIds, visibleLayers: visLayerIds, invisibleBasemapLayers: bmLayerIds}).then((res) => {
        // display success message
        if (res && res.data) {
          this.Ext.Msg.alert("Gespeicert", "Ebenen-Konfiguration gespeichert");
        } else {
          // error message is displayed anyway, no need to repeat here
        }
      });
    }
  }

  protected onClickRestore() {
    if (this.rootLayer) {
      this.rootLayer.resetVisibility();
      this.app.api.resetLayerConfig({});
    }
  }

  protected updateTree(rootLayer: Layer): void {
    this.rootChange$.next(true); // we notify all tree nodes that a new root object is to be set, so they can release their subscriptions to the layer object
    this.treeStore.setRoot(rootLayer.createTreeNode(0, iconClsVisible, iconClsInvisible));
    this.layerTree.getRootNode().expand(false);
  }

}
