import {LatLng, Layer} from "leaflet";
import {DataSet} from "@igis-common/model/DataSet";

export abstract class ImageFeature extends Layer {

  public abstract markSearch();

  public abstract unmarkSearch();

  public abstract deselect();

  public abstract select();

  public abstract setDataSetSymbol(dataSet: DataSet | null);

  public abstract getLatLng(): LatLng;

  public abstract getGUID(): string;
}
