import {DataField} from "@igis-common/model/DataField";
import {FI} from "@igis-common/model/FI";
import {DataEntryFieldBase} from "./DataEntryFieldBase";
import {IExt} from "../../../ext-types/Ext";
import {ExtFormFieldBase} from "../../../ext-types/form/field/ExtFormFieldBase";
import {ExtFormFieldDisplayConfig} from "../../../ext-types/form/field/ExtFormFieldDisplay";

export class DataEntryDisplayField extends DataEntryFieldBase {

  private readonly rawAttrValue: string | null;

  constructor(private displayField: DataField, feature: FI, Ext: IExt) {
    super(displayField.name, Ext);

    // gather attribute value from feature
    const attrValue = feature.getAttributeValue(displayField.attributeName);
    // we need to save the raw value: maybe the output is rendered specially
    this.rawAttrValue = feature.getRawAttributeValue(displayField.attributeName);

    // it is actually an ExtPanel, but we may cast to ExtField because setValue, getValue are never called on display fields
    const displayFieldConfig: ExtFormFieldDisplayConfig = {
      xtype: 'displayfield',
      labelStyle: 'white-space: nowrap;',
      value: attrValue
    }
    this.cmpValue = Ext.create<ExtFormFieldBase>(displayFieldConfig);
  }

  public getFieldId(): number {
    return this.displayField.id;
  }

  public getValue(): any {
    return this.rawAttrValue;
  }

  public updateComponent(fieldValues) {
    // TODO: implement me for display fields?
  }
}
