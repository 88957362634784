import {FeatureCollectionGen} from "./generated/FeatureCollectionGen";

export class FeatureCollection extends FeatureCollectionGen {

  get geoJSON(): string {
    return this._geoJSON;
  }

  get parsedGeoJSON(): any {
    return JSON.parse(this._geoJSON);
  }

}
