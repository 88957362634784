import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../igis-base";
import {COMP_IDS} from "./GUIDefinitions";
import {ExtGridPanel} from "../../ext-types/grid/ExtGridPanel";
import {MapComponent} from "@igis-common/component/MapComponent";
import {ExtPanel} from "../../ext-types/panel/ExtPanel";
import {Feature} from "@igis-common/model/Feature";
import {DataSetListResult} from "@igis-common/model/DataSetListResult";

export class ListMalfunctionGUIComponent extends Component {

  protected malfunctionListResultPanel: ExtGridPanel;
  protected emptyMessagePanel: ExtPanel;

  protected curMap: MapComponent;

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {

    this.app.mapChange$.subscribe(map => this.curMap = map);

    this.app.startup$.subscribe(({Ext, projectInfo}) => {
      this.malfunctionListResultPanel = <ExtGridPanel>Ext.getCmp(COMP_IDS.LIST_MALFUNCTION.RESULT_PANEL);
      this.emptyMessagePanel = <ExtPanel>Ext.getCmp(COMP_IDS.LIST_MALFUNCTION.EMPTY_MESSAGE);

      const panel = <ExtPanel>Ext.getCmp(COMP_IDS.LIST_MALFUNCTION.PANEL);
      panel.on('collapse', () => { // clear result when user switches away from panel
        this.app.listMalfunction.clear();
      })

      panel.on('expand', () => { // get result when user switches to panel
        this.app.listMalfunction.listMalfunction();
      })

      this.malfunctionListResultPanel?.on('selectionchange', (view, nodes) => {
        if (nodes && nodes.length > 0) {
          const data = nodes[0].data;
          this.onNodeSelect(data);
        }
      });

      this.app.listMalfunction.malfunctionResult$.subscribe((malfunctionResult) => {
        this.updateTree(malfunctionResult);
      })

      this.app.feature.selectFeature$.subscribe(selFeature => {
        this.onFeatureSel(selFeature);
      })

    })
  }

  protected onNodeSelect(data) {
    const guid = data.guid;
    const layerId = data.layerId;
    const lat = data.lat;
    const lon = data.lon;
    if (guid) {
      this.app.api.wmsFeatureInfoByGUID(layerId, guid).then(feature => {
        if (feature) {
          // and select the feature
          this.app.feature.selectFeatureSingle(feature, this.curMap);
          this.curMap.flyTo(lat, lon);
        }
      })
    }
  }

  protected onFeatureSel(feature: Feature | null) {

    const selModel = this.malfunctionListResultPanel.getSelectionModel();

    selModel.suspendEvents();

    if (!feature) {
      //unselect
      selModel.deselectAll();
    } else {
      const record = this.malfunctionListResultPanel.getStore().findRecord('guid', feature.guid);
      if (record) {
        selModel.select(record);
      } else {
        selModel.deselectAll();
      }
    }

    selModel.resumeEvents();
  }

  protected updateTree(malfunctionResult: DataSetListResult[] | null) {
    const store = this.malfunctionListResultPanel.getStore();
    store.removeAll();
    if (!malfunctionResult || malfunctionResult.length == 0) {
      this.emptyMessagePanel.setHtml('<h4>Keine Mängel</h4>');
      return;
    }
    this.emptyMessagePanel.setHtml('');
    const storeData: [string, string, string, number, number, number, string][] = [];
    for (let dataSetResult of malfunctionResult) {
      //const layerName = layerResult.layerName + " [" + layerResult.resultCnt + "]";
      const layerResult = dataSetResult.layerResult;
      for (let dataSetResult of layerResult.dataSetResults) {
        const dsName = dataSetResult.nameAndResultCnt;
        for (let res of dataSetResult.results) {
          storeData.push([res.name, dsName, res.guid, layerResult.layerId, res.lat, res.lon, res.lastEntryPretty]);
        }
      }
    }
    store.loadData(storeData);
  }
}
