import {FI} from "@igis-common/model/FI";
import {DataField} from "@igis-common/model/DataField";
import {DataEntryInputField} from "./DataEntryInputField";
import {IExt} from "../../../ext-types/Ext";
import {ExtFormFieldBase} from "../../../ext-types/form/field/ExtFormFieldBase";

export class DataEntryTimeField extends DataEntryInputField {

  protected changeEventName = 'select';

  constructor(inputField: DataField, feature: FI, Ext: IExt) {

    super(inputField, feature, Ext);

    // create integer textbox
    const attrValue = this.getFeatureAttrValue();
    const cmp = {
      xtype: 'timefield'
    };

    this.cmpValue = <ExtFormFieldBase>Ext.create(cmp);
  }

  public addChangeListener(listener) {
  }

}
