
import {ReportCountGen} from "./generated/ReportCountGen";

export class ReportCount extends ReportCountGen {

  get count(): number {
    return this._itemCnt;
  }

}
