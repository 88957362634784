
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class ReportCountGen extends Model {
  protected _itemCnt: number;

  constructor(json: {itemCnt: number}, public _api: IGISApi) {
    super();
    this._itemCnt = json.itemCnt;
    this.onNew();
  }
}
