import {CRS, ImageOverlay, Layer as LeafletLayer, LayerOptions, WMSOptions, WMSParams} from "leaflet";
import {Layer, LayerEventType} from "@igis-common/model/Layer";
import {debounceTime} from "rxjs/operators";
import * as L from "leaflet";
import "./AuthImageOverlay"
import {Observable} from "rxjs";

interface IOverlayOptions extends LayerOptions, WMSOptions {
  crs?: CRS;
  opacity: number;
}

export class WMSOverlay extends LeafletLayer {

  private readonly wmsParams: WMSParams;
  private curToken: string | null = null;

  private _currentUrl: string = "";
  private _currentOverlay: L.AuthImageOverlay | null = null;

  options: IOverlayOptions = {
    opacity: 0
  };

  constructor(public name: string, public _url: string, private _options: IOverlayOptions) {
    super(_options);

    // fill with default values
    this.wmsParams = {
      'layers': '',
      'styles': '',
      'format': 'image/jpeg',
      'transparent': false
    }

    // Move WMS parameters to params object
    for (let opt in _options) {
      if (!(opt in this.options)) {
        this.wmsParams[opt] = _options[opt];
      }
    }
  }

  public setLayers(layers: string) {
    this.wmsParams.layers = layers;
    this.update(false);
  }

  public getAttribution(): string {
    return this.options.attribution ? this.options.attribution : '';
  }

  public onAdd(): this {
    this.update(false);
    return this;
  }

  public onRemove(map): this {
    if (this._currentOverlay) {
      map.removeLayer(this._currentOverlay);
      this._currentOverlay = null;
    }
    if (this._currentUrl) {
      this._currentUrl = '';
    }
    return this;
  }

  public getEvents() {
    return {
      'moveend': this.onUpdate
    }
  }

  public onUpdate() {
    this.update(false);
  }

  public update(force) {
    if (!this._map) {
      return;
    }
    // Determine image URL and whether it has changed since last update
    this.updateWmsParams(this._map);
    const url = this.getImageUrl(force);
    if (this._currentUrl === url) {
      return;
    }
    this._currentUrl = url;

    // Keep current image overlay in place until new one loads
    // (inspired by esri.leaflet)
    const bounds = this._map.getBounds();
    const overlay = L.authImageOverlay(url, bounds, {'opacity': 0});
    this.setHeaderInOverlay(overlay);
    overlay.addTo(this._map);

    overlay.once('load', () => {
      if (url != this._currentUrl) { // did the url change in the meantime?
        this._map.removeLayer(overlay);
        return;
      } else {
        if (this._currentOverlay) {
          this._map.removeLayer(this._currentOverlay);
        }
      }
      this._currentOverlay = overlay; // save our current image overlay
      overlay.setOpacity(
        this.options.opacity ? this.options.opacity : 1
      );
    });
  }

  public setOpacity(opacity) {
    this.options.opacity = opacity;
    if (this._currentOverlay) {
      this._currentOverlay.setOpacity(opacity);
    }
  }

  public setNewToken(token: string): void {
    this.curToken = token;
    this.setHeaderInOverlay(this._currentOverlay);
  }

  protected setHeaderInOverlay(overlay) {
    if (overlay) {
      overlay.setHeader("Authorization", "Bearer " + this.curToken);
    }
  }

  // See L.TileLayer.WMS: onAdd() & getTileUrl()
  private updateWmsParams(map) {
    if (!map) {
      map = this._map;
    }
    // Compute WMS options
    const bounds = map.getBounds();
    const size = map.getSize();
    const wmsVersion = parseFloat(this.wmsParams.version ? this.wmsParams.version : '');
    const crs = this.options.crs || map.options.crs;
    const nw = crs.project(bounds.getNorthWest());
    const se = crs.project(bounds.getSouthEast());

    // Assemble WMS parameter string
    const params = {
      'width': size.x,
      'height': size.y,
      bbox: (
        wmsVersion >= 1.3 && crs === L.CRS.EPSG4326 ?
          [se.y, nw.x, nw.y, se.x] :
          [nw.x, se.y, se.x, nw.y]
      ).join(',')
    }

    const projectionKey = wmsVersion >= 1.3 ? 'crs' : 'srs';
    params[projectionKey] = crs.code;

    for (let key in params) {
      this.wmsParams[key] = params[key];
    }
  }

  private getImageUrl(force: boolean = false) {
    const paramString = L.Util.getParamString(this.wmsParams, this._url);
    if (force) {
      const d = new Date();
      const n = d.getTime();
      return this._url + paramString + "&dummy=" + n;
    } else {
      return this._url + paramString;
    }
  }
}

WMSOverlay.prototype.options = {
  crs: undefined,
  uppercase: false,
  attribution: '',
  opacity: 1
}

interface WMSSourceOptions extends IOverlayOptions {
  format: string;
  transparent: boolean;
  opacity: number;
}

/**
 * The Source object manages a single WMS connection.  Multiple "layers" can be
 * created with the getLayerByName function, but a single request will be sent for
 * each image update. It is using non-tiled "overlay" mode.
 */
export class WMSLayer extends LeafletLayer {

  private readonly _overlay: WMSOverlay;

  options: WMSSourceOptions = {
    format: "",
    transparent: false,
    opacity: 0
  };

  constructor(public layer: Layer, public _url: string, options: WMSSourceOptions, token$: Observable<string | null>) {
    super(options);
    this.options = options;
    this._overlay = new WMSOverlay(layer.name, this._url, this.options);

    // subscribe to our layer's visibility observable
    // debounce the state change, sometimes a lot of state changes regarding groups etc. can bubble up at the same time
    layer.stateChangesRecursive$.pipe(
      debounceTime(50)
    ).subscribe((event) => {
      // some visibility has changed, redraw ourself
      console.log(`refresh overlay in ${layer.name}`);
      switch (event.eventType) {
        case LayerEventType.NEW_VISIBLE:
          this.refreshOverlay();
          break;
        case LayerEventType.RELOAD:
          this.forceRedraw();
          break;
      }
    });

    // we update the access token for the WMS requests as soon as a new token is available
    token$.subscribe(newToken => {
      if (newToken) {
        this._overlay.setNewToken(newToken);
      }
    })

  }

  public forceRedraw() {
    if (this._overlay) {
      this._overlay.update(true);
    }
  }

  public onAdd(): this {
    this.refreshOverlay();
    return this;
  }

  public setOpacity(opacity) {
    this.options.opacity = opacity;
    if (this._overlay) {
      this._overlay.setOpacity(opacity);
    }
  }

  public refreshOverlay() {

    if (!this._map) {
      return;
    }
    // order layers by orderNr
    const subLayerList = this.layer.getVisibleChildren();
    if (subLayerList.length > 0) {
      // compile name list of layers
      const subLayers = Array.from(subLayerList, child => child.wmsName).reverse().join(',');
      this._overlay.setLayers(subLayers);
      this._overlay.addTo(this._map);
    } else {
      this._overlay.remove();
    }

  }
}
