import {IGISAppBase} from "../IGISAppBase";

export abstract class Component {

  protected app: IGISAppBase;

  constructor(app: IGISAppBase) {
    this.app = app;
  }

  public abstract init(): void;
}

