import {FI} from "@igis-common/model/FI";
import {DataField} from "@igis-common/model/DataField";
import {DataEntryInputField} from "./DataEntryInputField";
import {IExt} from "../../../ext-types/Ext";
import {ExtFormFieldBase} from "../../../ext-types/form/field/ExtFormFieldBase";
import {ExtFormFieldDateConfig} from "../../../ext-types/form/field/ExtFormFieldDate";


export class DataEntryDateField extends DataEntryInputField {

  protected changeEventName = 'select';

  constructor(inputField: DataField, feature: FI, Ext: IExt) {

    super(inputField, feature, Ext);

    // create date picker
    let initValue: Date | null = null;
    const attrValue = this.getFeatureAttrValue(true);
    if (attrValue) {
      initValue = new Date(attrValue);
    }

    const cmp: ExtFormFieldDateConfig = {
      xtype: 'datefield',
      value: initValue
    }

    this.cmpValue = <ExtFormFieldBase>Ext.create(cmp);
  }

  public addChangeListener(listener) {
  }

  public getValue(): any {
    const val = super.getValue();
    if (val && !isNaN(val.getTime())) {
      return val.toISOString();
    } else {
      return val;
    }
  }
}
