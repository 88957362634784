/**
 * PrintExtentSelect
 * A leaflet layer for selecting rectangles based on given scales and a fixed height/width ratio.
 * Based on code from leaflet-locationfilter (https://github.com/kajic/leaflet-locationfilter/), MIT licenced (2021-10-17)
 */
import {DomEvent, LatLng, LatLngBounds, Layer, LayerGroup, Marker, Rectangle} from "leaflet";
import './printextent.css';
import * as L from "leaflet";

type scaleChangeCallback = (any) => (void);
type centerMoveCallback = (LatLng) => (void);

export type ScaleConfig = {
  dLon: number;
  id: any;
}

export type PrintExtentSizeConfig = {
  lat2LonRatio: number;
  scales: ScaleConfig[];
}

export class PrintExtentSelect extends Layer {

  protected _sizeConfig: PrintExtentSizeConfig | null = null;
  protected _selectedScale: ScaleConfig | null = null;
  protected _snappedBounds: LatLngBounds | null = null;
  protected _center: LatLng | null = null;

  // current, snapped rectangle
  protected _snappedRect: Rectangle | null = null;

  // for drawing draggable markers
  protected _markers: Marker[] = [];
  protected _corners: LatLng[] = [];
  protected _moveMarker: Marker | null = null;
  protected _rect: Rectangle | null = null;

  protected _moveHandler: any;

  private _enabled: boolean = false;
  private _layer: LayerGroup | null = null;

  protected _initialDrawCalled: boolean = false;

  constructor(private scaleChangeCallback: scaleChangeCallback, private mapCenterChangeCallback: centerMoveCallback, props?) {
    super(props);
  }


  public onAdd(map: L.Map): this {
    map.addLayer(this);
    return this;
  }

  public onRemove(map: L.Map): this {
    this.disable();
    return this;
  }

  private _createRectangle(bounds, options?): Rectangle {
    options = options || {};
    const defaultOptions = {
      stroke: false,
      fill: true,
      fillColor: "black",
      fillOpacity: 0.3,
      clickable: false
    }
    options = L.Util.extend(defaultOptions, options);
    const rect = new L.Rectangle(bounds, options);
    if (this._layer) {
      rect.addTo(this._layer);
    }
    return rect;
  }

  /**
   * Create a draggable marker with the given options
   * @param point
   * @param options
   * @protected
   */
  protected _createImageMarker(point, options): Marker {
    const marker = new L.Marker(point, {
      icon: new L.DivIcon({
        iconAnchor: options.anchor,
        iconSize: options.size,
        className: options.className
      }),
      draggable: true
    });
    if (this._layer) {
      marker.addTo(this._layer);
    }
    return marker;
  }

  /**
   * Draw a move marker. Sets up drag listener that updates the coordinates of every marker + rectangle
   * @param point
   * @protected
   */
  protected _drawMoveMarker(point) {
    this._moveMarker = this._createImageMarker(point, {
      "className": "print-extent move-marker",
      "anchor": [-10, -10],
      "size": [13, 13]
    });
    this._moveMarker.on('drag', (e) => {
      // @ts-ignore
      const markerPos = this._moveMarker.getLatLng();
      const nw = this._corners[0];
      const ne = this._corners[1];
      const sw = this._corners[3];
      const se = this._corners[2];

      const latDelta = markerPos.lat - nw.lat;
      const lngDelta = markerPos.lng - nw.lng;
      this._corners = [
        new LatLng(nw.lat + latDelta, nw.lng + lngDelta),
        new LatLng(ne.lat + latDelta, ne.lng + lngDelta),
        new LatLng(se.lat + latDelta, se.lng + lngDelta),
        new LatLng(sw.lat + latDelta, sw.lng + lngDelta)
      ]
      if (this._center) {
        this._center = new LatLng(this._center.lat + latDelta, this._center.lng + lngDelta);
      }

      this.mapCenterChangeCallback(this._center);

      // recalculate our snapped bounds
      this._calcSnappedBounds();

      this._draw();
      DomEvent.stop(e);
    });
    //this._setupDragendListener(this._moveMarker);
    return this._moveMarker;
  }

  /**
   * Create the resize marker
   * @param point
   * @protected
   */
  protected _createResizeMarker(point, extraClass: string): Marker {
    return this._createImageMarker(point, {
      "className": "print-extent resize-marker " + extraClass,
      "anchor": [7, 6],
      "size": [13, 12]
    });
  }

  protected _calcSnappedBounds() {
    // calculate current snapped bounds from selected scale & center
    // create snapped rectangle
    if (this._selectedScale && this._center && this._sizeConfig) {
      const dLon = this._selectedScale.dLon;
      const dLat = dLon * this._sizeConfig.lat2LonRatio;

      const _sw = new LatLng(this._center.lat - dLat / 2, this._center.lng - dLon / 2);
      const _ne = new LatLng(this._center.lat + dLat / 2, this._center.lng + dLon / 2);
      this._snappedBounds = new L.LatLngBounds(_sw, _ne);
    }
  }

  /* Track moving of the given resize marker and update the markers
     given in options.moveAlong to match the position of the moved
     marker. Update filter corners and redraw the filter */
  protected _setupResizeMarkerTracking(marker: Marker) {
    marker.on('drag', (e) => {
      if (!this._center || !this._sizeConfig) {
        return;
      }
      const curPosition = marker.getLatLng();

      // calculate distance to center
      const center = this._center;
      let dLat = curPosition.lat - center.lat;
      let dLon = curPosition.lng - center.lng;

      // restrict marker movement
      const dist = Math.sqrt(dLat * dLat + dLon * dLon);
      // we have a set aspect ratio
      // calc new dLon
      dLon = dist / Math.sqrt(1 + this._sizeConfig.lat2LonRatio * this._sizeConfig.lat2LonRatio);
      dLat = dLon * this._sizeConfig.lat2LonRatio;

      const _sw = new LatLng(this._center.lat - dLat, this._center.lng - dLon);
      const _ne = new LatLng(this._center.lat + dLat, this._center.lng + dLon);
      const bounds = new L.LatLngBounds(_sw, _ne);
      this._corners = [
        bounds.getNorthWest(),
        bounds.getNorthEast(),
        bounds.getSouthEast(),
        bounds.getSouthWest()
      ]

      this._selectScale(dLon * 2); // we select the nearest scale that fits our current width
      this._calcSnappedBounds();

      this._draw();
    });
    this._setupDragendListener(marker);
  }

  /* Emit a change event whenever dragend is triggered on the
     given marker */
  protected _setupDragendListener(marker) {
    marker.on('dragend', (e) => {
      // we are done dragging, set corners to the snapped bounds
      const bounds = this._snappedBounds;
      if (bounds) {
        this._corners = [
          bounds.getNorthWest(),
          bounds.getNorthEast(),
          bounds.getSouthEast(),
          bounds.getSouthWest()
        ]
      }
      this._draw();
      DomEvent.stop(e);
    });
  }

  /* Initializes rectangles and markers */
  protected _initialDraw() {
    if (this._initialDrawCalled) {
      return;
    }

    this._layer = new L.LayerGroup();

    // Create outer rectangle
    this._rect = this._createRectangle(new LatLngBounds(this._corners[3], this._corners[1]));
    this._snappedRect = this._createRectangle(this._snappedBounds, {
      fillOpacity: 0.3,
      stroke: true,
      color: "orange",
      fillColor: "orange",
      weight: 1,
      opacity: 0.3
    });

    // Create resize markers
    let ii = 0;
    for (let corner of this._corners) {
      const marker = this._createResizeMarker(corner, (ii++ % 2 == 0) ? 'resize-marker-nwse' : 'resize-marker-nesw');
      // Setup tracking of resize marker.
      this._setupResizeMarkerTracking(marker);
      this._markers.push(marker);
    }

    // Create move marker
    this._moveMarker = this._drawMoveMarker(this._corners[0]); // on the northwest corner

    this._initialDrawCalled = true;
  }

  /**
   * Reposition all markers & rectangles based on current coordinates
   * @protected
   */
  protected _draw() {
    if (!this._rect || !this._snappedRect || !this._moveMarker || !this._snappedBounds) {
      return;
    }
    // Reposition rectangle
    this._rect.setBounds(new LatLngBounds(this._corners[3], this._corners[1]))
    this._snappedRect.setBounds(this._snappedBounds);

    // Reposition resize markers
    for (let ii = 0; ii < 4; ii++) {
      this._markers[ii].setLatLng(this._corners[ii]);
    }

    // Reposition the move marker
    this._moveMarker.setLatLng(this._corners[0]);
  }

  /* Adjust the print extent selector to the current map bounds */
  protected _adjustToMap() {
    const mapBounds = this._map.getBounds();
    const curdLon = (mapBounds.getEast() - mapBounds.getWest()) * 0.5;
    this._selectScale(curdLon);
  }

  protected _selectScale(curdLon: number) {
    if (!this._sizeConfig) {
      return;
    }
    // find the scale that is nearest to
    let selScale: ScaleConfig | null = null;
    let curDist = Number.MAX_VALUE;
    for (let scale of this._sizeConfig.scales) {
      const dist = Math.abs(curdLon - scale.dLon);
      if (dist < curDist) {
        curDist = dist;
        selScale = scale;
      }
    }

    if (this._selectedScale !== selScale) {
      this._selectedScale = selScale;
      this._calcSnappedBounds();
      this.scaleChangeCallback(selScale?.id);
    }
  }

  protected _setSizeConfig(sizeConfig: PrintExtentSizeConfig): void {
    this._sizeConfig = sizeConfig;
    this._adjustToMap();
  }

  public setScale(id: any): ScaleConfig | null {
    if (!this._enabled || !this._sizeConfig) {
      return null;
    }
    // try to find this scale in the current config
    for (let scaleConfig of this._sizeConfig.scales) {
      if (scaleConfig.id == id) {
        this._selectedScale = scaleConfig;
        this._updateSelectedScale();
        return scaleConfig;
      }
    }
    return null; // nothing found?
  }

  protected _updateSelectedScale(): void {
    this._calcSnappedBounds();
    const bounds = this._snappedBounds;
    if (bounds) {
      this._corners = [
        bounds.getNorthWest(),
        bounds.getNorthEast(),
        bounds.getSouthEast(),
        bounds.getSouthWest()
      ]
    }
    this._draw();
  }

  /**
   * Enable the print extent selector with the given sizes
   * @param sizeConfig
   */
  public enable(sizeConfig: PrintExtentSizeConfig): ScaleConfig | null {

    this._setSizeConfig(sizeConfig);

    if (this._enabled) {
      // just set sizes & redraw
      this._updateSelectedScale();
      return this._selectedScale;
    }

    const mapBounds = this._map.getBounds();

    this._center = mapBounds.getCenter();

    this.mapCenterChangeCallback(this._center);

    if (!this._selectedScale) {
      return null; // we did not find any scale?
    }
    const dLon = this._selectedScale.dLon;
    const dLat = dLon * sizeConfig.lat2LonRatio;

    // Initialize corners
    const _sw = new LatLng(this._center.lat - dLat / 2, this._center.lng - dLon / 2);
    const _ne = new LatLng(this._center.lat + dLat / 2, this._center.lng + dLon / 2);
    const bounds = new L.LatLngBounds(_sw, _ne);
    this._corners = [
      bounds.getNorthWest(),
      bounds.getNorthEast(),
      bounds.getSouthEast(),
      bounds.getSouthWest()
    ]

    this._calcSnappedBounds();

    // Draw filter
    this._initialDraw();
    this._draw();

    // Set up map move event listener
    this._moveHandler = (e) => {
      this._draw();
      DomEvent.stop(e);
    };
    this._map.on("move", this._moveHandler);

    // Add the layer to the map
    if (this._layer) {
      this._layer.addTo(this._map);
    }

    this._enabled = true;

    // Fire the enabled event
    this.fire("enabled");

    return this._selectedScale;
  }

  /**
   * Disable the print extent selector control and remove from map.
   */
  public disable() {
    if (!this._enabled) {
      return;
    }

    this._center = null;
    this._selectedScale = null;

    // Remove event listener
    this._map.off("move", this._moveHandler);

    // Remove rectangle layer from map
    if (this,this._layer) {
      this._map.removeLayer(this._layer);
    }

    this._enabled = false;

    // Fire the disabled event
    this.fire("disabled");
  }
}
