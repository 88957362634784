
import {ExportsGen} from "./generated/ExportsGen";
import {Export} from "@igis-common/model/Export";

export class Exports extends ExportsGen {

  get exports(): Export[] {
    return this._exports;
  }

}
