import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../igis-base";
import {ExtPanel} from "../../ext-types/panel/ExtPanel";
import {first, map, switchMap} from "rxjs/operators";
import {COMP_IDS} from "./GUIDefinitions";
import {MapComponent} from "@igis-common/component/MapComponent";
import {Layer} from "@igis-common/model/Layer";
import {WMSMapComponent} from "@igis-common/component/WMSMapComponent";
import {ImageMapComponent} from "@igis-common/component/ImageMapComponent";

import '@igis-common/css/legend.css';

export class LegendComponent extends Component {

  private panel: ExtPanel;
  
  private map: MapComponent | null;
  private rootLayer: Layer | null;

  private isVisible: boolean = false;

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {
    this.app.gui.gui$.pipe(first()).subscribe(Ext => {

      this.panel = <ExtPanel>Ext.getCmp(COMP_IDS.LEGEND_PANEL);
      this.panel.on('expand', () => {
        this.render();
      }, this);
      this.panel.on('collapse', () => {
        this.isVisible = false;
      }, this);

      this.app.mapRoot$.pipe(switchMap(({curMap, rootLayer}) => {
        return curMap.mapMove$.pipe(map(() => {
          return {curMap, rootLayer};
        }));
      })).subscribe(({curMap, rootLayer}) => {
        this.onMapChange(curMap, rootLayer);
      });

      this.app.layerVisChange$.subscribe(({curMap, rootLayer}) => {
        this.onMapChange(curMap, rootLayer);
      })
    })
  }

  protected onMapChange(map: MapComponent, rootLayer: Layer) {

    this.map = map;
    this.rootLayer = rootLayer;

    if (this.isVisible) {
      this.render();
    }
  }

  protected async render() {

    this.panel.setDisabled(false);
    if (!this.map || !this.rootLayer) {
      return;
    }
    if (this.map instanceof WMSMapComponent) {
      const layers: Layer[] = [];
      // retrieve flat layer list
      for (let baseLayer of this.rootLayer.children) {
        for (let layer of baseLayer.getVisibleChildren(true)) {
          layers.push(layer);
        }
      }
      if (layers.length > 0) {
        // construct url for the GetLegendGraphics call
        const url = await this.map.getLegendUrl(layers);
        this.panel.removeAll();
        this.panel.add({
          xtype: 'image',
          src: url,
          padding: '10 0 0 10',
          alt: 'legend graphic',
        });
      }

    } else if (this.map instanceof ImageMapComponent) {
      const legend = await this.map.getVisibleLegendItems();
      // render html for legend
      let html = '<div class="igis_legend">';
      for (const layerKey in legend) {
        const layer = legend[layerKey];
        html += `<div class="layer">${layer.name}</div><br>`;
        for(const itemKey in layer.items) {
          const item = layer.items[itemKey];
          html += `<div class="icon"><img alt="icon" src="${item.svgUrl}"/></div><div class="label">${item.name}</div><br>`;
        }
      }
      this.panel.removeAll();
      this.panel.add({
        html: html
      });
    }

    this.isVisible = true;
  }
}
