
import {FIAttr} from '../FIAttr';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class FIGen extends Model {
  protected _guid: string;
  protected _wkt: string;
  protected _attrs: FIAttr[];
  protected _layerId: number;

  constructor(json: {guid: string, wkt: string, attrs: {name: string, value: string}[], layerId: number}, public _api: IGISApi) {
    super();
    this._guid = json.guid;
    this._wkt = json.wkt;
    this._attrs =  json.attrs.map(item => new FIAttr(item, _api));
    this._layerId = json.layerId;
    this.onNew();
  }
}
