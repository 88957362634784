
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class DataStatusEntryGen extends Model {
  protected _name: string;
  protected _value: string;

  constructor(json: {name: string, value: string}, public _api: IGISApi) {
    super();
    this._name = json.name;
    this._value = json.value;
    this.onNew();
  }
}
