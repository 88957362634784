import {VisualizationColumnGen} from "./generated/VisualizationColumnGen";
import {StorageEntry} from "@igis-common/model/StorageEntry";

export class VisualizationColumn extends VisualizationColumnGen {
  get attributeName(): string {
    return this._attributeName;
  }

  public lookupOption(value: string): StorageEntry | undefined {
    for (let option of this._visOptions) {
      if (option.value == value) {
        return option.visStorageEntry;
      }
    }
    return undefined;
  }

}
