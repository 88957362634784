
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class StorageEntryGen extends Model {
  protected _uuid: string;
  protected _filename: string;
  protected _isPic: boolean;
  protected _isPanorama: boolean;
  protected _hasThumb: boolean;
  protected _comment: string;
  protected _href: string;

  constructor(json: {uuid: string, filename: string, isPic: boolean, isPanorama: boolean, hasThumb: boolean, comment: string, href: string}, public _api: IGISApi) {
    super();
    this._uuid = json.uuid;
    this._filename = json.filename;
    this._isPic = json.isPic;
    this._isPanorama = json.isPanorama;
    this._hasThumb = json.hasThumb;
    this._comment = json.comment;
    this._href = json.href;
    this.onNew();
  }
}
