import {FIResult} from "@igis-common/model/FIResult";
import {FI} from "@igis-common/model/FI";
import {FIResultLayer} from "@igis-common/model/FIResultLayer";
import {Layer} from "@igis-common/model/Layer";

export class FIResultFromFeature extends FIResult {
  constructor(feature: FI, layer: Layer) {
    super();

    const resultLayer = new FIResultLayer(layer);
    this.fiResultLayers = [resultLayer];
    resultLayer.addFeature(feature);
  }
}
