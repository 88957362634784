
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class ChangeFeatureResultGen extends Model {
  protected _featureGUID: string;

  constructor(json: {featureGUID: string}, public _api: IGISApi) {
    super();
    this._featureGUID = json.featureGUID;
    this.onNew();
  }
}
