
import {ProjectListGen} from "./generated/ProjectListGen";
import {ProjectListEntry} from "@igis-common/model/ProjectListEntry";

export class ProjectList extends ProjectListGen {

  get projectList(): ProjectListEntry[] {
    return this._projectList;
  }

}
