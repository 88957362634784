import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../igis-base";
import {COMP_IDS} from "./GUIDefinitions";
import {ExtGridPanel} from "../../ext-types/grid/ExtGridPanel";
import {MapComponent} from "@igis-common/component/MapComponent";
import {ExtPanel} from "../../ext-types/panel/ExtPanel";
import {Feature} from "@igis-common/model/Feature";
import {DataSetListResult} from "@igis-common/model/DataSetListResult";

export class ListPastDueGUIComponent extends Component {

  protected pastDueResultPanel: ExtGridPanel;
  protected emptyMessagePanel: ExtPanel;

  protected curMap: MapComponent;

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {

    this.app.mapChange$.subscribe(map => this.curMap = map);

    this.app.startup$.subscribe(({Ext, projectInfo}) => {
      this.pastDueResultPanel = <ExtGridPanel>Ext.getCmp(COMP_IDS.PAST_DUE.RESULT_PANEL);
      this.emptyMessagePanel = <ExtPanel>Ext.getCmp(COMP_IDS.PAST_DUE.EMPTY_MESSAGE);

      const panel = <ExtPanel>Ext.getCmp(COMP_IDS.PAST_DUE.PANEL);
      panel.on('collapse', () => { // clear result when user switches away from panel
        this.app.pastDue.clear();
      })

      panel.on('expand', () => { // get result when user switches to panel
        this.app.pastDue.listPastDue();
      })

      this.pastDueResultPanel?.on('selectionchange', (view, nodes) => {
        if (nodes && nodes.length > 0) {
          const data = nodes[0].data;
          this.onNodeSelect(data);
        }
      });

      this.app.pastDue.pastDueResult$.subscribe((pastDueResult) => {
        this.updateTree(pastDueResult);
      })

      this.app.feature.selectFeature$.subscribe(selFeature => {
        this.onFeatureSel(selFeature);
      })

    })
  }

  protected onNodeSelect(data) {
    const guid = data.guid;
    const layerId = data.layerId;
    const lat = data.lat;
    const lon = data.lon;
    if (guid) {
      this.app.api.wmsFeatureInfoByGUID(layerId, guid).then(feature => {
        if (feature) {
          // and select the feature
          this.app.feature.selectFeatureSingle(feature, this.curMap);
          this.curMap.flyTo(lat, lon);
        }
      })
    }
  }

  protected onFeatureSel(feature: Feature | null) {

    const selModel = this.pastDueResultPanel.getSelectionModel();

    selModel.suspendEvents();

    if (!feature) {
      //unselect
      selModel.deselectAll();
    } else {
      const record = this.pastDueResultPanel.getStore().findRecord('guid', feature.guid);
      if (record) {
        selModel.select(record);
      } else {
        selModel.deselectAll();
      }
    }

    selModel.resumeEvents();
  }

  protected updateTree(pastDueResult: DataSetListResult[] | null) {
    const store = this.pastDueResultPanel.getStore();
    store.removeAll();
    if (!pastDueResult || pastDueResult.length == 0) {
      this.emptyMessagePanel.setHtml('<h4>Keine offenen Objekte</h4>');
      return;
    }
    this.emptyMessagePanel.setHtml('');
    const storeData: [string, string, string, number, number, number, string][] = [];
    for (let dataSetResult of pastDueResult) {
      //const layerName = layerResult.layerName + " [" + layerResult.resultCnt + "]";
      const layerResult = dataSetResult.layerResult;
      for (let dataSetResult of layerResult.dataSetResults) {
        const dsName = dataSetResult.nameAndResultCnt;
        for (let res of dataSetResult.results) {
          storeData.push([res.name, dsName, res.guid, layerResult.layerId, res.lat, res.lon, res.lastEntryPretty]);
        }
      }
    }
    store.loadData(storeData);
  }
}
