import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../igis-base";
import {COMP_IDS} from "./GUIDefinitions";
import {ExtGridPanel} from "../../ext-types/grid/ExtGridPanel";
import {ExtButton} from "../../ext-types/button/ExtButton";
import {ExtFormFieldText} from "../../ext-types/form/field/ExtFormFieldText";
import {SearchResult} from "@igis-common/model/SearchResult";
import {MapComponent} from "@igis-common/component/MapComponent";
import {ExtPanel} from "../../ext-types/panel/ExtPanel";
import {Feature} from "@igis-common/model/Feature";
import {ImageMapComponent} from "@igis-common/component/ImageMapComponent";

export class SearchGUIComponent extends Component {

  protected searchResultPanel: ExtGridPanel;
  protected searchButton: ExtButton;
  protected searchTextField: ExtFormFieldText;
  protected emptyMessagePanel: ExtPanel;

  protected curMap: MapComponent;

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {

    this.app.mapChange$.subscribe(map => this.curMap = map);

    this.app.startup$.subscribe(({Ext, projectInfo}) => {
      this.searchResultPanel = <ExtGridPanel>Ext.getCmp(COMP_IDS.SEARCH.RESULT_PANEL);
      this.searchButton = <ExtButton>Ext.getCmp(COMP_IDS.SEARCH.BUTTON);
      this.searchTextField = <ExtFormFieldText>Ext.getCmp(COMP_IDS.SEARCH.TEXTFIELD);
      this.emptyMessagePanel = <ExtPanel>Ext.getCmp(COMP_IDS.SEARCH.EMPTY_MESSAGE);

      this.searchButton.setHandler(() => this.onSearchClick());

      const panel = <ExtPanel>Ext.getCmp(COMP_IDS.SEARCH.PANEL);
      panel.on('collapse', () => { // clear search result when user switches away from search panel
        this.searchTextField.setValue('');
        this.app.search.clear();
      })

      this.searchResultPanel?.on('selectionchange', (view, nodes) => {
        if (nodes && nodes.length > 0) {
          const data = nodes[0].data;
          this.onNodeSelect(data);
        }
      });

      this.app.search.searchResult$.subscribe((searchResult) => {
        this.updateTree(searchResult);
      })

      this.app.feature.selectFeature$.subscribe(selFeature => {
        this.onFeatureSel(selFeature);
      })

    })
  }

  protected onNodeSelect(data) {
    const guid = data.guid;
    const layerId = data.layerId;
    const lat = data.lat;
    const lon = data.lon;
    if (guid) {
      this.curMap.queryFeature(layerId, guid).then(feature => {
        if (feature) {
          // and select the feature
          this.app.feature.selectFeatureInfo(feature);

          if (this.curMap instanceof ImageMapComponent) {
            this.curMap.flyTo(data.y, data.x);
          } else {
            this.curMap.flyTo(lat, lon);
          }
        }
      })
    }
  }

  protected onFeatureSel(feature: Feature | null) {

    const selModel = this.searchResultPanel.getSelectionModel();

    selModel.suspendEvents();

    if (!feature) {
      //unselect
      selModel.deselectAll();
    } else {
      const record = this.searchResultPanel.getStore().findRecord('guid', feature.guid);
      if (record) {
        selModel.select(record);
      } else {
        selModel.deselectAll();
      }
    }

    selModel.resumeEvents();
  }

  protected onSearchClick() {
    const queryString = this.searchTextField.getValue().toString();
    this.app.search.search(queryString);
  }

  protected updateTree(searchResult: SearchResult | null) {
    const store = this.searchResultPanel.getStore();
    store.removeAll();
    if (!searchResult || searchResult.layerResults.length == 0) {
      this.emptyMessagePanel.setHtml('<h4>Keine Ergebnisse</h4>');
      return;
    }
    this.emptyMessagePanel.setHtml('');
    const storeData: [string, string, string, number, number, number, number, number][] = [];
    for (let layerResult of searchResult.layerResults) {
      for (let res of layerResult.resultEntries) {
        storeData.push([res.name, layerResult.layerName, res.guid, layerResult.layerId, res.lat, res.lon, res.x, res.y]);
      }
    }
    store.loadData(storeData);
  }
}
