
import {DataFieldCategoryGen} from "./generated/DataFieldCategoryGen";
import {DataField} from "@igis-common/model/DataField";
import {Model, ValueMap} from "@igis-common/api/Model";

export class DataFieldCategory extends DataFieldCategoryGen {

  get name(): string {
    return this._name;
  }

  get fields(): DataField[] {
    return this._fields;
  }

  public isVisible(valueMap: ValueMap) {
    return Model.evalCond(valueMap, this._condVisible);
  }

  get hidden(): boolean {
    return this._hidden;
  }

  get isCommon(): boolean {
    return this._id == -1;
  }

  get orderNr(): number {
    return this._orderNr;
  }
}
