// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../desktop/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../desktop/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.igis_legend {
    font-family: sans-serif;
    padding-left: 10px;
    padding-top: 10px;
}

.igis_legend .layer {
    font-weight: bold;
    font-size: 12pt;
    padding-bottom: 2mm;
}

.igis_legend .icon {
    display: inline-block;
    padding-bottom: 2mm
}

.igis_legend .icon img {
    width: 15mm;
    height: 15mm
}

.igis_legend .label {
    padding-left: 2mm;
    overflow: hidden;
    line-height: 15mm;
    display: inline-block;
    font-size: 11pt
}
`, "",{"version":3,"sources":["webpack://./../igis-common/src/css/legend.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB;AACJ;;AAEA;IACI,WAAW;IACX;AACJ;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,qBAAqB;IACrB;AACJ","sourcesContent":[".igis_legend {\n    font-family: sans-serif;\n    padding-left: 10px;\n    padding-top: 10px;\n}\n\n.igis_legend .layer {\n    font-weight: bold;\n    font-size: 12pt;\n    padding-bottom: 2mm;\n}\n\n.igis_legend .icon {\n    display: inline-block;\n    padding-bottom: 2mm\n}\n\n.igis_legend .icon img {\n    width: 15mm;\n    height: 15mm\n}\n\n.igis_legend .label {\n    padding-left: 2mm;\n    overflow: hidden;\n    line-height: 15mm;\n    display: inline-block;\n    font-size: 11pt\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
