import {Component} from "@igis-common/component/Component";
import {APP_MODE, IGISAppBase} from "@igis-common/IGISAppBase";
import {Observable, ReplaySubject, Subject} from "rxjs";
import {Layer} from "@igis-common/model/Layer";
import * as Wkt from 'wicket';
import * as L from "leaflet";
import {
  CircleMarker,
  LatLng,
  LatLngBounds,
  Layer as LeafletLayer,
  LayerOptions,
  Map,
  Point,
  Polygon,
  Polyline,
  Projection
} from "leaflet";
import '../css/map.css';
import {debounceTime, map, switchMap} from "rxjs/operators";
import {DataSet} from "@igis-common/model/DataSet";
import * as geojson from "geojson";
import {SearchResult} from "@igis-common/model/SearchResult";
import {FI} from "@igis-common/model/FI";
import {FIResult} from "@igis-common/model/FIResult";
import {PrintExtentSelect, PrintExtentSizeConfig} from "@igis-common/leaflet/printextent/PrintExtentSelect";
import {Feature} from "@igis-common/model/Feature";

interface IMarkerMap {
  [key: string]: LeafletObject
}

export interface LeafletObject extends LeafletLayer {
  toGeoJSON(): geojson.Feature;

  options: LayerOptions & {
    guid: string;
  }
}

export type MapPos = {
  zoom: number;
  lat: number;
  lng: number;
}

export abstract class MapComponent extends Component {

  protected rootLayerSubject = new ReplaySubject<Layer>(1);
  public rootLayer$: Observable<Layer> = this.rootLayerSubject;

  protected activeLayersSubject = new ReplaySubject<Layer[] | null>(1);
  public activeLayers$: Observable<Layer[] | null> = this.activeLayersSubject;
  protected activeLayers: Layer[] | null = null;

  protected oldActiveLayers: Layer[] | null = null;

  protected activeLayerLockSubject = new ReplaySubject<boolean>(1);
  public activeLayerLock$: Observable<boolean> = this.activeLayerLockSubject;
  protected activeLayerLocked: boolean = false;

  /**
   * Publishes info when the user moved/zoomed the map, or the map was resized
   * @protected
   */
  protected mapMoveSubject = new Subject<MapPos | null>();
  public mapMove$: Observable<MapPos | null> = this.mapMoveSubject.pipe(debounceTime(100));

  protected printExtentScaleChangeSubject = new Subject<any>();
  public printExtentScaleChange$: Observable<any> = this.printExtentScaleChangeSubject;

  protected printExtentMapCenterSubject = new Subject<LatLng>();
  public printExtentMapCenterChange$: Observable<LatLng> = this.printExtentMapCenterSubject;

  /**
   * Hold the currently active dataset.
   */
  protected curDataSet: DataSet | null = null;

  /**
   * Is called and completed when a map is registered
   */
  protected mapSubject = new ReplaySubject<Map>(1);
  public map$: Observable<Map> = this.mapSubject;

  protected map: Map | null = null;
  protected fiResultMarkers: IMarkerMap = {};
  protected selectedFeatureMarker: LeafletObject | null = null;

  // current app mode
  protected curMode: APP_MODE = APP_MODE.MODE_DEFAULT;

  protected printExtentSelector: PrintExtentSelect | null = null;

  constructor(app: IGISAppBase) {
    super(app);
  }

  public init() {
    // listen to mode changes
    this.app.modeChange$.subscribe(newMode => this.curMode = newMode);

    // collect active datasets
    // TODO: is this is a case of combineLatest
    const dataSetActivate1 =
      this.rootLayer$.pipe(
        switchMap((rootLayer: Layer) => {
            return this.app.feature.beginSelectDataSet$.pipe(
              map((dataSet: DataSet) => {
                  return {rootLayer, dataSet};
                }
              )
            );
          }
        ))

    const dataSetActivate2 =
      this.rootLayer$.pipe(
        switchMap((rootLayer: Layer) => {
          return this.app.feature.beginDataEntry$.pipe(
            map((beginDataEntry) => {
              const dataSet = beginDataEntry.dataSet;
              return {rootLayer, dataSet};
            })
          );
        })
      )

    //concat(dataSetActivate1, dataSetActivate2).subscribe((vals: { dataSet: DataSet, rootLayer: Layer }) => {
    dataSetActivate1.subscribe((vals: { dataSet: DataSet, rootLayer: Layer }) => {
        const dataSet = vals.dataSet;
        const rootLayer = vals.rootLayer;
        this.onDataSetSelect(rootLayer, dataSet);
      }
    )

    this.rootLayer$.pipe(switchMap((rootLayer) => {
      return this.app.feature.selectDataSet$.pipe(
        map((dataSet) => {
          return {rootLayer, dataSet};
        }))
    })).subscribe(({rootLayer, dataSet}) => {
      if (!dataSet) { // clear dataset layers when dataset is disabled
        this.onDataSetCleared(rootLayer);
      }
    })

  }

  public clearFIResult(): void {
  }

  public injectMapMove(): void {
    this.mapMoveSubject.next(null);
  }

  public setPrintSelectSizes(sizeConfig: PrintExtentSizeConfig) {
    if (this.printExtentSelector) {
      this.printExtentSelector.enable(sizeConfig);
    }
  }

  public setPrintScale(id: any) {
    if (this.printExtentSelector) {
      this.printExtentSelector.setScale(id);
    }
  }

  public removePrintSelect() {
    if (this.printExtentSelector) {
      this.printExtentSelector.disable();
    }
  }

  protected setLayerLock(lock: boolean): void {
    console.log('setting layer lock to ' + lock);
    this.activeLayerLocked = lock;
    this.activeLayerLockSubject.next(lock);
  }

  protected onDataSetCleared(ourRootLayer: Layer) {
    console.log('popping layer state');
    ourRootLayer.popVisibleState();
    // restore active layer
    if (this.oldActiveLayers) {
      this.setLayersActive(this.oldActiveLayers);
    } else {
      this.setLayersActive([ourRootLayer]);
    }
    this.curDataSet = null;
    this.setLayerLock(false);
  }

  protected onDataSetSelect(ourRootLayer: Layer, dataSet: DataSet) {

    console.log('onDataSetSelect in map');

    if (this.curDataSet && this.curDataSet.id === dataSet.id) {
      return; // do nothing, already setup
    } else {
      this.onDataSetCleared(ourRootLayer);
    }

    // a dataset was selected
    // look if the dataset layers are below our root layer
    const dsLayers = dataSet.layers;
    let found = false;
    for (let dsLayer of dsLayers) {
      if (ourRootLayer.hasSubLayer(dsLayer)) {
        found = true;
        break;
      }
    }
    if (!found) {
      console.log('did not activate dataset for wrong root layer');
      return;
    }

    console.log('activating dataset, pushing visibility');
    // save current layer config
    ourRootLayer.pushVisibleState();
    // save current active layer
    this.oldActiveLayers = this.activeLayers;
    for (let displayLayer of dataSet.displayLayers) {
      displayLayer.visible = true;
    }
    this.curDataSet = dataSet;

    // set the layer(s) of the dataset to active
    const activeLayers = dataSet.layers;
    this.setLayersActive(activeLayers);
    // and lock the selection while editing a dataset
    this.setLayerLock(true);
  }

  /**
   * Register virgin Leaflet map. Called from e.g. the Angular directive after init.
   * @param map
   */
  public registerMap(map: Map): void {
    if (this.map) {
      console.log('try to double register map!');
      return;
    }
    this.map = map;

    // register for any map change events
    map.on('resize', () => {
      this.mapMoveSubject.next(null);
    })

    map.on('zoomend', () => {
      this.mapMoveSubject.next(this.getMapPos());
    })
    map.on('moveend', () => {
      this.mapMoveSubject.next(this.getMapPos());
    })

    this.createPrintExtentControls();

    this.mapSubject.next(map);
    this.mapSubject.complete();
  }

  protected getMapPos(): MapPos | null {
    if (!this.map) {
      return null;
    }
    const center = this.map.getCenter();
    return {
      zoom: this.map.getZoom(),
      lat: center.lat,
      lng: center.lng
    }
  }

  protected createPrintExtentControls(): void {
    if (!this.map) {
      return;
    }
    // forward scale changes in print extent selector
    this.printExtentSelector = new PrintExtentSelect(
      (newScaleId) => {
        this.printExtentScaleChangeSubject.next(newScaleId);
      },
      (mapCenter) => {
        this.printExtentMapCenterSubject.next(mapCenter);
      }).addTo(this.map);
  }

  public setLayersActive(layers: Layer[]): void {
    // do these layers have anything queryable?
    const realActiveLayers: Layer[] = [];
    for (let layer of layers) {
      console.log('setting layer ' + layer.name + ' to active');
      if (layer.getQueryableChildren().length > 0) {
        realActiveLayers.push(layer);
        layer.visible = true; // we have to see what we select
        layer.notifyVisible(); // maybe we were visible but our parent was not?
      }
    }
    this.activeLayers = realActiveLayers;
    this.activeLayersSubject.next(this.activeLayers);
  }

  public getBounds(): LatLngBounds | undefined {
    return this.map?.getBounds();
  }

  public show(): void {
    if (!this.map) {
      return;
    }
    // show our map, bring to front
    this.map.getContainer().style.zIndex = "0";
  }

  public hide(): void {
    if (!this.map) {
      return;
    }
    // disable our map, set z-index
    this.map.getContainer().style.zIndex = "-100";
  }

  public invalidateMapSize(): void {
    this.map?.invalidateSize();
  }

  public flyTo(lat: number, lon: number) {
    this.map?.flyTo(new L.LatLng(lat, lon), 20, {duration: 1});
  }

  /**
   * Helper method for testing without real map
   * @param fiResult
   */
  public injectFIResult(fiResult: FIResult | null): void {
  }

  public createLeafletObjectForFeature(feature: FI, options: any): LeafletObject {
    return this.createLeafletGeometry(feature.wkt, options);
  }

  /**
   * Creates a Leaflet geometry object for the given Wkt and options.
   * Creates circle marker for point coordinates, and (poly)line objects for anything else.
   * Default color is blue, override with options parameter
   * @param featureWkt the coordinates in Wkt format
   * @param options extra options for the leaflet object constructors
   * @protected
   */
  protected createLeafletGeometry(featureWkt: string, options: any): LeafletObject {
    // convert to leaflet data
    const wktGeom = featureWkt;
    const wkt = new Wkt.Wkt();
    wkt.read(wktGeom);
    wkt.write();

    // the coordinates are in EPSG:3857, unproject to spherical mercator
    let objectCoords = this.projectAndFlattenCoords(wkt.components);

    // create correct leaflet object
    let object;
    switch (wkt.type) {
      case 'point':
        object = new CircleMarker(objectCoords[0], {...{color: 'blue', radius: 20, weight: 5}, ...options});
        break;
      case 'multilinestring':
      case 'linestring':
        object = new Polyline(objectCoords, {...{color: 'blue', weight: 5}, ...options});
        break;
      case 'polygon':
      case 'multipolygon':
        object = new Polygon(objectCoords, {...{color: 'blue', weight: 5}, ...options});
        break;
      default:
        console.log('unknown feature geometry: ' + wkt.type);
        object = new CircleMarker(objectCoords[0], {...{color: 'blue', radius: 20, weight: 5}, ...options});
        break;
    }
    return <LeafletObject>object;
  }

  /**
   * Flatten any arrays-in-arrays from the input and project to 4326
   * @param coords
   */
  protected projectAndFlattenCoords(coords: any): LatLng[] {
    let objectCoords: LatLng[] = [];
    for (let cc1 of coords) {
      if (Array.isArray(cc1)) {
        for (let cc2 of cc1) {
          if (Array.isArray(cc2)) {
            for (let cc3 of cc2) {
              objectCoords.push(Projection.SphericalMercator.unproject(new Point(cc3.x, cc3.y)));
            }
          } else {
            objectCoords.push(Projection.SphericalMercator.unproject(new Point(cc2.x, cc2.y)));
          }
        }
      } else {
        objectCoords.push(Projection.SphericalMercator.unproject(new Point(cc1.x, cc1.y)));
      }
    }

    return objectCoords;
  }

  public getActiveQueryableChildren(): Layer[] {
    const queryableLayers: Layer[] = [];
    if (this.activeLayers) {
      for (let layer of this.activeLayers) {
        for (let queryLayer of layer.getQueryableChildren()) {
          queryableLayers.push(queryLayer);
        }
      }
    }
    return queryableLayers;
  }

  public abstract queryFeature(layerId: number, guid: string): Promise<FI|null>;
}
