
import {SearchResultEntryGen} from "./generated/SearchResultEntryGen";

export class SearchResultEntry extends SearchResultEntryGen {

  get name(): string {
    return this._name;
  }

  get guid(): string {
    return this._guid;
  }

  get wkt(): string {
    return this._wkt;
  }

  get lat(): number {
    return this._lat;
  }

  get lon(): number {
    return this._lon;
  }

  get x(): number {
    return this._x;
  }

  get y(): number {
    return this._y;
  }

}
