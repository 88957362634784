
import {ProjectListEntry} from '../ProjectListEntry';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class ProjectListGen extends Model {
  protected _projectList: ProjectListEntry[];

  constructor(json: {projectList: {name: string, pid: number}[]}, public _api: IGISApi) {
    super();
    this._projectList =  json.projectList.map(item => new ProjectListEntry(item, _api));
    this.onNew();
  }
}
