
import {LayerListResult} from '../LayerListResult';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class ListResultGen extends Model {
  protected _layerResults: LayerListResult[];

  constructor(json: {layerResults: {layerId: number, layerName: string, resultCnt: number, dataSetResults: {dataSetId: number, dataSetName: string, resultCnt: number, results: {guid: string, wkt: string, lat: number, lon: number, name: string, lastEntryPretty: string}[]}[]}[]}, public _api: IGISApi) {
    super();
    this._layerResults =  json.layerResults.map(item => new LayerListResult(item, _api));
    this.onNew();
  }
}
