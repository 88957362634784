
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class FeatureActionResultGen extends Model {
  protected _layerId: number;
  protected _wkt: string;
  protected _featureGUID: string;

  constructor(json: {layerId: number, wkt: string, featureGUID: string}, public _api: IGISApi) {
    super();
    this._layerId = json.layerId;
    this._wkt = json.wkt;
    this._featureGUID = json.featureGUID;
    this.onNew();
  }
}
