import {ListResultGen} from "./generated/ListResultGen";
import {sortByLCName} from "@igis-common/api/Util";
import {LayerListResult} from "@igis-common/model/LayerListResult";
import {DataSetListResult} from "@igis-common/model/DataSetListResult";

export class ListResult extends ListResultGen {

  get layerResults(): LayerListResult[] {
    return this._layerResults;
  }

  public getDataSetResultsOrdered(): DataSetListResult[] {
    const res: DataSetListResult[] = [];

    for (let layerRes of this.layerResults) {
      for (let dsRes of layerRes.dataSetResults) {
        sortByLCName(dsRes.results);
        dsRes.layerResult = layerRes;
        res.push(dsRes);
      }
    }

    sortByLCName(res);

    return res;
  }

}
