import {Layer} from "@igis-common/model/Layer";
import {IGISApi} from "@igis-common/api/IGISApi";
import {EmptyLayer} from "@igis-common/model/EmptyLayer";

export class BasemapLayerGroup extends EmptyLayer {

  constructor(protected _name: string, protected api: IGISApi) {
    super(api);
    this._name = _name;
    this._group = true;
    this._visible = true;
    this._defaultVisible = true;
  }

}
