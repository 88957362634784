
import {DataFieldCategory} from '../DataFieldCategory';
import {DataSetLayer} from '../DataSetLayer';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class DataSetGen extends Model {
  protected _name: string;
  protected _id: number;
  protected _batch: boolean;
  protected _open: number;
  protected _fieldCategories: DataFieldCategory[];
  protected _layers: DataSetLayer[];

  constructor(json: {name: string, id: number, batch: boolean, open: number, fieldCategories: {id: number, name: string, condVisible: string, fields: {id: number, name: string, readonly: boolean, mandatory: boolean, type: number, attributeName: string, orderNr: number, hidden: boolean, optionsAreTemplates: boolean, insertPrevious: boolean, initValue: string, maxCharLength: number, condEnabled: string, condVisible: string, options: {name: string, value: string, visImage?: {uuid: string, filename: string, isPic: boolean, isPanorama: boolean, hasThumb: boolean, comment: string, href: string}}[]}[], orderNr: number, hidden: boolean}[], layers: {layerId: number, displayOnly: boolean}[]}, public _api: IGISApi) {
    super();
    this._name = json.name;
    this._id = json.id;
    this._batch = json.batch;
    this._open = json.open;
    this._fieldCategories =  json.fieldCategories.map(item => new DataFieldCategory(item, _api));
    this._layers =  json.layers.map(item => new DataSetLayer(item, _api));
    this.onNew();
  }
}
