// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
Disables the strange "selected header" effect when switching to another accordion panel
 */
.x-keyboard-mode .x-accordion-hd .x-panel-header-title.x-title-focus {
    outline: none;
}

/* TODO: change me to class based, move css into correct directory with the component/dialog */
#FWImageView .dataSet-entry-wrap {
    padding: 5px;
}

#FWImageView .thumb {
    background: #dddddd;
    padding: 3px;
    padding-bottom: 0;
    text-align: center;
}

.x-quirks #FWImageView .thumb {
    padding-bottom: 3px;
}

#FWImageView .thumb img {
}

#FWImageView .thumb-wrap {
    float: left;
    margin: 4px;
    margin-right: 0;
    padding: 5px;
}

#FWImageView .thumb-wrap span {
    overflow: hidden;
    text-align: center;
    width: 166px; /* for ie to ensure that the text is centered */
    height: 24px;
    font-size: x-small;
}

#FWImageView .x-item-selected {
    background: #eff5fb no-repeat right bottom;
    border: 1px solid #99bbe8;
    padding: 4px;
}

#FWImageView .x-item-selected .thumb {
    background: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/css/extjs-override.css"],"names":[],"mappings":"AAAA;;EAEE;AACF;IACI,aAAa;AACjB;;AAEA,8FAA8F;AAC9F;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;AACA;;AAEA;IACI,WAAW;IACX,WAAW;IACX,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,YAAY,EAAE,+CAA+C;IAC7D,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,0CAA0C;IAC1C,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":["/*\nDisables the strange \"selected header\" effect when switching to another accordion panel\n */\n.x-keyboard-mode .x-accordion-hd .x-panel-header-title.x-title-focus {\n    outline: none;\n}\n\n/* TODO: change me to class based, move css into correct directory with the component/dialog */\n#FWImageView .dataSet-entry-wrap {\n    padding: 5px;\n}\n\n#FWImageView .thumb {\n    background: #dddddd;\n    padding: 3px;\n    padding-bottom: 0;\n    text-align: center;\n}\n\n.x-quirks #FWImageView .thumb {\n    padding-bottom: 3px;\n}\n\n#FWImageView .thumb img {\n}\n\n#FWImageView .thumb-wrap {\n    float: left;\n    margin: 4px;\n    margin-right: 0;\n    padding: 5px;\n}\n\n#FWImageView .thumb-wrap span {\n    overflow: hidden;\n    text-align: center;\n    width: 166px; /* for ie to ensure that the text is centered */\n    height: 24px;\n    font-size: x-small;\n}\n\n#FWImageView .x-item-selected {\n    background: #eff5fb no-repeat right bottom;\n    border: 1px solid #99bbe8;\n    padding: 4px;\n}\n\n#FWImageView .x-item-selected .thumb {\n    background: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
