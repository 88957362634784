import {Component} from "@igis-common/component/Component";
import {Layer} from "@igis-common/model/Layer";
import {MapComponent} from "@igis-common/component/MapComponent";
import {Observable, Subject} from "rxjs";
import {Feature, LevelFeature} from "@igis-common/model/Feature";

import pathNotificationSound from '../audio/notification-gps.mp3';

export interface IBeginGeomEvent {
  map: MapComponent;
  layer: Layer;
  feature: Feature | null;
  levelFeature: LevelFeature | null;

  gps: boolean;
}

export interface ISaveGeom {
  layer: Layer;
  feature: Feature | null;
  levelFeature: LevelFeature | null;
  geoJson: string;
  altitude: number | null; // TODO: extend to multipoint
  accuracy: number | null; // TODO: extend to multipoint
}

export const ACCURACY_LIMIT = 0.1;

/**
 * Component for managing feature geometry adding/moving.
 */
export class FeatureGeomComponent extends Component {

  /**
   * Notification after geom-save is activated.
   * Subscribe to open the data input for add-feature-data-sets.
   */
  protected saveGeomSubject = new Subject<ISaveGeom>();
  public saveGeom$: Observable<ISaveGeom> = this.saveGeomSubject;

  public lastSaveGeom: ISaveGeom | null = null;

  protected currentGeoWatchId: number | null = null;

  protected beginGeomSubject = new Subject<IBeginGeomEvent | null>();
  public beginGeom$: Observable<IBeginGeomEvent | null> = this.beginGeomSubject;

  protected finishGeomSubject = new Subject<void>();
  public finishGeom$: Observable<void> = this.finishGeomSubject;

  protected gpsCoordsSubject = new Subject<GeolocationCoordinates | null>();
  public gpsCoords$: Observable<GeolocationCoordinates | null> = this.gpsCoordsSubject;

  protected curMap: MapComponent | null = null;
  protected curGeomLayer: Layer | null = null;
  protected curGeomFeature: Feature | null = null;
  protected curGeomLevelFeature: LevelFeature | null = null;

  public init() {

    this.app.mapChange$.subscribe(map => {

      // we reset everything on map change
      this.cancelGeom();

      // hold reference to current active map
      this.curMap = map;
    });
  }

  public cancelGeom(): void {
    this.stopGeoWatching();
    this.curGeomLayer = null;
    this.curGeomFeature = null;
    this.beginGeomSubject.next(null);
  }

  public beginAddGeom(layer: Layer, levelFeature: LevelFeature | null): void {

    if (!this.curMap) {
      return;
    }
    this.curGeomLayer = layer;
    this.curGeomFeature = null;
    this.curGeomLevelFeature = levelFeature;
    this.beginGeomSubject.next({
      map: this.curMap,
      layer,
      feature: null,
      levelFeature: levelFeature,
      gps: false
    })
  }

  public beginEditGeom(feature: Feature): void {
    if (!this.curMap) {
      return;
    }
    this.curGeomLayer = feature.layer;
    this.curGeomFeature = feature;
    this.beginGeomSubject.next({
      map: this.curMap,
      layer: feature.layer,
      feature,
      levelFeature: null, // we don't need to know where this feature resides
      gps: false
    })
  }

  public onNewGeoJSON(geoJSON: string, altitude: number | null, accuracy: number | null): void {
    if (this.curGeomLayer) {
      const saveGeomEvent = {
        layer: this.curGeomLayer, geoJson: geoJSON,
        feature: this.curGeomFeature,
        levelFeature: this.curGeomLevelFeature,
        altitude: altitude,
        accuracy: accuracy
      }
      this.lastSaveGeom = saveGeomEvent;
      this.saveGeomSubject.next(saveGeomEvent);
    }
    this.stopGeoWatching();
    this.beginGeomSubject.next(null); // end the geom event
  }

  public startGPSGeom(): void {
    if (!this.curMap) {
      return;
    }
    if (!this.curGeomLayer) {
      // we must have started the geom event already
      return;
    }
    if (!navigator.geolocation) {
      console.log('no gps support');
      return; // we do not have GPS support
    }
    // send a new begin-geom event
    this.beginGeomSubject.next({
      map: this.curMap,
      layer: this.curGeomLayer,
      feature: this.curGeomFeature,
      levelFeature: null, // we don't need to know where this feature resides
      gps: true
    })

    // register for geolocation updates
    this.stopGeoWatching();
    // start geo tracking
    this.currentGeoWatchId = navigator.geolocation.watchPosition( posCallback => {
      // broadcast accuracy to listeners
      const coords = posCallback.coords;
      const accuracy = coords.accuracy;
      console.log(coords);

      // notify listeners of current coordinates/accuracy
      this.gpsCoordsSubject.next(coords);

      if (accuracy < ACCURACY_LIMIT) { // TODO: make me configurable
        // we have a valid measurement
        this.onAccurateGeom(coords);
      }
    })

  }

  protected onAccurateGeom(coords: GeolocationCoordinates): void {
    // convert to geojson
    const geoJSON = {
      "type" : "Point",
      "coordinates" : [
        coords.longitude,
        coords.latitude
      ]
    }
    this.onNewGeoJSON(JSON.stringify(geoJSON), coords.altitude, coords.accuracy);
    new Audio(pathNotificationSound).play();
  }

  protected stopGeoWatching(): void {
    if (this.currentGeoWatchId) {
      navigator.geolocation.clearWatch(this.currentGeoWatchId);
    }
    this.currentGeoWatchId = null;
  }

  public finishGeom(): void {
    this.finishGeomSubject.next();
  }
}
