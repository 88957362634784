import {Component} from "@igis-common/component/Component";
import {IGISAppBase} from "@igis-common/IGISAppBase";
import {Observable, Subject} from "rxjs";
import {MapComponent} from "@igis-common/component/MapComponent";
import {DataSetListResult} from "@igis-common/model/DataSetListResult";


/**
 * Component for handling search requests.
 */
export class ListMalfunctionComponent extends Component {

  protected malfunctionResultSubject = new Subject<DataSetListResult[] | null>();
  public malfunctionResult$: Observable<DataSetListResult[] | null> = this.malfunctionResultSubject;

  protected curMap: MapComponent | null = null;

  constructor(app: IGISAppBase) {
    super(app);
  }

  public init() {
    this.app.mapChange$.subscribe(map => {
      this.curMap = map;
    })
  }

  public async listMalfunction(): Promise<DataSetListResult[] | null> {
    if (!this.curMap) {
      return null;
    }

    // for now: list all layers
    return this.app.api.listMalfunction({}).then(malfunctionResult => {
      if (malfunctionResult && malfunctionResult.data) {
        var data = malfunctionResult.data;
        const res = data.getDataSetResultsOrdered();
        this.malfunctionResultSubject.next(res);
        return res;
      } else {
        this.malfunctionResultSubject.next(null);
        return null;
      }
    })
  }

  public clear(): void {
    this.malfunctionResultSubject.next(null);
  }
}
