
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class WhoamiGen extends Model {
  protected _username: string;

  constructor(json: {username: string}, public _api: IGISApi) {
    super();
    this._username = json.username;
    this.onNew();
  }
}
