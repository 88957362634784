import {Component} from "@igis-common/component/Component";
import {IGISAppBase} from "@igis-common/IGISAppBase";
import {Observable, Subject} from "rxjs";
import {SearchResult} from "@igis-common/model/SearchResult";
import {MapComponent} from "@igis-common/component/MapComponent";
import {LevelFeature} from "@igis-common/model/Feature";


/**
 * Component for handling search requests.
 */
export class SearchComponent extends Component {

  protected searchResultSubject = new Subject<SearchResult | null>();
  public searchResult$: Observable<SearchResult | null> = this.searchResultSubject;

  protected curMap: MapComponent | null = null;
  protected curLevelFeature: LevelFeature | null = null;

  constructor(app: IGISAppBase) {
    super(app);
  }

  public init() {
    this.app.mapChange$.subscribe(map => {
      this.curMap = map;
      this.searchResultSubject.next(null); // clear search result
    })
    this.app.levelFeature.selectLevelFeature$.subscribe(levelFeature => {
      this.curLevelFeature = levelFeature;
    })
  }

  public async search(queryText: string): Promise<SearchResult | null> {
    if (!this.curMap) {
      return null;
    }
    if (queryText.length == 0) {
      this.searchResultSubject.next(null); // clear result
      return null;
    }

    // get list of layers to search
    const layers = this.curMap.getActiveQueryableChildren();
    const layerIds = layers.map((layer) => layer.id);

    // make call to api
    const apiSearchResult = await this.app.api.search({queryString: queryText, layerIds, levelFeatureGUID: this.curLevelFeature?.feature.guid});
    if (!apiSearchResult || !apiSearchResult.data) {
      return null;
    }
    const layerSearchResult = apiSearchResult.data;
    layerSearchResult.map = this.curMap;
    this.searchResultSubject.next(layerSearchResult);
    return layerSearchResult;
  }

  public clear(): void {
    this.searchResultSubject.next(null);
  }
}
