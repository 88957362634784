import * as L from "leaflet";
import {Map} from "leaflet";
import {IBeginGeomEvent} from "@igis-common/component/FeatureGeomComponent";
import {LeafletObject, MapComponent} from "@igis-common/component/MapComponent";
import {Component} from "@igis-common/component/Component";
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import {first} from "rxjs/operators";
import {IGISAppBase} from "@igis-common/IGISAppBase";

export class DesktopGeomHandlerComponent extends Component {

  protected cancelButton: L.Control.EasyButton;
  protected saveButton: L.Control.EasyButton;

  protected map: Map;
  protected geomObject: LeafletObject | null;

  constructor(protected app: IGISAppBase, protected mapComponent: MapComponent) {
    super(app);
  }

  public init() {
    this.mapComponent.map$.pipe(first()).subscribe(map => {
      this.map = map;
      this.createControls();
      this.app.featureGeom.beginGeom$.subscribe((beginGeomEvent) => {
        this.onBeginGeomOperation(beginGeomEvent);
      })
    })
  }

  protected createControls(): void {

    this.saveButton = L.easyButton({
      position: 'topright', states: [{
        stateName: 'default',
        title: 'Speichern',
        icon: '<i style="color:green" class="fas fa-check"></i>',
        onClick: () => {
          this.onSaveClick();
        }
      }]
    })

    this.cancelButton = L.easyButton({
      position: 'topright', states: [{
        stateName: 'default',
        title: 'Abbrechen',
        icon: '<i style="color:darkred" class="fas fa-times"></i>',
        onClick: () => {
          this.onCancelClick();
        }
      }]
    })

    // set language for geoman markers
    const customTranslation = {
      tooltips: {
        placeCircleMarker: 'Klicken um Objekt zu erstellen',
        placeMarker: 'Klicken um Objekt zu erstellen',
      },
    };

    // @ts-ignore
    this.map.pm.setLang('deExt', customTranslation, 'de');
  }

  protected addControls(): void {
    this.saveButton?.addTo(this.map);
    this.cancelButton?.addTo(this.map);
  }

  protected removeControls(): void {
    this.saveButton?.remove();
    this.cancelButton?.remove();
  }

  protected onSaveClick(): void {
    if (this.geomObject) {
      const geoJson = JSON.stringify(this.geomObject.toGeoJSON().geometry);
      this.app.featureGeom.onNewGeoJSON(geoJson, null, null);
    }
  }

  protected onCancelClick(): void {
    this.app.featureGeom.cancelGeom();
  }

  ///////////////////////////////////////////////////////
  //// geom handling for desktop via geoman.io
  ///////////////////////////////////////////////////////
  protected geomanStartAdding(objectType: 'CircleMarker' | 'Line' | 'Polygon', callback: (geoJSON: string) => void): void {
    const options = objectType == 'Polygon' ? {
      snappable: true,
      snapDistance: 20,
    } : {};
    this.map.pm.enableDraw(objectType, options);
    // @ts-ignore
    this.map.once('pm:create', (shape: any, layer: L.Layer) => {
      // extract geojson
      const geoJson = JSON.stringify(shape.layer.toGeoJSON().geometry);
      shape.layer.remove();
      callback(geoJson);
    })
  }

  protected geomanStartEditing(layer: LeafletObject, callback: (geoJSON: string) => void): void {
    (<any>layer).pm.enable({
      allowSelfIntersection: false,
      snappable: false,
      preventMarkerRemoval: true
    });
    layer.on('pm-disable', () => {
      console.log('pm disable');
    });
  }

  protected geomanStop(): void {
    if (this.map) {
      this.map.pm.disableDraw();
      if (this.geomObject) {
        this.geomObject.remove();
        this.geomObject = null;
      }
    }
  }

  protected onBeginGeomOperation(beginGeomEvent: IBeginGeomEvent | null) {

    this.mapComponent?.clearFIResult();

    if (!beginGeomEvent) {
      // we should end all geom operations
      this.geomanStop();
      this.removeControls();
      this.app.resetMode();
      return;
    }

    if (beginGeomEvent.map !== this.mapComponent) {
      return; // this event was not for us
    }

    const layer = beginGeomEvent.layer;
    if (!layer.geomanType) {
      return; // not a valid layer
    }

    const feature = beginGeomEvent.feature;
    if (feature) {
      // edit geometry
      this.addControls();
      // create a marker for this feature
      const marker = this.mapComponent.createLeafletObjectForFeature(feature.fi, {color: 'red'});
      marker.addTo(this.map);
      this.geomObject = marker;
      this.geomanStartEditing(marker, () => {
        console.log('on end editing?');
      })

    } else {
      // add geometry
      this.geomanStartAdding(layer.geomanType, (geoJSON) => {
        console.log('received geojson');
        this.app.featureGeom.onNewGeoJSON(geoJSON, null, null);
      })
    }
    this.app.setGeometryMode();
  }
}
