
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class DataSetLayerGen extends Model {
  protected _layerId: number;
  protected _displayOnly: boolean;

  constructor(json: {layerId: number, displayOnly: boolean}, public _api: IGISApi) {
    super();
    this._layerId = json.layerId;
    this._displayOnly = json.displayOnly;
    this.onNew();
  }
}
