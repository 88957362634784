import {forkJoin, lastValueFrom, Observable, ReplaySubject} from "rxjs";
import {LeafletObject, MapComponent} from "@igis-common/component/MapComponent";
import {BasemapMapLayer} from "@igis-common/leaflet/BasemapMapLayer";
import {WMSLayer} from "@igis-common/leaflet/WMSLayer";
import {BasemapLayerGroup} from "@igis-common/model/BasemapLayerGroup";
import * as L from "leaflet";
import {CRS, DomEvent, FeatureGroup, LayerGroup, LeafletMouseEvent} from "leaflet";
import {APP_MODE} from "@igis-common/IGISAppBase";
import '../leaflet/L.Control.Locate';
import {Layer} from "@igis-common/model/Layer";
import {FI} from "@igis-common/model/FI";
import {SearchResult} from "@igis-common/model/SearchResult";
import {FIResult} from "@igis-common/model/FIResult";
import {Feature} from "@igis-common/model/Feature";

import '@igis-common/leaflet/measure/leaflet-measure.js';

export class WMSMapComponent extends MapComponent {

  private fiRequestResultSubject = new ReplaySubject<FIResult | null>(1);
  /**
   * Holds the last result of a feature info request
   */
  public fiRequestResult$: Observable<FIResult | null> = this.fiRequestResultSubject;

  private locationAccuracySubject = new ReplaySubject<number | null>(1);
  public locationAccuracy$: Observable<number | null> = this.locationAccuracySubject;

  protected searchLayer: LayerGroup | null = null;

  protected fiResult: FI[] = [];

  public init() {

    super.init();

    // we need to react when map init data is available
    forkJoin([this.app.projectInfo$, this.map$]).subscribe(([projectInfo, map]) => {

      const wmsUrl = this.app.api.getWMSUrl("map");

      const center = projectInfo.lastPosition;
      map.setView([center.lat, center.lng], center.zoom);

      const rootLayer = projectInfo.rootLayer;
      if (!rootLayer) {
        // what to do?
        return;
      }

      // create a wms-source for every main layer group
      const baseLayerCnt = rootLayer.children.length;
      let baseMapZIndex = baseLayerCnt + 100;
      for (let layer of rootLayer.children.reverse()) {

        if (layer.name.startsWith('_')) {
          continue; // ignore layers starting with underscore, they are used for printing
        }

        if (layer instanceof BasemapLayerGroup) {
          for (let basemapLayer of layer.children) {
            if (basemapLayer instanceof BasemapMapLayer) {
              const basemapMapLayer = basemapLayer.createLeafletLayer(map, baseMapZIndex--);
              if (basemapLayer.visible) {
                basemapMapLayer.addTo(map);
              }
            }
          }
        } else {
          const wmsSource = new WMSLayer(layer, wmsUrl, {
            format: "image/png",
            transparent: true,
            opacity: 1
          }, this.app.api.token$);
          wmsSource.addTo(map);
        }
      }

      // undo layer list reversal
      rootLayer.children.reverse();

      // add locate control
      L.control.locate({
        showPopup: false,
        keepCurrentZoomLevel: true,
        locateOptions: {
          enableHighAccuracy: !projectInfo.isGPSGeometryEnabled() // use low-res when gps enabled. for some reason this is necessary
        },
        strings: {
          title: 'Aktuelle Position anzeigen',
          metersUnit: 'meter'
        }
      }).addTo(map);

      // add measure control if in desktop mode
      if (!this.app.isMobile()) {
        const measOptions = {
          position: 'bottomleft',
          primaryLengthUnit: 'meters',
          primaryAreaUnit: 'sqmeters',
          secondaryLengthUnit: undefined,
          secondaryAreaUnit: undefined,
          decPoint: ',', thousandsSep: '',
          units: {
            meters: {
              factor: 1,
              display: 'meters',
              decimals: 1
            }
          }
        }
        // TODO: how to import properly?
        // @ts-ignore
        const measureControl = new L.Control.Measure(measOptions);
        measureControl.addTo(map);
      }

      map.invalidateSize();

      // install click handler
      map.on('click', (e: LeafletMouseEvent) => {
        // publish click events
        this.onMapClick(e);
      });

      // set our root layer
      if (rootLayer) {
        this.rootLayerSubject.next(rootLayer); // these are the global WMS layers
      }
    })

    // listen to new selected feature (or updated selected feature)
    this.app.feature.updateSelectedFeature$.subscribe(fi => this.onNewSelectedFeature(fi));

    // listen to search results
    this.app.search.searchResult$.subscribe(searchResult => this.onNewSearchResult(searchResult));
  }

  public async getLegendUrl(layers: Layer[]): Promise<string | null> {

    const map = this.map;
    if (!map) {
      return '';
    }
    const bounds = map.getBounds();
    const mapSize = map.getSize();

    // we make the query in EPSG:3857
    // convert bounds from lat/lng
    const crs = CRS.EPSG3857;
    const bboxNW = crs.project(bounds.getNorthWest());
    const bboxSE = crs.project(bounds.getSouthEast());

    const layerString = layers.map(layer => {
      return layer.wmsName
    }).join(',');

    const bboxString = bboxNW.x + "," + bboxSE.y + "," + bboxSE.x + "," + bboxNW.y;

    return await this.app.api.wmsLg(mapSize.x, mapSize.y, bboxString, layerString);
  }

  protected async onMapClick(mouseEvent: LeafletMouseEvent) {
    if ((this.curMode != APP_MODE.MODE_DEFAULT && this.curMode != APP_MODE.MODE_ADD_DATA_ENTRY) || !this.map) {
      return; // nothing to do
    }
    // make a feature request!
    const projectInfo = await lastValueFrom(this.app.projectInfo$);
    const map = this.map;
    const bounds = map.getBounds();
    const mapSize = map.getSize();

    // select the correct layers to query
    const rootLayer = projectInfo.rootLayer;
    const layers = this.curDataSet ? this.curDataSet.layers :
      (this.activeLayers ? this.getActiveQueryableChildren() : rootLayer?.getQueryableChildren());
    if (!layers || layers.length == 0) {
      // warning message?
      console.log('trying to query a zero-size layer list');
      return;
    }

    const x = mouseEvent.containerPoint.x;
    const y = mouseEvent.containerPoint.y;

    this.app.api.wmsFeatureInfo(layers, mouseEvent.containerPoint, bounds, mapSize, projectInfo).then(fiResult => {
      if (fiResult) {
        // save click coords
        fiResult.x = x;
        fiResult.y = y;
        fiResult.map = this; // save the map reference in the fi result
      }
      this.onNewMapFIResult(fiResult);
    })
  }

  protected onNewMapFIResult(fiResult: FIResult | null) {

    if (!this.map) {
      return; // nothing to do, we do not have a map yet
    }
    this.removeMarkers();

    this.fiRequestResultSubject.next(fiResult);

    if (!fiResult) {
      return; // nothing to do, just remove old markers
    }

    // select the first feature on feature-info-request
    const featureList = fiResult.flatResult;
    if (!featureList.length || !featureList[0]) {
      this.app.feature.clearSelectedFeature();
      return;
    }

    const selFeature = featureList[0];
    this.app.feature.selectFeatureInfo(selFeature); // we autoselect the first feature info in the list, it is the nearest to the click

    for (let feature of featureList) {
      if (feature.wkt) {
        // create a marker for every feature in the fi result, even for the selected feature
        const leafletObject = this.createLeafletObjectForFeature(feature, {opacity: 0.3, guid: feature.guid});
        this.fiResultMarkers[feature.guid] = leafletObject;
        leafletObject.on('click', (event: LeafletMouseEvent) => {
          return this.onMarkerClick(event, feature);
        })

        if (selFeature !== feature) {
          // ...but only add the non-selected features to the map
          leafletObject.addTo(this.map);
        }
      }
    }

    this.fiResult = featureList;
  }

  public clearFIResult(): void {
    this.onNewMapFIResult(null);
  }

  protected getMarkerForSelected(feature: Feature): LeafletObject {
    return this.createLeafletObjectForFeature(feature.fi, {color: 'orange', guid: feature.guid});
  }

  protected getMarkerForSearchFeature(featureWkt: string, guid: string): LeafletObject {
    return this.createLeafletGeometry(featureWkt, {color: 'gray', guid: guid});
  }

  protected onNewSelectedFeature(feature: Feature | null): void {

    // remove old marker(s)
    if (this.selectedFeatureMarker) {
      this.selectedFeatureMarker.remove();
      this.selectedFeatureMarker = null;
    }

    if (feature && this.map && !feature.isOnLevel()) {
      // disable the regular marker of this feature
      for (let markerFeatureGuid in this.fiResultMarkers) {
        if (markerFeatureGuid == feature.guid) {
          this.fiResultMarkers[markerFeatureGuid].remove();
        } else {
          this.fiResultMarkers[markerFeatureGuid].addTo(this.map);
        }
      }

      const marker = this.getMarkerForSelected(feature);
      marker.addTo(this.map);
      this.selectedFeatureMarker = marker;
    }
  }

  public onMarkerClick(event: LeafletMouseEvent, newSelFeature: FI | null) {
    if (newSelFeature) {
      this.app.feature.selectFeatureInfo(newSelFeature);
    }
    DomEvent.stop(event); // prevent marker click from bubbling up to map click
    return true;
  }

  protected removeMarkers() {
    for (let featureGuid in this.fiResultMarkers) {
      this.fiResultMarkers[featureGuid].remove();
    }
    this.fiResultMarkers = {};
  }

  public injectFIResult(fiResult: FIResult | null) {
    if (fiResult) {
      fiResult.map = this;
    }
    this.onNewMapFIResult(fiResult);
  }

  public onNewSearchResult(searchResult: SearchResult | null) {

    this.clearSearch();

    if (!searchResult) {
      return;
    }

    // check if this is for us
    if (searchResult.map !== this) {
      return;
    }

    if (this.searchLayer) {
      this.searchLayer.clearLayers();
    } else {
      if (this.map) {
        this.searchLayer = new LayerGroup().addTo(this.map);
      }
    }

    const markerArray: LeafletObject[] = [];
    for (let layerResult of searchResult.layerResults) {
      for (let result of layerResult.resultEntries) {
        const searchMarker = this.getMarkerForSearchFeature(result.wkt, result.guid);
        // @ts-ignore
        this.searchLayer.addLayer(searchMarker);
        markerArray.push(searchMarker);
      }
    }

    const featureGroup = new FeatureGroup(markerArray);
    if (markerArray.length > 0) {
      const bounds = featureGroup.getBounds();
      this.map?.fitBounds(bounds, {padding: [50, 50]});
    }
  }

  public clearSearch() {
    if (this.searchLayer) {
      this.searchLayer.clearLayers();
    }
  }

  public queryFeature(layerId: number, guid: string): Promise<FI | null> {
    return this.app.api.wmsFeatureInfoByGUID(layerId, guid);
  }

}
