
import {ReportFilter} from '../ReportFilter';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class ReportGen extends Model {
  protected _id: number;
  protected _name: string;
  protected _category: string;
  protected _isFeatureSel: boolean;
  protected _availFilter: ReportFilter;

  constructor(json: {id: number, name: string, category: string, isFeatureSel: boolean, availFilter: {date: boolean, malfunction: boolean, batch: boolean}}, public _api: IGISApi) {
    super();
    this._id = json.id;
    this._name = json.name;
    this._category = json.category;
    this._isFeatureSel = json.isFeatureSel;
    this._availFilter = new ReportFilter(json.availFilter, _api);
    this.onNew();
  }
}
