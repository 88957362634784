
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class ListResultEntryGen extends Model {
  protected _guid: string;
  protected _wkt: string;
  protected _lat: number;
  protected _lon: number;
  protected _name: string;
  protected _lastEntryPretty: string;

  constructor(json: {guid: string, wkt: string, lat: number, lon: number, name: string, lastEntryPretty: string}, public _api: IGISApi) {
    super();
    this._guid = json.guid;
    this._wkt = json.wkt;
    this._lat = json.lat;
    this._lon = json.lon;
    this._name = json.name;
    this._lastEntryPretty = json.lastEntryPretty;
    this.onNew();
  }
}
