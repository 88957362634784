import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../igis-base";
import {COMP_IDS} from "./GUIDefinitions";
import {ExtButton} from "../../ext-types/button/ExtButton";
import {first} from "rxjs/operators";
import {ERROR_CODE} from "@igis-common/api/IGISApiGen";
import {ProjectListEntry} from "@igis-common/model/ProjectListEntry";

export class LogoutButtonComponent extends Component {
  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {
    this.app.gui.gui$.pipe(first()).subscribe((Ext) => {
      const logoutButton = Ext.getCmp<ExtButton>(COMP_IDS.LOGOUT);
      this.app.api.projectList$.pipe(first()).subscribe(projectList => {
        this.setupLogoutButton(logoutButton, projectList);
      })
    })
  }

  public setupLogoutButton(logoutButton: ExtButton, projectList: ProjectListEntry[]) {
    if (projectList.length == 1) {
      // don't change anything
      logoutButton.setHandler(this.onLogout, this);
    } else {
      // we have more than one project:
      // add a menu to the button
      logoutButton.setMenu({
        xtype: 'menu',
          items: [
            {
              text: 'Projekt wechseln',
              handler: (item, event) => {
                this.onChangeProject();
              }
            },
            {
              text: 'Logout',
              handler: (item, event) => {
                this.onLogout();
              }
            }
          ]
      })
    }
  }

  public onChangeProject(): void {
    // reset project
    this.app.forgetProjectSelection();
    // just reload whole app
    window.location.reload();
  }

  public onLogout() {
    // close session on server and reload page
    this.app.logout().then(() => {
      location.reload();
    })
  }
}
