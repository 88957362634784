import {DataSetGen} from "./generated/DataSetGen";
import {ProjectInfo} from "@igis-common/model/ProjectInfo";
import {Layer} from "@igis-common/model/Layer";
import {Observable, Subject} from "rxjs";
import {DataFieldCategory} from "@igis-common/model/DataFieldCategory";
import {sortByOrderNr} from "@igis-common/api/Util";
import {IGISConst} from "@igis-common/const";

interface GroupedMap<T> {
  [groupValue: string]: T[];
}

function igisGroupBy<T>(arr: T[], fn: (item: T) => string) {
  const grouped: GroupedMap<T> = {};
  for(let i of arr) {
    const groupByValue = fn(i);
    let groupedList = grouped[groupByValue];
    if (!groupedList) {
      groupedList = [];

    }
    groupedList.push(i);
    grouped[groupByValue] = groupedList;
  }
  return grouped;
}

export class DataSet extends DataSetGen {

  protected _queryLayers: Layer[] = [];
  protected _displayLayers: Layer[] = [];

  protected openEntryCntSubject = new Subject<number>();
  public openEntryCnt$: Observable<number> = this.openEntryCntSubject;

  get id(): number {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get isBatch(): boolean {
    return this._batch;
  }

  get openDataEntryCount(): number {
    return this._open;
  }

  set openDataEntryCount(openCnt: number) {
    this._open = openCnt;
    this.openEntryCntSubject.next(openCnt);
  }

  get dataFieldCategories(): DataFieldCategory[] {
    sortByOrderNr(this._fieldCategories);
    return this._fieldCategories;
  }

  get layers(): Layer[] {
    return this._queryLayers;
  }

  public hasPermission(): boolean {
    let perm = false;
    for(const layer of this._queryLayers) {
      perm = layer.hasPermission(IGISConst.PERM_DATASET);
      if (!perm) {
        return false;
      }
    }
    return perm;
  }

  get displayLayers(): Layer[] {
    return this._displayLayers;
  }

  public reloadLayers(): void {
    for (let layer of this._displayLayers) {
      layer.reload();
    }
    for (let layer of this._queryLayers) {
      layer.reload();
    }
  }

  public isApplicableToLayer(layer: Layer): boolean {
    for (let ourLayer of this._queryLayers) {
      if (ourLayer.id === layer.id) {
        return true;
      }
    }
    return false;
  }

  public fixDataSetLayers(projectInfo: ProjectInfo, addToLayer: boolean = true): void {
    this._displayLayers = [];
    this._queryLayers = [];
    for (let layer of this._layers) {
      const projLayer = projectInfo.getLayerById(layer.layerId);
      if (!projLayer) {
        console.log('data-set-layer not in project-info: ' + layer.layerId);
        continue;
      }
      if (layer.isDisplayOnly) {
        this._displayLayers.push(projLayer);
      } else {
        if (addToLayer) {
          projLayer.addDataSet(this);
        }
        this._queryLayers.push(projLayer);
      }
    }
  }
}
