import {Layer} from "@igis-common/model/Layer";
import {IGISApi} from "@igis-common/api/IGISApi";
import {EmptyLayer} from "@igis-common/model/EmptyLayer";

export class RootLayer extends EmptyLayer {
  constructor(name: string, api: IGISApi) {
    super(api);

    this.name = name;
    this._visible = true;
    this._queryable = false;
    this._wms = false;
    this._isRoot = true;
    this._defaultVisible = true;
  }
}
