
import {DataSetListResult} from '../DataSetListResult';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class LayerListResultGen extends Model {
  protected _layerId: number;
  protected _layerName: string;
  protected _resultCnt: number;
  protected _dataSetResults: DataSetListResult[];

  constructor(json: {layerId: number, layerName: string, resultCnt: number, dataSetResults: {dataSetId: number, dataSetName: string, resultCnt: number, results: {guid: string, wkt: string, lat: number, lon: number, name: string, lastEntryPretty: string}[]}[]}, public _api: IGISApi) {
    super();
    this._layerId = json.layerId;
    this._layerName = json.layerName;
    this._resultCnt = json.resultCnt;
    this._dataSetResults =  json.dataSetResults.map(item => new DataSetListResult(item, _api));
    this.onNew();
  }
}
