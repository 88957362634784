import {FeatureEntry} from "@igis-common/model/FeatureEntry";
import {IGISApi} from "@igis-common/api/IGISApi";

export class EmptyFeatureEntry extends FeatureEntry {
  constructor(api: IGISApi) {
    super({
      guid: "",
      layerId: -1,
      files: [],
      levels: [],
      reports: [],
      dataEntries: []
    }, api);
  }
}
