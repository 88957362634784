'use strict';

async function fetchImage(url, callback, headers) {
  let _headers = {};
  if (headers) {
    headers.forEach(h => {
      _headers[h.header] = h.value;
    });
  }
  const controller = new AbortController();
  const signal = controller.signal;
  const f = await fetch(url, {
    method: "GET",
    headers: _headers,
    mode: "cors",
    signal: signal
  });
  const blob = await f.blob();
  callback(blob);
}

L.AuthImageOverlay = L.ImageOverlay.extend({
  setHeader(header, value) {
    this.headers = [{header: header, value: value}];
  },
  _initImage() {
    const wasElementSupplied = this._url.tagName === 'IMG';
    const img = this._image = wasElementSupplied ? this._url : L.DomUtil.create('img');

    img.classList.add('leaflet-image-layer');
    if (this._zoomAnimated) { img.classList.add('leaflet-zoom-animated'); }
    if (this.options.className) { img.classList.add(...L.Util.splitWords(this.options.className)); }

    img.onselectstart = L.Util.falseFn;
    img.onmousemove = L.Util.falseFn;

    // @event load: Event
    // Fired when the ImageOverlay layer has loaded its image
    img.onload = this.fire.bind(this, 'load');
    img.onerror = this._overlayOnError.bind(this);

    if (this.options.crossOrigin || this.options.crossOrigin === '') {
      img.crossOrigin = this.options.crossOrigin === true ? '' : this.options.crossOrigin;
    }

    img.decoding = this.options.decoding;

    if (this.options.zIndex) {
      this._updateZIndex();
    }

    self = this;

    fetchImage(
        this._url,
        resp => {
          const reader = new FileReader();
          reader.onload = () => {
            img.src = reader.result;
            if (self.results) {
              self.results.next(reader.result);
            }
          }
          reader.readAsDataURL(resp);
        },
        this.headers,
    );
    img.alt = this.options.alt;
  },
});
L.authImageOverlay = function (url, bounds, options) {
  return new L.AuthImageOverlay(url, bounds, options);
};
