
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class ReportFilterGen extends Model {
  protected _date: boolean;
  protected _malfunction: boolean;
  protected _batch: boolean;

  constructor(json: {date: boolean, malfunction: boolean, batch: boolean}, public _api: IGISApi) {
    super();
    this._date = json.date;
    this._malfunction = json.malfunction;
    this._batch = json.batch;
    this.onNew();
  }
}
