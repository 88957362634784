import {Component} from "@igis-common/component/Component";
import {Observable, ReplaySubject} from "rxjs";
import {Level} from "@igis-common/model/Level";
import {IGISAppBase} from "@igis-common/IGISAppBase";
import {Feature, LevelFeature} from "@igis-common/model/Feature";

export class LevelFeatureComponent extends Component {

  protected curLevels: Level[] | null = null;
  protected curLevelFeature: Feature | null = null;

  protected levelListSubject = new ReplaySubject<Level[] | null>();
  public levelList$: Observable<Level[] | null> = this.levelListSubject;

  private selectLevelFeatureSubject = new ReplaySubject<LevelFeature | null>(1);
  /**
   * Holds the currently selected, level-enabled feature
   */
  public selectLevelFeature$: Observable<LevelFeature | null> = this.selectLevelFeatureSubject;

  constructor(protected app: IGISAppBase) {
    super(app);
  }

  public init() {
  }

  /**
   * Triggers opening the selected level feature with the first level selected if possible.
   * @param feature
   */
  public enterLevelFeature(feature: Feature) {
    if (!feature.fi.hasLevels) {
      console.log('trying to select level feature, but no feature or feature has no level!');
      return;
    }
    // we select the first level
    const firstLevel = this.updateLevelList(feature);
    if (firstLevel) {
      this.curLevelFeature = feature;
      this.selectLevelFeatureSubject.next({feature, level: firstLevel});
      // we clear the selected feature as we are in the building now
      this.app.feature.clearSelectedFeature();
      console.log('selected level feature');
    }
  }

  /**
   * Close the currently opened level feature.
   */
  public clearSelectedLevelFeature(): void {
    console.log('clear level feature');
    const oldFeature = this.curLevelFeature;
    this.curLevelFeature = null;
    this.levelListSubject.next(null); // clear list of available levels
    this.selectLevelFeatureSubject.next(null);
    // now we (re-)select the previous selected feature
    if (oldFeature) {
      this.app.feature.selectFeature(oldFeature);
    }
  }

  public selectLevel(levelId: number): void {
    if (!this.curLevels) {
      return;
    }
    if (!this.curLevelFeature) {
      return;
    }
    for (let level of this.curLevels) {
      if (level.id === levelId) {
        this.selectLevelFeatureSubject.next({feature: this.curLevelFeature, level});
        this.app.feature.clearSelectedFeature();
        return;
      }
    }
  }

  protected updateLevelList(levelFeature: Feature): Level | null {
    const featureEntry = levelFeature.extFI;
    let firstLevel: Level | null = null;
    this.curLevels = featureEntry.levels;
    if (this.curLevels.length > 0) {
      firstLevel = this.curLevels[0];
    } else {
      console.log('no levels for feature?');
      return null;
    }
    // we broadcast the list of available levels
    this.levelListSubject.next(this.curLevels);
    return firstLevel;
  }
}
