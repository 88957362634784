
import {IntervalsGen} from "./generated/IntervalsGen";
import {Interval} from "@igis-common/model/Interval";
import {sortByLCName} from "@igis-common/api/Util";

export class Intervals extends IntervalsGen {

  get intervals(): Interval[] {
    return this._intervals;
  }

  protected onNew() {
    sortByLCName(this._intervals);
  }

}
