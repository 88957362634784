
import {DataStatusEntry} from '../DataStatusEntry';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class DataStatusGen extends Model {
  protected _statuses: DataStatusEntry[];

  constructor(json: {statuses: {name: string, value: string}[]}, public _api: IGISApi) {
    super();
    this._statuses =  json.statuses.map(item => new DataStatusEntry(item, _api));
    this.onNew();
  }
}
