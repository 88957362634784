import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../igis-base";
import {combineLatest, ReplaySubject} from "rxjs";
import {first} from "rxjs/operators";
import {ProjectInfo} from "@igis-common/model/ProjectInfo";
import {COMP_IDS} from "./GUIDefinitions";
import {ExtPanel} from "../../ext-types/panel/ExtPanel";
import {IExt} from "../../ext-types/Ext";
import {ExtButton} from "../../ext-types/button/ExtButton";
import '../../css/changelog.css';


export class ChangelogComponent extends Component {

  protected changelogButton: ExtButton;
  protected clickButton$ = new ReplaySubject<boolean>();

  protected projectInfo;

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {

    // set handler for changelog button
    this.app.gui.gui$.pipe(first()).subscribe((Ext) => {
      this.changelogButton = Ext.getCmp<ExtButton>(COMP_IDS.CHANGELOG);
      this.changelogButton.setHandler(this.onShowChangelog, this);
    })

    combineLatest([
      this.app.gui.gui$,
      this.app.projectInfo$,
      this.clickButton$]
    ).subscribe(([Ext, projectInfo, button]) => {
      // get dimensions of map panel
      const mapPanel = Ext.getCmp<ExtPanel>(COMP_IDS.PANEL_MAP);
      this.showChangelog(Ext, projectInfo, button, mapPanel);
    })

    // start with a value of force=false
    this.clickButton$.next(false);
  }

  public onShowChangelog() {
    this.clickButton$.next(true);
  }

  protected async showChangelog(Ext: IExt, projectInfo: ProjectInfo, force: boolean, mapPanel: ExtPanel) {
    if (force || projectInfo.hasChangelogs) {
      projectInfo.resetChangelogs(); // prevent showing changelog again
      console.log("should display changelog");
      const url = await this.app.api.changelog(force ? 1 : 0);
      let win = new Ext.Window({
        autoDestroy: true,
        title: 'Programmneuerungen',
        width: mapPanel.getWidth() * 0.8,
        height: mapPanel.getHeight() * 0.8,
        scrollable: true,
        maximizable: true,
        items: {
          xtype: 'panel',
          layout: 'fit',
          loader: {
            url: url,
            autoLoad: true
          },
        }
      });
      win.show();
    }
  }

}
