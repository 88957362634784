import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../igis-base";
import {IExt} from "../../ext-types/Ext";
import {filter, first} from "rxjs/operators";
import {COMP_IDS} from "../gui/GUIDefinitions";
import {ExtButton} from "../../ext-types/button/ExtButton";
import {IGISConst} from "@igis-common/const";
import {Feature} from "@igis-common/model/Feature";


export class MoveFeatureComponent extends Component {

  protected Ext: IExt;
  protected buttonMoveFeature: ExtButton;
  protected curFeature: Feature | null;

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {
    this.app.gui.gui$.pipe(first()).subscribe(Ext => {
      this.Ext = Ext;

      this.buttonMoveFeature = Ext.getCmp<ExtButton>(COMP_IDS.GEOMETRY.MOVE);
      this.buttonMoveFeature.setHandler(this.onButtonClick, this);

      this.app.feature.updateSelectedFeature$.subscribe((feature) => {
        let enabled = false;
        this.curFeature = feature;
        if (feature) {
          if (feature.layer.hasPermission(IGISConst.PERM_MOVE_GEOM)) {
            enabled = true;
          }
        }
        if (enabled) {
          this.buttonMoveFeature.setDisabled(false);
        } else {
          this.buttonMoveFeature.setDisabled(true);
        }
      })

      this.app.featureGeom.saveGeom$.pipe(filter((event) => event.feature !== null )).subscribe((event) => {
        const geoJson = event.geoJson;
        const feature = event.feature;
        if (feature && feature === this.curFeature) {
          this.onMoveFeature(feature, geoJson);
        }
      })

    })
  }

  protected onButtonClick(): void {
    this.app.featureGeom.cancelGeom();
    // start editing the geometry
    if (this.curFeature) {
      this.app.featureGeom.beginEditGeom(this.curFeature);
    }
  }

  protected onMoveFeature(feature: Feature, geoJSON: string): void {
    const featureParams = feature.fi.getFeatureIdParams();
    if (featureParams) {
      this.app.api.moveFeature({...featureParams, geoJSON}).then(res => {
        // inject the result (new geometry)
        if (res && res.data) {
          const moveFeatureResult = res.data;
          if (moveFeatureResult.guid == feature.guid) {
            feature.fi.setWKT(res.data.wkt);
            this.app.feature.updateFeature(feature);
          }
        }
      });
    }
  }
}


