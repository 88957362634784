
import {AddDataEntryResultGen} from "./generated/AddDataEntryResultGen";
import {DataSet} from "@igis-common/model/DataSet";

export class AddDataEntryResult extends AddDataEntryResultGen {

  get newEntryId(): number {
    return this._newEntryId;
  }

  get dataSet(): DataSet {
    return this._dataSet;
  }

  get featureGUID(): string {
    return this._featureGUID;
  }
}
