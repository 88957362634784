import {FI} from "@igis-common/model/FI";
import {DataField} from "@igis-common/model/DataField";
import {Option} from "@igis-common/model/Option";
import {DataEntryInputField} from "./DataEntryInputField";
import {IExt} from "../../../ext-types/Ext";
import {ExtBoxComponent} from "../../../ext-types/ExtComponent";
import {ExtFormFieldBase, ExtFormFieldBaseConfig} from "../../../ext-types/form/field/ExtFormFieldBase";
import {ExtPanel, ExtPanelConfig} from "../../../ext-types/panel/ExtPanel";
import {ExtFormFieldContainerConfig} from "../../../ext-types/form/field/ExtFormFieldContainer";


export class DataEntryTextField extends DataEntryInputField {

  protected changeEventName = 'keypress';
  protected cmpTemplates: ExtBoxComponent[];

  constructor(inputField: DataField, feature: FI, Ext: IExt) {

    super(inputField, feature, Ext);

    this.cmpTemplates = [];

    // create regular textbox
    const attrValue = this.getFeatureAttrValue();
    let cmp: ExtFormFieldBaseConfig;
    const maxCharLength = inputField.maxCharLength;
    if (maxCharLength > 50 || maxCharLength === 0) {
      cmp = {
        xtype: 'textarea',
        value: attrValue,
        maxLength: maxCharLength > 0 ? maxCharLength : undefined, // do not restrict maxLength when max=0
        enableKeyEvents: true,
        fieldLabel: this.label
      };
    } else {
      cmp = {
        xtype: 'textfield',
        value: attrValue,
        maxLength: maxCharLength,
        enableKeyEvents: true,
        fieldLabel: this.label
      };
    }

    this.cmpValue = <ExtFormFieldBase>Ext.create(cmp);

    // create templates (if there are any)
    if (inputField.isOptionsAreTemplates) {
      const items: ExtPanelConfig[] = [];
      for (let option of inputField.options) {
        items.push({
          xtype: 'panel',
          html: '<span class="link_style">'+option.name+'</span>',
          listeners: {
            click: {
              element: 'el',
              fn: () => {
                const cmpComment = this.cmpValue;
                let curStr = cmpComment.getValue();
                curStr += option.name + " ";
                cmpComment.setValue(curStr);
              }
            }
          }
        })
      }
      const tableConfig: ExtFormFieldContainerConfig = {
        xtype: 'fieldcontainer',
        fieldLabel: 'Vorschläge',
        layout: 'vbox',
        items: items
      }
      const cmpTemplates = Ext.create<ExtPanel>(tableConfig);
      this.cmpTemplates.push(cmpTemplates);
    }
  }

  addToForm(): ExtFormFieldBase[] | null {

    const controls = super.addToForm();

    // do not add hidden fields
    if (!controls) {
      return null; // nothing to add to the form
    }

    // add cmpTemplates
    for (let cmp of this.cmpTemplates) {
      controls.push(<never>cmp);
    }

    return controls;
  }

  public updateComponent(fieldValues) {
    // determine if component should be active
    const active = this.inputField.isEnabled(fieldValues);
    this.cmpValue.setDisabled(!active);

    // visible?
    const visible = this.inputField.isVisible(fieldValues);
    this.cmpValue.setVisible(visible);

    // hide/show template field
    for (let cmp of this.cmpTemplates) {
      cmp.setVisible(active && visible);
    }
  }
}
