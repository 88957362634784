/**
 * Representation of a complete Feature. Includes the Ext-Feature-Info from IGIS-server, and the level/building information.
 */
import {FI} from "@igis-common/model/FI";
import {FeatureEntry} from "@igis-common/model/FeatureEntry";
import {Level} from "@igis-common/model/Level";
import {Layer} from "@igis-common/model/Layer";
import {IGISAppBase} from "@igis-common/IGISAppBase";
import {FeatureParams} from "@igis-common/base";

export class LevelFeature {
  constructor(public readonly level: Level, public readonly feature: Feature) {
  }
}

export class Feature {
  public readonly layer: Layer;
  public readonly guid: string;
  public readonly fi: FI;
  public extFI: FeatureEntry;

  constructor(fi: FI, layer: Layer, extFI: FeatureEntry) {
    this.fi = fi;
    this.guid = this.fi.guid;
    this.layer = layer;
    this.extFI = extFI;

    this.fi.renderValues();
  }

  public isOnLevel(): boolean {
    return !!this.fi.levelFeature;
  }

  /**
   * Update the stored feature entry of this feature.
   * @param featureEntry
   */
  public injectFeatureEntry(featureEntry: FeatureEntry) {
    this.extFI = featureEntry;
    //this.featureEntrySubject.next(featureEntry);
  }

  /**
   * Helper for fetching feature id from feature info.
   */
  public getFeatureIdParams(): FeatureParams | null {
    return this.fi.getFeatureIdParams();
  }

  get name(): string {
    return this.fi.name;
  }
}
