
import {DataFieldValueGen} from "./generated/DataFieldValueGen";
import {Attribute} from "@igis-common/model/Attribute";

export class DataFieldValue extends DataFieldValueGen {

  get name(): string {
    return this._name;
  }

  get value(): string {
    return this._value;
  }

  get attribute(): Attribute {
    return this._attribute;
  }

  get orderNr(): number {
    return this._orderNr;
  }

}
