import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../../igis-base";
import {COMP_IDS} from "../GUIDefinitions";
import {lastValueFrom} from "rxjs";
import {ExtButton} from "../../../ext-types/button/ExtButton";
import {first} from "rxjs/operators";
import {SettingsWindow} from "./SettingsWindow";

export class SettingsWindowComponent extends Component {

  protected windowButton: ExtButton;

  protected dlg: SettingsWindow;

  constructor(protected app: IGIS) {
    super(app);
  }

  init() {
    this.app.gui.gui$.pipe(first()).subscribe(() => {
      // save reference to the window button
      this.windowButton = <ExtButton>this.app.gui.Ext.getCmp(COMP_IDS.SETTINGS);
      this.windowButton.setHandler(this.onButtonClick, this);

      this.createWindow();
    })
  }

  protected async createWindow() {
    const projInfo = await lastValueFrom(this.app.projectInfo$);
    this.dlg = new SettingsWindow(this.app, projInfo);
  }

  public onButtonClick() {
    this.dlg.show();
  }
}
