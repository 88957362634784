// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.f-short-info {
    padding: 3px;
    text-align: right;
}

/* header: layer name */
div.f-short-info > :first-child {
    padding-top: 5px;
    float:right;
    background-color: #f5f5f5;
    font-weight: bold;
    font-size: large;
}

.f-short-info table {
    float: right;
    border-collapse: collapse;
    /*width: 100%;*/
}

.f-short-info table td {
    padding: 0.3rem;
    text-align: right;
}

/* first column: attribute name */
.f-short-info tbody>tr>:nth-child(1) {
    vertical-align: bottom;
    font-size: smaller;
}

/* second column: attribute value */
.f-short-info tbody>tr>:nth-child(2) {
    font-size: larger;
}

.f-short-info tbody tr:nth-child(even) {
    /*background: #eee;*/
}
`, "",{"version":3,"sources":["webpack://./src/components/gui/featureinfo/feature-short-info.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA,uBAAuB;AACvB;IACI,gBAAgB;IAChB,WAAW;IACX,yBAAyB;IACzB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA,iCAAiC;AACjC;IACI,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA,mCAAmC;AACnC;IACI,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":["div.f-short-info {\n    padding: 3px;\n    text-align: right;\n}\n\n/* header: layer name */\ndiv.f-short-info > :first-child {\n    padding-top: 5px;\n    float:right;\n    background-color: #f5f5f5;\n    font-weight: bold;\n    font-size: large;\n}\n\n.f-short-info table {\n    float: right;\n    border-collapse: collapse;\n    /*width: 100%;*/\n}\n\n.f-short-info table td {\n    padding: 0.3rem;\n    text-align: right;\n}\n\n/* first column: attribute name */\n.f-short-info tbody>tr>:nth-child(1) {\n    vertical-align: bottom;\n    font-size: smaller;\n}\n\n/* second column: attribute value */\n.f-short-info tbody>tr>:nth-child(2) {\n    font-size: larger;\n}\n\n.f-short-info tbody tr:nth-child(even) {\n    /*background: #eee;*/\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
