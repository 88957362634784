
import {DataSet} from '../DataSet';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class AddDataEntryResultGen extends Model {
  protected _newEntryId: number;
  protected _dataSet: DataSet;
  protected _featureGUID: string;

  constructor(json: {newEntryId: number, dataSet: {name: string, id: number, batch: boolean, open: number, fieldCategories: {id: number, name: string, condVisible: string, fields: {id: number, name: string, readonly: boolean, mandatory: boolean, type: number, attributeName: string, orderNr: number, hidden: boolean, optionsAreTemplates: boolean, insertPrevious: boolean, initValue: string, maxCharLength: number, condEnabled: string, condVisible: string, options: {name: string, value: string, visImage?: {uuid: string, filename: string, isPic: boolean, isPanorama: boolean, hasThumb: boolean, comment: string, href: string}}[]}[], orderNr: number, hidden: boolean}[], layers: {layerId: number, displayOnly: boolean}[]}, featureGUID: string}, public _api: IGISApi) {
    super();
    this._newEntryId = json.newEntryId;
    this._dataSet = new DataSet(json.dataSet, _api);
    this._featureGUID = json.featureGUID;
    this.onNew();
  }
}
