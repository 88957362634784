import {iif, Observable, of, ReplaySubject} from "rxjs";
import {IGISApi} from "@igis-common/api/IGISApi";
import {ProjectInfo} from "@igis-common/model/ProjectInfo";
import {FeatureComponent} from "@igis-common/component/FeatureComponent";
import {delay, distinctUntilChanged, map, switchMap} from "rxjs/operators";
import {ProjectListEntry} from "@igis-common/model/ProjectListEntry";
import {ImageMapComponent} from "@igis-common/component/ImageMapComponent";
import {WMSMapComponent} from "@igis-common/component/WMSMapComponent";
import {FeatureGeomComponent} from "@igis-common/component/FeatureGeomComponent";
import {Component} from "@igis-common/component/Component";
import {MapComponent} from "@igis-common/component/MapComponent";
import {ApiResult, ERROR_CODE} from "@igis-common/api/IGISApiGen";
import {DataSet} from "@igis-common/model/DataSet";
import {FileUploadComponent} from "@igis-common/component/FileUploadComponent";
import {PanoramaImageComponent} from "@igis-common/component/PanoramaImageComponent";
import {Layer} from "@igis-common/model/Layer";
import {LevelFeatureComponent} from "@igis-common/component/LevelFeatureComponent";
import {SearchComponent} from "@igis-common/component/SearchComponent";
import {PrintConfig, PrintFormat, PrintOrientation} from "@igis-common/PrintConfig";
import {LatLng} from "leaflet";
import {ListPastDueComponent} from "@igis-common/component/ListPastDueComponent";
import {ListMalfunctionComponent} from "@igis-common/component/ListMalfunctionComponent";
import {FileGenComponent} from "@igis-common/component/FileGenComponent";

export enum APP_MODE {
  MODE_DEFAULT,
  MODE_ADD_DATA_ENTRY,
  MODE_GEOMETRY,
  MODE_PRINT
}

export class IGISAppBase {

  private readonly KEY_LAST_PROJECT_ID: string = 'last-project-id';

  private needLoginSubject = new ReplaySubject<boolean>();
  /**
   * Indicates that login with the saved cookie failed
   * @private
   */
  public needLogin$: Observable<boolean> = this.needLoginSubject;

  private needProjectSelectSubject = new ReplaySubject<ProjectListEntry[]>();
  public needProjectSelect$: Observable<ProjectListEntry[]> = this.needProjectSelectSubject;

  private projectInfoSubject = new ReplaySubject<ProjectInfo>(1);
  /**
   * Publishes the ProjectInfo once it is received, and holds the current ProjectInfo object
   */
  public projectInfo$: Observable<ProjectInfo> = this.projectInfoSubject;


  /**
   * Publishes mode changes
   */
  protected modeChangeSubject = new ReplaySubject<APP_MODE>(1);
  public modeChange$: Observable<APP_MODE> = this.modeChangeSubject;

  protected mapChangeSubject = new ReplaySubject<MapComponent>(1);
  public mapChange$: Observable<MapComponent> = this.mapChangeSubject;

  protected mapRootSubject = new ReplaySubject<{ curMap: MapComponent, rootLayer: Layer }>(1);
  public mapRoot$: Observable<{ curMap: MapComponent, rootLayer: Layer }> = this.mapRootSubject;

  protected layerVisChangeSubject = new ReplaySubject<{ curMap: MapComponent, rootLayer: Layer }>(1);
  public layerVisChange$: Observable<{ curMap: MapComponent, rootLayer: Layer }> = this.layerVisChangeSubject;

  // our components
  public readonly feature: FeatureComponent;
  public readonly featureGeom: FeatureGeomComponent;
  public readonly levelFeature: LevelFeatureComponent;
  public readonly mapMain: WMSMapComponent;
  public readonly mapImage: ImageMapComponent;
  public readonly upload: FileUploadComponent;
  public readonly panoramaImage: PanoramaImageComponent;
  public readonly search: SearchComponent;
  public readonly pastDue: ListPastDueComponent;
  public readonly listMalfunction: ListMalfunctionComponent;
  public readonly print: FileGenComponent;

  protected componentList: Component[] = [];

  constructor(public api: IGISApi) {
    // start in default mode
    this.modeChangeSubject.next(APP_MODE.MODE_DEFAULT);

    /*
    Instantiate all basic components
     */
    this.mapMain = new WMSMapComponent(this);
    this.componentList.push(this.mapMain);

    this.mapImage = new ImageMapComponent(this);
    this.componentList.push(this.mapImage);

    this.feature = new FeatureComponent(this);
    this.componentList.push(this.feature);

    this.upload = new FileUploadComponent(this);
    this.componentList.push(this.upload);

    this.panoramaImage = new PanoramaImageComponent(this);
    this.componentList.push(this.panoramaImage);

    this.featureGeom = new FeatureGeomComponent(this);
    this.componentList.push(this.featureGeom);

    this.levelFeature = new LevelFeatureComponent(this);
    this.componentList.push(this.levelFeature);

    this.search = new SearchComponent(this);
    this.componentList.push(this.search);

    this.pastDue = new ListPastDueComponent(this);
    this.componentList.push(this.pastDue);

    this.listMalfunction = new ListMalfunctionComponent(this);
    this.componentList.push(this.listMalfunction);

    this.print = new FileGenComponent(this);
    this.componentList.push(this.print);

    this.api.setClient(this.getClient());
  }

  /**
   * An observable for e.g. displaying a spinner while print requests are running.
   */
  get printRequestActive$(): Observable<boolean> {
    return this.print.genRequestActive$.pipe(distinctUntilChanged()).pipe(
      switchMap((loading) =>
        iif(() => loading,
          of(loading).pipe(delay(2000)),
          of(loading),
        )
      )
    );
  }

  /**
   * An observable for e.g. displaying a spinner while active requests are running.
   */
  get requestActive$(): Observable<boolean> {
    // create a debounced spinner activation service
    // track requests and enable all spinners after a delay
    // hiding the spinner is without delay
    return this.api.requestActive$.pipe(distinctUntilChanged()).pipe(
      switchMap((loading) =>
        iif(() => loading,
          of(loading).pipe(delay(300)),
          of(loading),
        )
      )
    );
  }

  /**
   * Override in sub class if necessary
   */
  public getClient(): string {
    return "d"; // default is desktop
  }

  public isMobile(): boolean {
    const client = this.getClient();
    return client == 'm' || client == 'g';
  }

  public init() {

    console.log('starting IGISApp');

    this.api.projectInfo$.subscribe(projectInfo => {
      if (!this.projectInfoSubject.isStopped) {
        this.projectInfoSubject.next(projectInfo);
        this.projectInfoSubject.complete();
      }
    });

    // init all components
    console.log('init components');
    for (let cmp of this.componentList) {
      cmp.init();
    }

    this.mapChange$.pipe(
      switchMap((mapComp) => {
        return mapComp.rootLayer$.pipe(map((rootLayer) => {
          return {mapComp, rootLayer};
        }));
      })
    ).subscribe(({mapComp, rootLayer}) => {
      console.log('map root SUBJECT');
      this.mapRootSubject.next({curMap: mapComp, rootLayer});
      mapComp.injectMapMove(); // start with a refresh
    })

    this.mapRoot$.pipe(switchMap(({curMap, rootLayer}) => {
      return rootLayer.stateChangesRecursive$.pipe(map(() => {
        return {curMap, rootLayer};
      }));
    })).subscribe(({curMap, rootLayer}) => {
      this.layerVisChangeSubject.next({curMap, rootLayer});
    });


    // start with the WMS map
    console.log('starting WMS map');
    this.mapChangeSubject.next(this.mapMain);

    // transfer current map position into api for saving last position on server
    this.mapMain.mapMove$.subscribe(mapPos => {
      if (mapPos) {
        this.api.setMapPos(mapPos);
      }
    })

    // ..and listen to levelSel events to change map visibility
    this.levelFeature.selectLevelFeature$.subscribe(levelFeature => {
      if (levelFeature) {
        console.log('show image map with level feature');
        // switch to image map
        this.mapMain.hide();
        this.mapImage.show();
        this.mapChangeSubject.next(this.mapImage);
      } else {
        console.log('disable image map, show wms');
        this.mapImage.hide();
        this.mapMain.show();
        this.mapChangeSubject.next(this.mapMain);
      }
    })

    this.api.projectList$.subscribe(projectList => {
      // check if we have a project id saved in local storage from last login
      const lastProjectIdValue = localStorage.getItem(this.KEY_LAST_PROJECT_ID);
      if (lastProjectIdValue) {
        // look if this id is in the list of projects
        const lastProjectId = Number.parseInt(lastProjectIdValue);
        for (let project of projectList) {
          if (project.id == lastProjectId) {
            // found a project
            console.log('auto-selecting ' + project.name);
            this.selectProject(project.id);
            return;
          }
        }
      }
      // we did not find anything
      if (projectList.length == 1) {
        this.selectProject(projectList[0].id);
      } else {
        // notify listeners that we need to select a project
        this.needProjectSelectSubject.next(projectList);
      }
    })
  }

  public async selectProject(projectId: number): Promise<boolean> {
    this.api.setProjectId(projectId);
    // save our project id in local storage for next reload
    localStorage.setItem(this.KEY_LAST_PROJECT_ID, "" + projectId);
    const rawProjInfo = await this.api.getProjectInfo();
    return !!rawProjInfo;
  }

  /**
   * Tries to log in with a possible access token/refresh token from browser-only storage
   */
  public async checkLoginStatus(): Promise<boolean> {
    const token = await this.api.checkToken(true);
    if (!token) {
      console.log('check login failed, need new refresh token');
      this.needLoginSubject.next(true);
      return false;
    } else {
      console.log('seems like we have a valid refresh token');
      // initiate api calls to start application
      this.api.whoami();
      return true;
    }
  }

  public async login(email: string, pwd: string): Promise<number> {
    const errorCode = await this.api.login({email: email, pwd: pwd});
    if (errorCode == 0) {
      // login was successful, requesting the project list is done by API
      return 0;
    } else {
      switch (errorCode) {
        case ERROR_CODE.INVALID_LOGIN:
          return 1;
        case ERROR_CODE.LOGIN_EXPIRED:
          return 2;
        default:
          return 3;
      }
    }
  }

  public async logout(): Promise<boolean> {
    this.forgetProjectSelection();
    // notify server that we want to close our session, but do not wait for reply
    await this.api.logoutCall();
    return true;
  }

  public forgetProjectSelection(): void {
    // delete last project id from local storage
    localStorage.removeItem(this.KEY_LAST_PROJECT_ID);
  }

  public startAddingDataEntry(dataSet: DataSet, clearFeature: boolean = true): void {
    if (clearFeature) {
      this.feature.clearSelectedFeature();
      this.clearFIQueryResult();
    }
    this.modeChangeSubject.next(APP_MODE.MODE_ADD_DATA_ENTRY);
    this.feature.selectDataSet(dataSet);
  }

  public setGeometryMode() {
    this.modeChangeSubject.next(APP_MODE.MODE_GEOMETRY);
  }

  public resetMode(): void {
    this.modeChangeSubject.next(APP_MODE.MODE_DEFAULT);
  }

  public setPrintMode(): void {
    this.modeChangeSubject.next(APP_MODE.MODE_PRINT);
  }

  /**
   * Removes the last feature info query result from both map types
   */
  public clearFIQueryResult(): void {
    this.mapMain.clearFIResult();
  }

  /**
   * Requests to execute and fill a report
   * @param reportId
   * @param layerId
   * @param filter
   */
  public fillReport(reportId: number, layerId: number, filter?: {
    from?: string,
    to?: string,
    open?: boolean,
    malfunction?: boolean
  }): void {
    const params = {
      reportId,
      layerId,
      filter
    }
    this.api.printReport(params);
  }

  /**
   * Ask the server how many items are in the report
   * @param reportId
   * @param layerId
   * @param filter
   */
  public async countReportParams(reportId: number, layerId: number, filter?: {
    from?: string,
    to?: string,
    open?: boolean,
    malfunction?: boolean
  }): Promise<number | undefined> {
    const params = {
      reportId,
      layerId,
      filter
    }
    const res = await this.api.printReportCount(params);
    if (res) {
      return res.data?.count;
    } else {
      return undefined;
    }
  }

  public printMap(format: PrintFormat, orientation: PrintOrientation, scale: number, layers: Layer[],
                  mapCenter: LatLng, withTable: boolean): void {

    const mapDims = PrintConfig.getMapDims(format, orientation);
    const bb31255 = PrintConfig.calcPaperBoundingBoxAroundCenter(mapCenter, scale, mapDims);

    this.api.printMap({
      extent: [bb31255.p1.y, bb31255.p1.x, bb31255.p2.y, bb31255.p2.x],
      layers: layers.map(layer => {
        return layer.wmsName
      }),
      orientation: orientation == PrintOrientation.q ? 'q' : 'h',
      format: format.toString(),
      scale,
      withTable
    })
  }

}
