import {DataEntryDisplayField} from "./DataEntryDisplayField";
import {createComponent} from "./DataEntryFieldFactory";
import {DateTime} from "luxon";
import {FI} from "@igis-common/model/FI";
import {IExt} from "../../../ext-types/Ext";
import {ComponentMap} from "./DataEntryDialog";
import {ExtPanel} from "../../../ext-types/panel/ExtPanel";
import {DataFieldCategory} from "@igis-common/model/DataFieldCategory";
import {FieldValues} from "@igis-common/model/DataField";
import {ValueMap} from "@igis-common/api/Model";
import {ChangeCallback} from "./DataEntryInputField";
import {ExtFormFieldDisplayConfig} from "../../../ext-types/form/field/ExtFormFieldDisplay";


/**
 * Class for encapsulating an Ext form control
 */
export class DataEntryCategory {

  private panel: ExtPanel;
  private readonly inputFieldComponents: ComponentMap;
  private lastVisible: boolean;

  constructor(private category: DataFieldCategory) {
    this.inputFieldComponents = {};
  }

  public addAsPanel(panel: ExtPanel, fi: FI, Ext: IExt, changeCb: ChangeCallback, addDate: boolean = false) {
    // create tab for us as form component
    const panelConfig = {
      xtype: 'form',
      defaultType: 'displayfield',
      title: this.category.name ? this.category.name : 'Allgemein',
      header: !this.category.isCommon,
      scrollable: true,
      fieldDefaults: {
        labelWidth: 150,
        labelStyle: 'white-space: nowrap;'
      },
      layout: {
        type: 'vbox',
        align: 'stretch'
      },
      bodyPadding: 5,
      border: false,
      items: []
    };

    if (addDate) {
      panelConfig.items.push(<never>this.getDateFieldConfig());
    }

    for (let field of this.category.fields) {
      if (field.isReadonly) {
        // display field
        const dFieldComp = new DataEntryDisplayField(field, fi, Ext);
        const components = dFieldComp.addToForm();
        if (components) {
          for(let cmp of components) {
            panelConfig.items.push(<never>cmp);
          }
        }
      } else {
        // input field
        const fieldComponent = createComponent(field, fi, Ext);
        fieldComponent.addChangeListener(changeCb);
        const components = fieldComponent.addToForm();
        if (components) {
          for(let cmp of components) {
            panelConfig.items.push(<never>cmp);
          }
        }
        this.inputFieldComponents[field.id] = fieldComponent;
      }
    }

    // add to list of tabs/panels
    this.panel = <ExtPanel>panel.add(panelConfig);
  }

  protected getDateFieldConfig() {
    // Date
    // format current date
    const now = DateTime.now();
    const dateFormat = {month: 'numeric', day: 'numeric', year: 'numeric'};
    const curDate = now.setLocale('de').toLocaleString(dateFormat);
    const displayFieldConfig: ExtFormFieldDisplayConfig = {
      xtype: 'displayfield',
      fieldLabel: 'Datum',
      //labelStyle: 'white-space: nowrap;',
      value: curDate
    };
    return displayFieldConfig;
  }

  public check(): boolean {
    for (let fieldId in this.inputFieldComponents) {
      const cmp = this.inputFieldComponents[fieldId];
      if (!cmp.check()) {
        return false;
      }
    }
    return true;
  }

  public saveLastValue(): void {
    for (let fieldId in this.inputFieldComponents) {
      const cmp = this.inputFieldComponents[fieldId];
      cmp.saveLastValue();
    }
  }

  public addFieldValues(fieldValues: FieldValues): void {
    for (let fieldId in this.inputFieldComponents) {
      const cmp = this.inputFieldComponents[fieldId];
      fieldValues.push({
        fieldId: cmp.getFieldId(),
        value: cmp.getValue()
      })
    }
  }

  public update(valueMap: ValueMap): void {
    for (let fieldId in this.inputFieldComponents) {
      const cmp = this.inputFieldComponents[fieldId];
      cmp.updateComponent(valueMap);
    }
  }

  public isVisible(valueMap: ValueMap): boolean {
    // eval our visible condition
    return this.category.hidden ? false : this.category.isVisible(valueMap);
  }

  public visibilityChanged(visible: boolean): boolean {
    if (this.lastVisible !== visible) {
      this.lastVisible = visible;
      return true;
    } else {
      return false;
    }
  }

  public getPanel(): ExtPanel {
    return this.panel;
  }
}
