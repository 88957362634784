import {DataEntryInputField} from "./DataEntryInputField";

export abstract class DataEntryNumberField extends DataEntryInputField {

  protected changeEventName = 'change';

  public check() {
    const value = this.getValue();
    if (value !== 0 && !value && this.inputField.isMandatory) {
      this.cmpValue.markInvalid('Muss ausgefüllt werden');
      return false;
    }
    return true;
  }
}
