import {FeatureEntryGen} from "./generated/FeatureEntryGen";
import {StorageEntry} from "@igis-common/model/StorageEntry";
import {DataEntry} from "@igis-common/model/DataEntry";
import {Level} from "@igis-common/model/Level";
import {DataSet} from "@igis-common/model/DataSet";
import {ProjectInfo} from "@igis-common/model/ProjectInfo";
import {TreeNodeConfig} from "@igis-common/TreeNodeConfig";

interface DataSetTreeNodeConfig extends TreeNodeConfig {

}

export class DataSetEntriesDto {
  constructor(public readonly dataSet: DataSet, public readonly dataEntries: DataEntry[]) {
  }

  public createTreeNode(): DataSetTreeNodeConfig {
    return {
      text: this.dataSet.name,
      expanded: true,
      children: <any>this.dataEntries.map((oldChild) => {
        return oldChild.createTreeNode(this.dataSet)
      }),
      leaf: false,
      checked: undefined,
      iconCls: ''
    }
  }
}

type DataSetMap = Record<number, DataSetEntriesDto>;

export class FeatureEntry extends FeatureEntryGen {

  get files(): StorageEntry[] {
    return this._files;
  }

  get dataEntries(): DataEntry[] {
    return this._dataEntries;
  }

  get levels(): Level[] {
    return this._levels;
  }

  get layerId(): number {
    return this._layerId;
  }

  /**
   * Assemble a list of all pictures that are associated with this feature.
   * Includes pictures from data entries
   */
  public getAllPictures(includeDataEntries: boolean = true): StorageEntry[] {
    const pictures: StorageEntry[] = [];
    for (let file of this.files) {
      if (file.isPicture) {
        pictures.push(file);
      }
    }
    if (includeDataEntries) {
      // add files from data entries
      for (let de of this.dataEntries) {
        for (let file of de.files) {
          if (file.isPicture) {
            pictures.push(file);
          }
        }
      }
    }

    return pictures;
  }

  /**
   * Assemble a list of all files that are associated with this feature, excluding pictures.
   * Includes files from data entries
   */
  public getAllNonPictures(): StorageEntry[] {
    const files: StorageEntry[] = [];
    for (let file of this.files) {
      if (!file.isPicture) {
        files.push(file);
      }
    }
    // add files from data entries
    for (let de of this.dataEntries) {
      for (let file of de.files) {
        if (!file.isPicture) {
          files.push(file);
        }
      }
    }

    return files;
  }

  public getDataEntriesBySet(projectInfo: ProjectInfo): DataSetEntriesDto[] {

    let bySet: DataSetMap = {};
    for (let dataEntry of this.dataEntries) {
      const dataSetId = dataEntry.dataSetId;
      let dto = bySet[dataSetId];
      if (!dto) {
        const dataSet = projectInfo.getDataSetById(dataSetId);
        if (dataSet) {
          dto = new DataSetEntriesDto(dataSet, []);
          bySet[dataSetId] = dto;
        }
      }
      if (dto) {
        dto.dataEntries.push(dataEntry);
      }
    }

    let ret: DataSetEntriesDto[] = [];
    // loop over original list of datasets to preserve project ordering of datasets
    const dataSetLayers = projectInfo.getDataSetLayers();
    for (let dataSetLayer of dataSetLayers) {
      for (let dataSet of dataSetLayer.dataSets) {
        const dataSetId = dataSet.id;
        if (bySet[dataSetId]) {
          ret.push(bySet[dataSetId]);
        }
      }
    }
    return ret;
  }

  public getLastDataEntryId(projectInfo: ProjectInfo): number {
    const sets = this.getDataEntriesBySet(projectInfo);
    let lastId = -1;
    for (let v of sets) {
      for (let e of v.dataEntries) {
        if (e.id > lastId) {
          lastId = e.id;
        }
      }
    }

    return lastId;
  }

  public containsDataEntry(dataEntryId: number, projectInfo: ProjectInfo): boolean {
    const sets = this.getDataEntriesBySet(projectInfo);
    let lastId = -1;
    for (let v of sets) {
      for (let e of v.dataEntries) {
        if (e.id == dataEntryId) {
          return true;
        }
      }
    }
    return false;
  }

  public createTreeNode(projectInfo: ProjectInfo): TreeNodeConfig {
    // get data entries and group by set
    const sets = this.getDataEntriesBySet(projectInfo);

    return {
      text: 'root, not visible',
      expanded: true,
      children: <any>sets.map((oldChild) => {
        return oldChild.createTreeNode()
      }),
      leaf: false,
      checked: undefined,
      iconCls: ''
    }
  }
}
