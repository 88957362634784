import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../igis-base";
import {COMP_IDS} from "./GUIDefinitions";
import {ExtMenuItemConfig} from "../../ext-types/menu/ExtMenuItem";
import {ExtMenuConfig} from "../../ext-types/menu/ExtMenu";
import {ExtButton} from "../../ext-types/button/ExtButton";
import {ExtToolbarTextItem} from "../../ext-types/toolbar/ExtToolbarTextItem";
import {Report} from "@igis-common/model/Report";
import {ReportFilterWindow} from "./report/ReportFilterWindow";
import {first} from "rxjs/operators";
import {Layer} from "@igis-common/model/Layer";

export class ReportMenuComponent extends Component {

  protected reportButton: ExtButton;
  protected statusMsg : ExtToolbarTextItem;
  protected filterDlg: ReportFilterWindow;

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {
    // wait for gui & project-info
    this.app.gui.gui$.pipe(first()).subscribe(Ext => {
      // get handle to report button
      this.reportButton = Ext.getCmp<ExtButton>(COMP_IDS.PRINT_REPORT);
      this.statusMsg = Ext.getCmp<ExtToolbarTextItem>(COMP_IDS.PRINT_STATUS_MESSAGE);

      this.filterDlg = new ReportFilterWindow(this.app);

      this.app.printRequestActive$.subscribe(active => {
        if (active) {
          console.log('setting active')
          this.statusMsg.setVisible(true);
        } else {
          console.log('setting disabled');
          this.statusMsg.setVisible(false);
        }
      })

      // update report list when rootLayer and/or map changes
      this.app.mapRoot$.subscribe(({curMap, rootLayer}) => {
        // rebuild report list when layer visibility changes
        this.updateReportList(rootLayer);
      })

      // update report list when layer visibility changes
      this.app.layerVisChange$.subscribe(({curMap, rootLayer}) => {
        // rebuild report list when layer visibility changes
        this.updateReportList(rootLayer);
      })
    })
  }

  public onReportMenuClick(report: Report, layer: number): void {
    console.log('report item clicked');
    if (report.hasAnyFilter) {
      this.filterDlg.show(report);
    } else {
      this.app.fillReport(report.id, layer);
    }
  }

  protected updateReportList(rootLayer: Layer): void {
    let layerReports = rootLayer.getChildLayerReports();

    // delete old menu
    this.reportButton.setMenu(false);

    let menuConfig: ExtMenuConfig;

    if (layerReports.length > 0) {
      // create an item for every report
      // each report has a category
      let lastCategoryMenuItems: any = [];
      let commonMenuItems: any = [];
      let lastCategory = '';
      let menuItems: any = [];

      for (let report of layerReports) {
        const layer = report.layer;
        if (!layer) {
          continue;
        }
        const reportName = report.name;
        const category = report.category;

        // create a new menu entry
        let menuItem: ExtMenuItemConfig = {
          xtype: 'menuitem',
          text: reportName,
          handler: (item, event) => { this.onReportMenuClick(report, layer.id);}
        }

        if (category === '') {
          // add to list of common menu items
          commonMenuItems.push(menuItem);
        } else {
          if (category !== lastCategory) {
            if (lastCategory !== '') {
              // create a sub menu for the category
              // and fill it with the already accumulated menu items
              let categoryMenuItem: ExtMenuItemConfig = {
                text: lastCategory,
                menu: {
                  xtype: 'menu',
                  items: lastCategoryMenuItems
                }
              };
              menuItems.push(categoryMenuItem);

              lastCategoryMenuItems = [];
            }
          }
          lastCategoryMenuItems.push(menuItem);
          lastCategory = category;
        }
      }

      // the last category has to be added
      let categoryMenuItem: ExtMenuItemConfig = {
        text: lastCategory,
        menu: {
          xtype: 'menu',
          items: lastCategoryMenuItems
        }
      };
      menuItems.push(categoryMenuItem);

      // add common reports
      menuItems = menuItems.concat(commonMenuItems);

      menuConfig = {
        items: menuItems
      }

    } else {
      const emptyMenuItem: ExtMenuItemConfig = {
        xtype: 'menuitem',
        text: 'Keine Berichte verfügbar',
        disabled: true
      }

      menuConfig = {
        items: [emptyMenuItem]
      }
    }

    this.reportButton.setMenu(menuConfig);
  }

}
