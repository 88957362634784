import {FI} from "@igis-common/model/FI";
import {DataField, IOption} from "@igis-common/model/DataField";
import {DataEntryInputField} from "./DataEntryInputField";
import {IExt} from "../../../ext-types/Ext";
import {ExtFormFieldBase} from "../../../ext-types/form/field/ExtFormFieldBase";
import {ExtArrayStoreConfig} from "../../../ext-types/data/ExtArrayStore";
import {ExtFormFieldComboConfig} from "../../../ext-types/form/field/ExtFormFieldCombo";

export class DataEntryComboField extends DataEntryInputField {

  protected changeEventName = 'select';

  constructor(inputField: DataField, feature: FI, Ext: IExt) {
    super(inputField, feature, Ext);

    // create store with options, convert to array first
    const storeData: [string, string][] = [];
    const options = this.getOptions(inputField);
    for (let option of options) {
      storeData.push([option.name, option.value]);
    }
    let store : ExtArrayStoreConfig = {
      xtype: 'arraystore',
      autoDestroy: true,
      data: storeData,
      fields: ['name', 'value']
    }

    const cmp: ExtFormFieldComboConfig = {
      xtype: 'combo',
      editable: false,
      store: store,
      valueField: 'value',
      displayField: 'name',
      value: undefined
    }

    // select the right item if available
    const attrValue = this.getFeatureAttrValue(true);
    if (attrValue) {
      for (let inputValue of options) {
        if (inputValue.value == attrValue) {
          cmp.value = attrValue;
          break;
        }
      }
    }

    this.cmpValue = Ext.create<ExtFormFieldBase>(cmp);
  }

  protected getOptions(inputField: DataField): IOption[] {
    return inputField.options;
  }
}
