import {ProjectInfo} from "@igis-common/model/ProjectInfo";
import {FI} from "@igis-common/model/FI";
import {FIAttr} from "@igis-common/model/FIAttr";
import {FIResultLayer} from "@igis-common/model/FIResultLayer";
import {FIResult} from "@igis-common/model/FIResult";

export class FIResultFromXML extends FIResult {

  constructor(xml: string, projectInfo: ProjectInfo) {
    super();
    // parse xml
    let xmlDoc;
    if ((<any>window).DOMParser) {
      const parser = new DOMParser();
      xmlDoc = parser.parseFromString(xml, "text/xml");
    }
    this.fiResultLayers = [];
    this.parseFIResult(xmlDoc, projectInfo);
  }


  protected parseFIResult(node, projectInfo: ProjectInfo) {
    if (node.hasChildNodes()) {

      if (node.hasChildNodes() && node.nodeName === "Layer") {
        let hasAttributes = false;
        let htmlText = '';

        const wmsId = node.getAttribute("name");

        // fetch the layer from the project-info
        const layer = projectInfo.getLayerByWMSId(wmsId);
        if (!layer) {
          console.log('did not find layer ' + wmsId + ' in projectInfo');
          return;
        }

        const fiLayer = new FIResultLayer(layer);

        let layerChildNode = node.firstChild;
        while (layerChildNode) {
          if (layerChildNode.hasChildNodes() && layerChildNode.nodeName === "Feature") {
            let attributeNode = layerChildNode.firstChild;

            const layerFeature = new FI( {
              guid: 'null', wkt: '', attrs: [], layerId: -1
            }, projectInfo._api);

            while (attributeNode) {

              if (attributeNode.nodeName === "Attribute") {

                const attName = attributeNode.getAttribute("name");
                let attValue = attributeNode.getAttribute("value");

                const mapInfoFieldName = 'tooltip';

                if ((attName !== mapInfoFieldName) && ((attValue.replace(/^\s\s*/, '').replace(/\s\s*$/, '') !== ""))) {
                  if (attName === "geometry") {
                    layerFeature.setWKT(attValue);
                  } else {
                    // not geometry

                    let blnShow = true;
                    if (attName === "maptip" || attName === "marker" || attName === "deleted") {
                      blnShow = false; // do not display maptip features
                    }

                    // convert NULL-strings
                    if (attValue === 'NULL' || attValue === 'NULL::character varying') {
                      attValue = null;
                    }

                    layerFeature.addAttribute(new FIAttr({name: attName, value: attValue}, projectInfo._api));
                    hasAttributes = true;
                  }
                }
              }

              attributeNode = attributeNode.nextSibling;

            }

            fiLayer.addFeature(layerFeature);

            htmlText += "\n  </tbody>\n </table>";
          } else if (layerChildNode.nodeName === "Attribute") {
            hasAttributes = true;
          }

          layerChildNode = layerChildNode.nextSibling;
        }

        // add data of this layer to array
        this.fiResultLayers.push(fiLayer);

      } else {
        let child = node.firstChild;
        while (child) {
          this.parseFIResult(child, projectInfo);
          child = child.nextSibling;
        }
      }
    }
  }
}
