
import {FileResultGen} from "./generated/FileResultGen";

export class FileResult extends FileResultGen {

  get uuid(): string {
    return this._uuid;
  }

  get status(): string {
    return this._status;
  }

}
