
import {FIGroupInfoGen} from "./generated/FIGroupInfoGen";

export class FIGroupInfo extends FIGroupInfoGen {

  get name(): string {
    return this._name;
  }

  get members(): string[] {
    return this._items;
  }

}
