import {DataEntryComboField} from "./DataEntryComboField";
import {DataField, IOption} from "@igis-common/model/DataField";
import {FI} from "@igis-common/model/FI";
import {IExt} from "../../../ext-types/Ext";

/**
 * DataField component for entering boolean values.
 */
export class DataEntryBooleanField extends DataEntryComboField {

  constructor(inputField: DataField, feature: FI, Ext: IExt) {
    super(inputField, feature, Ext);
  }

  protected getOptions(inputField: DataField): IOption[] {
    return DataField.getBooleanOptions();
  }

  protected getFeatureAttrValue(raw: boolean = false): any {
    const val = super.getFeatureAttrValue(raw);
    if (val == 'true' || val =='t' || val == '1') {
      return '1';
    } else {
      return '0';
    }
  }
}
