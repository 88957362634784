
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class FeatureCollectionGen extends Model {
  protected _geoJSON: string;

  constructor(json: {geoJSON: string}, public _api: IGISApi) {
    super();
    this._geoJSON = json.geoJSON;
    this.onNew();
  }
}
