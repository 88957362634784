
import {Attribute} from '../Attribute';
import {FIGroupInfo} from '../FIGroupInfo';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class LayerInfoGen extends Model {
  protected _attributes: Attribute[];
  protected _groups: FIGroupInfo[];
  protected _shortInfoEntries: string[];

  constructor(json: {attributes: {name: string, displayName: string, info: string, unit: string, digits: number, type: number, searchable: boolean, options: {name: string, value: string, visImage?: {uuid: string, filename: string, isPic: boolean, isPanorama: boolean, hasThumb: boolean, comment: string, href: string}}[]}[], groups: {name: string, items: string[]}[], shortInfoEntries: string[]}, public _api: IGISApi) {
    super();
    this._attributes =  json.attributes.map(item => new Attribute(item, _api));
    this._groups =  json.groups.map(item => new FIGroupInfo(item, _api));
    this._shortInfoEntries = json.shortInfoEntries;
    this.onNew();
  }
}
