enum PARSER_STATE {
  START, LAYERS, PERMISSION
}

class LayerPolicy {
  layers: number[] | null = null;
  positive: boolean = false;
  perms: number[] | null = null;

  public isForLayer(layerId: number): boolean {
    if (!this.layers) {
      return true;
    }
    for (let layer of this.layers) {
      if (layer === layerId) {
        return true;
      }
    }
    return false;
  }

  public isDirectlyForLayer(layerId: number): boolean {
    if (!this.layers) {
      return false;
    }
    for (let layer of this.layers) {
      if (layer === layerId) {
        return true;
      }
    }
    return false;
  }

  public isForPermission(perm: number): boolean {
    if (!this.perms) {
      return true;
    }
    for (let ourPerm of this.perms) {
      if (ourPerm === perm) {
        return true;
      }
    }
    return false;
  }

}

function isDigit(char) {
  return !isNaN(parseInt(char));
}

export class ProjectPolicy {

  readonly GLOBAL_LAYER_ID = 41232;

  private constructor (private layerPolicies: LayerPolicy[]) {
  }

  public hasPermission(layerId: number, permission: number): boolean {
    let allowed: boolean | undefined = undefined;

    for(let policy of this.layerPolicies) {
      if(!policy.isForLayer(layerId) || !policy.isForPermission(permission)) {
        continue;
      }
      allowed = policy.positive;
    }

    if (allowed === undefined) {
      return false;
    } else {
      return allowed;
    }
  }

  public hasGlobalPermission(permission: number): boolean {
    let allowed: boolean | undefined = undefined;

    for(let policy of this.layerPolicies) {
      if(!policy.isDirectlyForLayer(this.GLOBAL_LAYER_ID) || !policy.isForPermission(permission)) {
        continue;
      }
      allowed = policy.positive;
    }

    if (allowed === undefined) {
      return false;
    } else {
      return allowed;
    }
  }

  public static fromPolicyString(policyString: string): ProjectPolicy | null {
    let state: PARSER_STATE = PARSER_STATE.START;

    let positive = false;
    let allLayers = false;
    let allPermissions = false;
    let curIdString = '';
    let layerIds: number[] = [];
    let permissionIds: number[] = [];

    const policies: LayerPolicy[] = [];

    for (const c of policyString) {
      switch (state) {
        case PARSER_STATE.START:
          // init variables
          allLayers = false;
          allPermissions = false;
          layerIds = [];
          permissionIds = [];
          curIdString = '';

          switch (c) {
            case '+':
              positive = true;
              break;
            case '-':
              positive = false;
              break;
            default:
              console.warn("error parsing policy string: +/- expected, got: " + c);
              return null;
          }
          state = PARSER_STATE.LAYERS;
          break;
        case PARSER_STATE.LAYERS:
          switch (c) {
            case 'a':
              allLayers = true;
              break;
            case '{':
              // finish last layerId
              if (curIdString != '') {
                layerIds.push(Number.parseInt(curIdString));
              }
              curIdString = '';
              state = PARSER_STATE.PERMISSION;
              break;
            case ',':
              // finish next layerId
              if (curIdString != '') {
                layerIds.push(Number.parseInt(curIdString));
              }
              curIdString = '';
              break;
            default:
              if (!isDigit(c)) {
                console.warn("error parsing policy string: expected layer id digit, got: " + c);
                return null;
              }
              curIdString += c;
              break;
          }
          break;
        case PARSER_STATE.PERMISSION:
          switch (c) {
            case 'a':
              allPermissions = true;
              break;
            case ',':
              // finish next perm id
              if (curIdString != '') {
                permissionIds.push(Number.parseInt(curIdString));
              }
              curIdString = '';
              break;
            case '}':
              // finish last id
              if (curIdString != '') {
                permissionIds.push(Number.parseInt(curIdString));
              }
              curIdString = '';
              // we successfully parsed the policy
              const policy = new LayerPolicy();
              if (!allLayers) {
                policy.layers = layerIds;
              }
              if (!allPermissions) {
                policy.perms = permissionIds;
              }
              policy.positive = positive;
              policies.push(policy);
              state = PARSER_STATE.START; // restart parsing
              break;
            default:
              if (!isDigit(c)) {
                console.warn("error parsing policy string: expected perm id digit, got: " + c);
                return null;
              }
              curIdString += c;
              break;
          }
          break;
      }
    }

    return new ProjectPolicy(policies);
  }
}
