import {Layer} from "@igis-common/model/Layer";
import {IGISApi} from "@igis-common/api/IGISApi";

export abstract class EmptyLayer extends Layer {
  protected constructor(api: IGISApi, id: number = -1) {
    super({
      id: id,
      group: false,
      parentId: -1,
      defaultVisible: false,
      queryable: false,
      imageLayer: false,
      name: "",
      nameSingular: "",
      orderNr: -1,
      geomType: "",
      addFeatureDataSet: undefined,
      availPerms: [],
      nameAttributes: [],
      shortInfoAttributes: [],
      mapReport: false,
      visColumn: undefined,
      reports: [],
      maxVertices: 0
    }, api);
  }

}
