
import {StorageEntry} from '../StorageEntry';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class LevelGen extends Model {
  protected _id: number;
  protected _name: string;
  protected _image: StorageEntry;

  constructor(json: {id: number, name: string, image: {uuid: string, filename: string, isPic: boolean, isPanorama: boolean, hasThumb: boolean, comment: string, href: string}}, public _api: IGISApi) {
    super();
    this._id = json.id;
    this._name = json.name;
    this._image = new StorageEntry(json.image, _api);
    this.onNew();
  }
}
