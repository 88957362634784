import {DataEntryGen} from "./generated/DataEntryGen";
import {StorageEntry} from "@igis-common/model/StorageEntry";
import {DataFieldValue} from "@igis-common/model/DataFieldValue";
import {LayerTreeNodeConfig} from "@igis-common/model/Layer";
import {TreeNodeConfig} from "@igis-common/TreeNodeConfig";
import {DataSet} from "@igis-common/model/DataSet";
import {sortByOrderNr} from "@igis-common/api/Util";

export interface DataEntryTreeNodeConfig extends TreeNodeConfig {
  dataEntry: DataEntry;
  dataSet: DataSet;
}

export class DataEntry extends DataEntryGen {

  get name(): string {
    return this._name;
  }

  get files(): StorageEntry[] {
    return this._files;
  }

  get dataSetId(): number {
    return this._dataSetId;
  }

  get id(): number {
    return this._id;
  }

  get fieldValues(): DataFieldValue[] {
    // sort by order-nr
    sortByOrderNr(this._fieldValues);
    return this._fieldValues;
  }

  public createTreeNode(dataSet: DataSet): DataEntryTreeNodeConfig {
    return {
      text: this.name,
      expanded: true,
      children: [],
      leaf: true,
      checked: undefined,
      iconCls: '',
      dataSet: dataSet,
      dataEntry: this
    }
  }
}
