
import {DataStatusGen} from "./generated/DataStatusGen";
import {DataStatusEntry} from "@igis-common/model/DataStatusEntry";

export class DataStatus extends DataStatusGen {

  get statuses(): DataStatusEntry[] {
    return this._statuses;
  }

}
