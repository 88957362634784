
import {DataStatusEntryGen} from "./generated/DataStatusEntryGen";

export class DataStatusEntry extends DataStatusEntryGen {

  get name(): string {
    return this._name;
  }

  get value(): string {
    return this._value;
  }

}
