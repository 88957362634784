
import {Option} from '../Option';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class AttributeGen extends Model {
  protected _name: string;
  protected _displayName: string;
  protected _info: string;
  protected _unit: string;
  protected _digits: number;
  protected _type: number;
  protected _searchable: boolean;
  protected _options: Option[];

  constructor(json: {name: string, displayName: string, info: string, unit: string, digits: number, type: number, searchable: boolean, options: {name: string, value: string, visImage?: {uuid: string, filename: string, isPic: boolean, isPanorama: boolean, hasThumb: boolean, comment: string, href: string}}[]}, public _api: IGISApi) {
    super();
    this._name = json.name;
    this._displayName = json.displayName;
    this._info = json.info;
    this._unit = json.unit;
    this._digits = json.digits;
    this._type = json.type;
    this._searchable = json.searchable;
    this._options =  json.options.map(item => new Option(item, _api));
    this.onNew();
  }
}
