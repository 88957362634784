import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../igis-base";
import {first} from "rxjs/operators";
import {Feature} from "@igis-common/model/Feature";

export class UploadButtonComponent extends Component {

  private curFeature: Feature | null;

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {
    this.app.gui.gui$.pipe(first()).subscribe((Ext) => {

      // listen to file-change events on the upload control
      const elem = document.getElementById('input_upload');
      if (elem) {
        console.log('registering upload listener');
        elem.onchange = () => {
          // @ts-ignore
          const selectedFiles = [...elem.files];
          this.onFileChange(selectedFiles);
        }
      } else {
        console.log('could not find upload component!!');
      }

      this.app.feature.selectFeature$.subscribe(feature => {
        this.curFeature = feature;
      })
    })
  }

  protected onFileChange(files) {
    if (!this.curFeature) {
      return;
    }
    for (let file of files) {
      this.app.upload.uploadFile(file, this.curFeature);
    }
  }
}
