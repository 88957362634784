import {Component} from "@igis-common/component/Component";
import {FI} from "@igis-common/model/FI";
import {FIAttr} from "@igis-common/model/FIAttr";
import {IGIS} from "../../igis-base";
import {Layer} from "@igis-common/model/Layer";
import {DataEntryDlg} from "../gui/dataentry/DataEntryDialog";
import {IExt} from "../../ext-types/Ext";
import {filter, first} from "rxjs/operators";
import {ExtPanel} from "../../ext-types/panel/ExtPanel";
import {COMP_IDS} from "../gui/GUIDefinitions";
import {ExtButton} from "../../ext-types/button/ExtButton";
import {IGISConst} from "@igis-common/const";
import {ExtMenuConfig} from "../../ext-types/menu/ExtMenu";
import {ExtMenuItemConfig} from "../../ext-types/menu/ExtMenuItem";
import {calculateLength} from "@igis-common/leaflet/CalcLength";
import {FieldValues} from "@igis-common/model/DataField";
import {DataSet} from "@igis-common/model/DataSet";
import {Feature, LevelFeature} from "@igis-common/model/Feature";
import {EmptyFeatureEntry} from "@igis-common/model/EmptyFeatureEntry";


export class AddFeatureComponent extends Component {

  protected Ext: IExt;
  protected buttonAddGeom: ExtButton;
  protected curLevelFeature: LevelFeature | null;

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {
    this.app.gui.gui$.pipe(first()).subscribe(Ext => {
      this.Ext = Ext;

      // get references
      this.buttonAddGeom = Ext.getCmp<ExtButton>(COMP_IDS.GEOMETRY.ADD);

      this.app.mapRoot$.subscribe(({curMap, rootLayer}) => {
        console.log('filling add geometry menu');
        this.updateGeomMenu(rootLayer);
      })

      this.app.featureGeom.saveGeom$.pipe(filter((event) => event.feature == null)).subscribe((event) => {
        const geoJson = event.geoJson;
        const layer = event.layer;
        this.onAddFeature(layer, geoJson, event.levelFeature);
      })

      this.app.levelFeature.selectLevelFeature$.subscribe(levelFeature => {
        this.curLevelFeature = levelFeature;
      })

    })
  }

  protected onAddFeature(layer: Layer, geoJson: string, levelFeature: LevelFeature | null): void {
    const addFeatureDataSet = layer.addFeatureDataSet;
    if (addFeatureDataSet) {

      const length = calculateLength(geoJson);

      // create a mock feature for supplying length info
      const fi = new FI({guid: '', wkt: '', layerId: layer.id, attrs: []}, this.app.api);
      const lengthAttr = new FIAttr({name: 'laenge', value: length.toFixed(2)}, this.app.api);
      lengthAttr.renderValue({});
      fi.addAttribute(lengthAttr);
      const feature = new Feature(fi, layer, new EmptyFeatureEntry(this.app.api));

      const mapPanel = <ExtPanel>this.Ext.getCmp(COMP_IDS.PANEL_MAP);

      const featureDataDlg = new DataEntryDlg(feature, addFeatureDataSet, this.Ext, "erstellen",
        (save, fieldValues) => {
          if (save && fieldValues) {
            return this.onSave(fieldValues, addFeatureDataSet, layer, geoJson, levelFeature);
          } else {
            return Promise.resolve(true); // you may close, we do nothing
          }
        });
      featureDataDlg.show();

    } else {
      // just add feature without data
      this.app.api.addFeature({
        dataSetId: -1,
        geoJson: geoJson,
        altitudes: [],
        accuracies: [],
        layerId: layer.id,
        levelLayerId: levelFeature ? levelFeature.feature.layer.id : undefined,
        levelFeatureGUID: levelFeature ? levelFeature.feature.guid : undefined,
        levelId: levelFeature?.level ? levelFeature.level.id : undefined,
        values: []
      })
    }
  }

  /**
   * Makes the call to the server.
   * @param fieldValues
   * @param addFeatureDataSet
   * @param layer
   * @param geoJson
   * @param levelFeature
   * @protected
   */
  protected onSave(fieldValues: FieldValues, addFeatureDataSet: DataSet, layer: Layer, geoJson: string, levelFeature: LevelFeature | null): Promise<boolean> {
    return this.app.api.addFeature({
      dataSetId: addFeatureDataSet.id,
      geoJson: geoJson,
      accuracies: [],
      altitudes: [],
      layerId: layer.id,
      levelLayerId: levelFeature ? levelFeature.feature.layer.id : undefined,
      levelFeatureGUID: levelFeature ? levelFeature.feature.guid : undefined,
      levelId: levelFeature?.level ? levelFeature.level.id : undefined,
      values: fieldValues
    }).then((featureEntry) => {
      return !!featureEntry;
    }).catch(() => {
      // something happened
      return false;
    })
  }

  protected updateGeomMenu(rootLayer: Layer): void {

    const geomLayers = rootLayer.getGeomChildren(IGISConst.PERM_ADD_GEOM);

    // delete previous menu
    this.buttonAddGeom.setMenu(false);
    let menuConfig: ExtMenuConfig;

    if (geomLayers.length > 0) {

      const menuItems: ExtMenuItemConfig[] = [];

      for (let layer of geomLayers) {
        const menuItem: ExtMenuItemConfig = {
          xtype: 'menuitem',
          text: layer.name,
          handler: (item, event) => {
            this.onAddGeomClick(layer);
          }
        }
        menuItems.push(menuItem);
      }

      menuConfig = {
        xtype: 'menu',
        items: menuItems
      }

    } else {

      const emptyMenuItem: ExtMenuItemConfig = {
        xtype: 'menuitem',
        text: 'Keine Geometrieoperationen verfügbar',
        disabled: true
      }

      menuConfig = {
        xtype: 'menu',
        items: [emptyMenuItem]
      }
    }

    this.buttonAddGeom.setMenu(menuConfig);
  }

  /**
   * Button handler
   * @param layer
   * @protected
   */
  protected onAddGeomClick(layer: Layer): void {
    this.app.featureGeom.beginAddGeom(layer, this.curLevelFeature);
  }
}


