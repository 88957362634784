import {FIAttrGen} from "./generated/FIAttrGen";
import {FI} from "@igis-common/model/FI";
import {Formatter} from "@igis-common/Formatter";
import {AttributeMap} from "@igis-common/model/LayerInfo";

export class FIAttr extends FIAttrGen {

  public feature: FI | null = null; // the feature we are associated with

  protected _displayName: string | null = null;
  protected _displayValue: string | null = null;
  protected _infoString: string | null = null;
  protected _searchable: boolean = false;

  get value(): string {
    return this._value;
  }

  get infoString(): string {
    if (this._infoString === null) {
      return '';
    }
    return this._infoString ? this._infoString : '';
  }

  get name(): string {
    return this._name;
  }

  get display(): boolean {
    return true;
  }

  get searchable(): boolean {
    return this._searchable;
  }

  get hasInfo(): boolean {
    return this.searchable || (this._infoString !== null && this._infoString.length > 0);
  }

  /**
   * Rendered display name for this attribute.
   */
  get displayName(): string {
    if (!this._displayName) {
      console.warn(`${this._name} displayName before renderValue`);
    }
    return this._displayName ? this._displayName : '';
  }

  /**
   * Rendered (display) value for this attribute.
   */
  get displayValue(): string {
    if (this._displayValue === null) {
      console.warn('displayValue before renderValue');
    }
    return this._displayValue ? this._displayValue : '';
  }

  /**
   * Used to render the display name & value for this
   * @param attributeInfos
   */
  public renderValue(attributeInfos: AttributeMap): void {
    if (this._displayName) {
      return; // already processed
    }

    const attrInfo = attributeInfos[this._name];
    if (attrInfo) {
      this._displayName = attrInfo.displayName;
      // format value for this type
      this._displayValue = Formatter.format(this._value, attrInfo);
      this._infoString = attrInfo.info;
      this._searchable = attrInfo.searchable;
    } else {
      // use unprocessed values
      this._displayName = this._name;
      this._displayValue = this._value ? this._value : '';
      this._infoString = '';
    }
  }
}
