
import {IntervalGen} from "./generated/IntervalGen";

export class Interval extends IntervalGen {

  get unit(): string {
    return this._unit;
  }

  // for ordering purposes
  get name(): string {
    return this._dataSetName;
  }

  get dataSetName(): string {
    return this._dataSetName;
  }

  get dataSetId(): number {
    return this._dataSetId;
  }

  get defaultInterval(): number {
    return this._defaultInterval;
  }

  get interval(): number {
    return this._interval;
  }

}
