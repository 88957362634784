
import {ReportFilterGen} from "./generated/ReportFilterGen";

export class ReportFilter extends ReportFilterGen {

  get hasAnyFilter(): boolean {
    return this._date || this._malfunction || this._batch;
  }

  get hasDate(): boolean {
    return this._date;
  }

  get hasMalfunction(): boolean {
    return this._malfunction;
  }

  get hasBatch(): boolean {
    return this._batch;
  }

}
