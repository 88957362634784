
import {DataEntry} from '../DataEntry';
import {Report} from '../Report';
import {Level} from '../Level';
import {StorageEntry} from '../StorageEntry';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class FeatureEntryGen extends Model {
  protected _guid: string;
  protected _layerId: number;
  protected _files: StorageEntry[];
  protected _levels: Level[];
  protected _reports: Report[];
  protected _dataEntries: DataEntry[];

  constructor(json: {guid: string, layerId: number, files: {uuid: string, filename: string, isPic: boolean, isPanorama: boolean, hasThumb: boolean, comment: string, href: string}[], levels: {id: number, name: string, image: {uuid: string, filename: string, isPic: boolean, isPanorama: boolean, hasThumb: boolean, comment: string, href: string}}[], reports: {id: number, name: string, category: string, isFeatureSel: boolean, availFilter: {date: boolean, malfunction: boolean, batch: boolean}}[], dataEntries: {id: number, name: string, dataSetId: number, files: {uuid: string, filename: string, isPic: boolean, isPanorama: boolean, hasThumb: boolean, comment: string, href: string}[], fieldValues: {name: string, value: string, orderNr: number, attribute: {name: string, displayName: string, info: string, unit: string, digits: number, type: number, searchable: boolean, options: {name: string, value: string, visImage?: {uuid: string, filename: string, isPic: boolean, isPanorama: boolean, hasThumb: boolean, comment: string, href: string}}[]}}[]}[]}, public _api: IGISApi) {
    super();
    this._guid = json.guid;
    this._layerId = json.layerId;
    this._files =  json.files.map(item => new StorageEntry(item, _api));
    this._levels =  json.levels.map(item => new Level(item, _api));
    this._reports =  json.reports.map(item => new Report(item, _api));
    this._dataEntries =  json.dataEntries.map(item => new DataEntry(item, _api));
    this.onNew();
  }
}
