// import ExtJS library (js+css)
import '../../ExtJS';

// igis css
import '../../css/igis.css';

// additional components
import {Component} from "@igis-common/component/Component";
import {viewportItems} from "./GUIDefinitions";
import {Observable, ReplaySubject, merge} from "rxjs";
import {IExt} from "../../ext-types/Ext";
import {ExtViewport} from "../../ext-types/window/ExtViewport";
import {AppStrings} from "@igis-common/AppStrings";
import {ERROR_CODE} from "@igis-common/api/IGISApiGen";

// included globally via ExtJS.ts
declare var Ext: IExt;

export class GUIComponent extends Component {

  protected extViewport: ExtViewport;

  private guiSubject = new ReplaySubject<IExt>();
  public gui$: Observable<IExt> = this.guiSubject;

  public Ext: IExt;

  init(): void {
    //initialize tooltips and render main gui
    Ext.onReady(() => { // TODO: do we need to wait for onReady? we are only initialized when the onReady is already met..

      Ext.tip.QuickTipManager.init();

      const viewportConfig = {
        xtype: 'viewport',
        layout: 'fit',
        items: viewportItems,
        renderTo: Ext.getBody()
      }
      this.extViewport = Ext.create<ExtViewport>(viewportConfig);
      this.extViewport.show();
      this.Ext = Ext;

      this.registerErrorListeners();


      this.guiSubject.next(Ext); // notify listeners that the GUI is ready and the Ext handle is filled
      this.guiSubject.complete();
    });
  }

  protected registerErrorListeners(): void {

    this.app.api.apiError$.subscribe((errorResponse) => {
      switch(errorResponse.errorCode) {
        case ERROR_CODE.LOGIN_EXPIRED:
          Ext.Msg.alert('Sitzung', AppStrings.MSG_LOGIN_EXPIRED, () => {
            this.app.api.logoutCall().then(() => {
              window.location.reload();
            })
          })
          break;
        default:
          Ext.Msg.alert('Fehler', AppStrings.MSG_SERVER_ERROR);
          break;
      }
    })

    this.app.api.wmsError$.subscribe((errorResponse) => {
      Ext.Msg.alert('Fehler', AppStrings.MSG_SERVER_ERROR);
    })

    this.app.print.genFailed$.subscribe((errorResponse) => {
      Ext.Msg.alert('Fehler', errorResponse);
    })

    merge(this.app.api.apiNotAvailable$, this.app.api.serverOffline$).subscribe(() => {
      Ext.Msg.alert('Fehler', AppStrings.MSG_SERVER_NOT_AVAILABLE);
    })

    this.app.api.apiSessionExpired$.subscribe(() => {
      // display error message and restart app
      console.log('session closed');
      Ext.Msg.alert('Sitzung', AppStrings.MSG_SESSION_CLOSED, () => {
        window.location.reload();
      })
    })

  }

}
