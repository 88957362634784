import {DataFieldGen} from "./generated/DataFieldGen";
import {Option} from "@igis-common/model/Option";
import {Model, ValueMap} from "@igis-common/api/Model";
import {FI} from "@igis-common/model/FI";

export type FieldValues = { fieldId: number, value: string }[];

export interface IOption {
  name: string;
  value: string;
}

export interface DataFieldOptionExt {
  name: string;
  value: string;
  inputId: string;
}

export class DataField extends DataFieldGen {

  protected _lastValue: any;

  get id(): number {
    return this._id;
  }

  get isMandatory(): boolean {
    return this._mandatory;
  }

  get isReadonly(): boolean {
    return this._readonly;
  }

  get isHidden(): boolean {
    return this._hidden;
  }

  get isInsertPreviousValue(): boolean {
    return this._insertPrevious;
  }

  get type(): number {
    return this._type;
  }

  set lastValue(lastValue: any) {
    this._lastValue = lastValue;
  }

  get lastValue(): any {
    return this._lastValue;
  }

  get name(): string {
    return this._name;
  }

  get initValue(): string {
    return this._initValue;
  }

  get attributeName(): string {
    return this._attributeName;
  }
  
  get options(): Option[] {
    return this._options;
  }

  get isOptionsAreTemplates(): boolean {
    return this._optionsAreTemplates;
  }

  get maxCharLength(): number {
    return this._maxCharLength;
  }

  public isVisible(valueMap: ValueMap) {
    return Model.evalCond(valueMap, this._condVisible);
  }

  public isEnabled(valueMap: ValueMap) {
    return Model.evalCond(valueMap, this._condEnabled);
  }

  public static getOptionsWithIds(options: IOption[], fieldId: number): DataFieldOptionExt[] {
    const ret: DataFieldOptionExt[] = [];
    for (let option of options) {
      ret.push({
        name: option.name,
        value: option.value,
        inputId: fieldId + '_' + option.value
      });
    }
    return ret;
  }

  /**
   * Retrieves the current value for this input field, depending on given feature info, init value etc..
   * @param feature may be null
   * @param raw
   */
  public getFeatureAttrValue(feature: FI|undefined, raw: boolean = false): any {
    let attrValue: string | null = '';
    if (feature) {
      const attrName = this.attributeName;
      if (attrName && attrName.length > 0) {
        // check if we have a date here
        if (raw) {
          attrValue = feature.getRawAttributeValue(attrName);
        } else {
          attrValue = feature.getAttributeValue(attrName);
        }
      }
    }
    if (attrValue == '' || !attrValue) {
      attrValue = this.initValue;
    }

    // look if we have a previous value (if not a hidden field)
    const prevValue = this.lastValue;
    if (!this.isHidden && this.isInsertPreviousValue && prevValue != null) {
      attrValue = prevValue;
    }

    return attrValue;
  }

  public static convertValuesToMap(fieldValues: FieldValues): ValueMap {
    // TODO: use a more javascript way to do this, performance increase?
    const valueMap: ValueMap = {};
    for (let value of fieldValues) {
      valueMap[value.fieldId] = value.value;
    }
    return valueMap;
  }

  public static getBooleanOptions(): IOption[] {
    return [{name: 'Ja', value: '1'}, {name: 'Nein', value: '0'}];
  }

}
