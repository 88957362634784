import {DataSetListResultGen} from "./generated/DataSetListResultGen";
import {ListResultEntry} from "@igis-common/model/ListResultEntry";
import {LayerListResult} from "@igis-common/model/LayerListResult";

export class DataSetListResult extends DataSetListResultGen {
  protected _layerResult;

  set layerResult(layerResult: LayerListResult) {
    this._layerResult = layerResult;
  }

  get layerResult(): LayerListResult {
    return this._layerResult;
  }

  get dataSetName(): string {
    return this._dataSetName;
  }

  get name(): string {
    return this._dataSetName; // alias for sorting
  }

  get results(): ListResultEntry[] {
    return this._results;
  }

  get resultCnt(): number {
    return this._resultCnt;
  }

  get nameAndResultCnt(): string {
    return this.dataSetName + ' [' + this.resultCnt + ']';
  }

}
