import {IExt} from "../../../ext-types/Ext";
import {ExtFormFieldBase} from "../../../ext-types/form/field/ExtFormFieldBase";
import {ValueMap} from "@igis-common/api/Model";

export abstract class DataEntryFieldBase {

  protected cmpValue: ExtFormFieldBase;

  constructor(protected label: string, Ext: IExt) {
    // in the constructor of the child class: create cmpValue
  }

  public addToForm(): ExtFormFieldBase[]|null {
    this.cmpValue.setFieldLabel(this.label);
    return [this.cmpValue];
  }

  public abstract getFieldId(): number;

  public abstract getValue(): any;

  public abstract updateComponent(fieldValues: ValueMap);

  public check(): boolean {
    return true; // is overridden in input field to provide data validation
  }

  public saveLastValue(): void {
  }
}
