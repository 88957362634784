// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../desktop/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../desktop/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.igis-map {
    position: absolute;
    top: 0;
    left: 0;
}

.img-top {
    position: absolute;
    top: 0;
    left: 0;
}

.feature-svg-sel svg {
    filter: grayscale(100%);
}

.feature-svg-search svg {
    filter: grayscale(100%) sepia(100%) hue-rotate(90deg);
}

.locate-following {
    color: #2A93EE;
}
`, "",{"version":3,"sources":["webpack://./../igis-common/src/css/map.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;AACX;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;AACX;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,qDAAqD;AACzD;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".igis-map {\n    position: absolute;\n    top: 0;\n    left: 0;\n}\n\n.img-top {\n    position: absolute;\n    top: 0;\n    left: 0;\n}\n\n.feature-svg-sel svg {\n    filter: grayscale(100%);\n}\n\n.feature-svg-search svg {\n    filter: grayscale(100%) sepia(100%) hue-rotate(90deg);\n}\n\n.locate-following {\n    color: #2A93EE;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
