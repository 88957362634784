import {VISIBILITY} from "@igis-common/model/Layer";
import {BasemapLayer} from "@igis-common/model/BasemapLayer";
import {Map, TileLayer} from "leaflet";
import {EmptyLayer} from "@igis-common/model/EmptyLayer";

export class BasemapMapLayer extends EmptyLayer {

  private mapLayer: TileLayer | null = null;
  private map: Map | null = null;
  private readonly _basemapId : number;

  constructor(public basemap: BasemapLayer) {
    super(basemap._api);
    this._name = basemap.name;
    this._visible = basemap.defaultVisible;
    this._defaultVisible = basemap.defaultVisible;
    this._queryable = false;
    this._wms = false;
    this._basemapId = basemap.id;

    this.visibilityChange$.subscribe(newVis => {
      if (!this.mapLayer || !this.map) {
        return;
      }
      switch (newVis) {
        case VISIBILITY.VISIBLE:
          this.mapLayer.addTo(this.map);
          break;
        default:
          this.mapLayer.remove();
          break;
      }
    })
  }

  get wmsName(): string {
    return '_orthofoto';
  }

  get basemapId(): number {
    return this._basemapId;
  }

  get isBasemap(): boolean {
    return true;
  }

  public createLeafletLayer(map: L.Map, zIndex: number): L.TileLayer {
    this.map = map;
    this.mapLayer = new TileLayer(this.basemap.leafletUrl, {
      attribution: this.basemap.attribution,
      maxZoom: 22,
      maxNativeZoom: 18,
      zIndex: zIndex,
      opacity: this.basemap.opacity
    });
    return this.mapLayer;
  }

}

