import {ProjectListEntryGen} from "./generated/ProjectListEntryGen";

export class ProjectListEntry extends ProjectListEntryGen {

  get name(): string {
    return this._name;
  }

  get id(): number {
    return this._pid;
  }

}