import {Layer} from "@igis-common/model/Layer";
import {FI} from "@igis-common/model/FI";

export class FIResultLayer {

  protected _features: FI[];

  constructor(public layer: Layer) {
    this._features = [];
  }

  get features(): FI[] {
    return this._features;
  }

  addFeature(feature: FI): void {
    feature.layer = this.layer;
    this._features.push(feature);
  }
}
