
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class SearchResultEntryGen extends Model {
  protected _guid: string;
  protected _wkt: string;
  protected _lat: number;
  protected _lon: number;
  protected _x: number;
  protected _y: number;
  protected _name: string;

  constructor(json: {guid: string, wkt: string, lat: number, lon: number, x: number, y: number, name: string}, public _api: IGISApi) {
    super();
    this._guid = json.guid;
    this._wkt = json.wkt;
    this._lat = json.lat;
    this._lon = json.lon;
    this._x = json.x;
    this._y = json.y;
    this._name = json.name;
    this.onNew();
  }
}
