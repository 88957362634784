import {ReportGen} from "./generated/ReportGen";
import {ReportFilter} from "@igis-common/model/ReportFilter";
import {Layer} from "@igis-common/model/Layer";

export class Report extends ReportGen {

  // for saving the layer we are referenced from
  protected _layer: Layer | null = null;

  get layer(): Layer | null {
    return this._layer;
  }

  set layer(value: Layer | null) {
    this._layer = value;
  }

  get id(): number {
    return this._id;
  }

  get isFeatureSelect(): boolean {
    return this._isFeatureSel;
  }

  get name(): string {
    return this._name;
  }

  get category(): string {
    return this._category;
  }

  get hasAnyFilter(): boolean {
    if (!this._availFilter) {
      return false;
    }
    return this._availFilter.hasAnyFilter;
  }

  get availFilter(): ReportFilter {
    return this._availFilter;
  }

}
