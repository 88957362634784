
import {LayerSearchResult} from '../LayerSearchResult';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class SearchResultGen extends Model {
  protected _layerResults: LayerSearchResult[];

  constructor(json: {layerResults: {layerId: number, layerName: string, resultCnt: number, results: {guid: string, wkt: string, lat: number, lon: number, x: number, y: number, name: string}[]}[]}, public _api: IGISApi) {
    super();
    this._layerResults =  json.layerResults.map(item => new LayerSearchResult(item, _api));
    this.onNew();
  }
}
