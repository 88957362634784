
import {ListResultEntry} from '../ListResultEntry';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class DataSetListResultGen extends Model {
  protected _dataSetId: number;
  protected _dataSetName: string;
  protected _resultCnt: number;
  protected _results: ListResultEntry[];

  constructor(json: {dataSetId: number, dataSetName: string, resultCnt: number, results: {guid: string, wkt: string, lat: number, lon: number, name: string, lastEntryPretty: string}[]}, public _api: IGISApi) {
    super();
    this._dataSetId = json.dataSetId;
    this._dataSetName = json.dataSetName;
    this._resultCnt = json.resultCnt;
    this._results =  json.results.map(item => new ListResultEntry(item, _api));
    this.onNew();
  }
}
