import {FI} from "@igis-common/model/FI";
import {IGISConst} from "@igis-common/const";
import {DataField} from "@igis-common/model/DataField";
import {DataEntryInputField} from "./DataEntryInputField";
import {DataEntryTextField} from "./DataEntryTextField";
import {DataEntryComboField} from "./DataEntryComboField";
import {DataEntryFloatField} from "./DataEntryFloatField";
import {DataEntryDateField} from "./DataEntryDateField";
import {DataEntryTimeField} from "./DataEntryTimeField";
import {IExt} from "../../../ext-types/Ext";
import {DataEntryBooleanField} from "./DataEntryBooleanField";
import {DataEntryIntegerField} from "./DataEntryIntegerField";

export function createComponent(inputField: DataField, feature: FI, Ext: IExt): DataEntryInputField {

  const options = inputField.options;
  if (options && options.length > 0 && !inputField.isOptionsAreTemplates && inputField.type != IGISConst.TYPE_BOOLEAN) {
    // create a combobox, but not if we have options as templates
    // create a datastore from the values
    return new DataEntryComboField(inputField, feature, Ext);
  }

  // else:
  switch (inputField.type) {
    case IGISConst.TYPE_STRING:
      return new DataEntryTextField(inputField, feature, Ext);
    case IGISConst.TYPE_INTEGER:
      return new DataEntryIntegerField(inputField, feature, Ext);
    case IGISConst.TYPE_FLOAT:
      return new DataEntryFloatField(inputField, feature, Ext);
    case IGISConst.TYPE_DATE:
      return new DataEntryDateField(inputField, feature, Ext);
    case IGISConst.TYPE_TIME:
      return new DataEntryTimeField(inputField, feature, Ext);
    case IGISConst.TYPE_BOOLEAN:
      return new DataEntryBooleanField(inputField, feature, Ext);
    default:
      console.log("unknown input field type " + inputField.type + ", using textfield");
      return new DataEntryTextField(inputField, feature, Ext);
  }
}
