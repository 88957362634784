
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class FileResultGen extends Model {
  protected _uuid: string;
  protected _status: string;

  constructor(json: {uuid: string, status: string}, public _api: IGISApi) {
    super();
    this._uuid = json.uuid;
    this._status = json.status;
    this.onNew();
  }
}
