import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../igis-base";
import {COMP_IDS} from "./GUIDefinitions";
import {ExtButton} from "../../ext-types/button/ExtButton";
import {DataEntryDlg} from "./dataentry/DataEntryDialog";
import {DataSet} from "@igis-common/model/DataSet";
import {FieldValues} from "@igis-common/model/DataField";
import {IExt} from "../../ext-types/Ext";
import {Feature} from "@igis-common/model/Feature";

export class FeatureDataEntryMenuComponent extends Component {

  protected dataEntryButton: ExtButton;
  protected feature: Feature|null;
  protected dataSet: DataSet;

  protected Ext: IExt;

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {
    // wait for gui & project-info
    this.app.startup$.subscribe((data) => {
      // get handle to report button
      const Ext = data.Ext;
      this.Ext = Ext;
      this.dataEntryButton = Ext.getCmp<ExtButton>(COMP_IDS.DATA_ENTRY.START_FEATURE);
      this.dataEntryButton.setHandler(() => {
        this.onDataEntryMenuClick();
      })

      this.app.feature.selectFeature$.subscribe(feature => {
        this.onSelectFeature(feature);
      })
    })
  }

  public onDataEntryMenuClick(): void {
    if (!this.feature || !this.dataSet) {
      return;
    }

    const dataSet = this.dataSet;
    const feature = this.feature;

    console.log('starting data entry for data set ' + dataSet.name);
    const actionVerb = 'ändern';
    new DataEntryDlg(feature, dataSet, this.Ext, actionVerb, ((save, fieldValues) => {
      if (!save || !fieldValues) {
        return Promise.resolve(true); // just close the dialog
      }
      return this.onSave(dataSet, feature, fieldValues);
    })).show();
  }

  protected onSave(dataSet: DataSet, feature: Feature, fieldValues: FieldValues): Promise<boolean> {
    const featureParams = feature.getFeatureIdParams();
    if (!featureParams) {
      console.log('featureParams were null?');
      return Promise.resolve(true); // we don't know what to do, just close dialog
    }

    const params = {
      dataSetId: dataSet.id,
      values: fieldValues
    }

    return this.app.api.addDataEntry({...featureParams, ...params}).then(addDataEntryResult => {
      return addDataEntryResult != null && addDataEntryResult.data != null;
    }).catch(() => {
      return false; // the call failed, do not close the dialog
    });
  }

  protected onSelectFeature(feature: Feature | null): void {

    if (!feature || !feature.layer) {
      this.disableButton();
      return;
    }

    const layer = feature.layer;
    this.feature = feature;
    const dataSet = layer.addFeatureDataSet;
    if (dataSet) {
      this.dataSet = dataSet;
      this.dataEntryButton.setDisabled(false);
      this.dataEntryButton.setTooltip('Stammdaten');
    } else {
      this.disableButton();
    }
  }

  protected disableButton(): void {
    this.dataEntryButton.setTooltip('Stammdaten nicht bearbeitbar');
    this.dataEntryButton.setDisabled(true);
  }
}
