import {FIResultLayer} from "@igis-common/model/FIResultLayer";
import {FI} from "@igis-common/model/FI";
import {MapComponent} from "@igis-common/component/MapComponent";

export abstract class FIResult {

  /** the pixel x coordinate of the request initiation */
  public x: number = -1;
  /** the pixel y coordinate of the request initiation */
  public y: number = -1;

  public map: MapComponent | null = null; // the map component the coordinates are from

  protected fiResultLayers: FIResultLayer[] = [];

  get resultLayers(): FIResultLayer[] {
    return this.fiResultLayers;
  }

  get flatResult(): FI[] {
    const res: FI[] = [];
    for (let l of this.fiResultLayers) {
      for (let f of l.features) {
        res.push(f);
      }
    }

    return res;
  }
}
