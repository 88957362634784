
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class ExportGen extends Model {
  protected _name: string;
  protected _id: number;

  constructor(json: {name: string, id: number}, public _api: IGISApi) {
    super();
    this._name = json.name;
    this._id = json.id;
    this.onNew();
  }
}
