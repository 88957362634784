
import {LayerSearchResultGen} from "./generated/LayerSearchResultGen";
import {SearchResultEntry} from "@igis-common/model/SearchResultEntry";

export class LayerSearchResult extends LayerSearchResultGen {

  get layerId(): number {
    return this._layerId;
  }

  get resultEntries(): SearchResultEntry[] {
    return this._results;
  }

  get resultCnt(): number {
    return this._resultCnt;
  }

  get layerName(): string {
    return this._layerName;
  }

}
