
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class EmptyGen extends Model {

  constructor(json: {}, public _api: IGISApi) {
    super();
    this.onNew();
  }
}
