
import {SearchResultEntry} from '../SearchResultEntry';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class LayerSearchResultGen extends Model {
  protected _layerId: number;
  protected _layerName: string;
  protected _resultCnt: number;
  protected _results: SearchResultEntry[];

  constructor(json: {layerId: number, layerName: string, resultCnt: number, results: {guid: string, wkt: string, lat: number, lon: number, x: number, y: number, name: string}[]}, public _api: IGISApi) {
    super();
    this._layerId = json.layerId;
    this._layerName = json.layerName;
    this._resultCnt = json.resultCnt;
    this._results =  json.results.map(item => new SearchResultEntry(item, _api));
    this.onNew();
  }
}
