
import {AttributeGen} from "./generated/AttributeGen";
import {Option} from "@igis-common/model/Option";

/**
 * Contains information about a specific attribute in the feature info data.
 */
export class Attribute extends AttributeGen {

  get name(): string {
    return this._name;
  }

  get options(): Option[] {
    return this._options;
  }

  get type(): number {
    return this._type;
  }

  get unit(): string {
    return this._unit;
  }

  get info(): string {
    return this._info;
  }

  get digits(): number {
    return this._digits;
  }

  get displayName(): string {
    return this._displayName;
  }

  get searchable(): boolean {
    return this._searchable;
  }

}
