import {LevelGen} from "@igis-common/model/generated/LevelGen";
import {StorageEntry} from "@igis-common/model/StorageEntry";

export class Level extends LevelGen {

  get image(): StorageEntry {
    return this._image;
  }

  get name(): string {
    return this._name;
  }

  get id(): number {
    return this._id;
  }
}
