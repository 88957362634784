import {ListResultEntryGen} from "./generated/ListResultEntryGen";

export class ListResultEntry extends ListResultEntryGen {
  get name(): string {
    return this._name;
  }

  get guid(): string {
    return this._guid;
  }

  get wkt(): string {
    return this._wkt;
  }

  get lat(): number {
    return this._lat;
  }

  get lon(): number {
    return this._lon;
  }

  get lastEntryPretty(): string {
    return this._lastEntryPretty;
  }
}
