import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../igis-base";
import {first} from "rxjs/operators";
import {ProjectInfo} from "@igis-common/model/ProjectInfo";
import {IExt} from "../../ext-types/Ext";
import '../../css/changelog.css';


export class NewsletterComponent extends Component {

  protected projectInfo;

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {
    this.app.startup$.pipe(first()).subscribe(({Ext, projectInfo}) => {
      if (projectInfo.askNewsletter) {
        // display confirmation dialog
        this.askNewsletter(Ext, projectInfo);
      }
    })
  }

  protected askNewsletter(Ext: IExt, projectInfo: ProjectInfo) {
    console.log('Asking newsletter question');
    const username = projectInfo.username;
    let msg = 'Dürfen wir Ihre Adresse "' + username + '" benutzen um Sie per E-Mail über Neuerungen im InfraGIS-System, über neue Verwaltungs-Module und sonstige Neuigkeiten zu informieren?';
    msg += "<br><br>Auch wenn Sie hier zustimmen können Sie den Erhalt des InfraGIS-Newsletters jederzeit wieder abbestellen.";

    Ext.Msg.confirm('InfraGIS Newsletter', msg, (id) => {
      const agree = id === 'yes';
      // call server
      this.app.api.newsletter({agree});
    }, this);
  }
}
