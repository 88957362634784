import './ExtJS'
import {IGISAppBase} from "@igis-common/IGISAppBase";
import {GUIComponent} from "./components/gui/GUIComponent";
import {DesktopMapComponent} from "./components/gui/DesktopMapComponent";
import {IGISApi} from "@igis-common/api/IGISApi";
import {FeatureInfoDisplayComponent} from "./components/gui/featureinfo/FeatureInfoDisplayComponent";
import {FeatureDataWindowComponent} from "./components/gui/featureinfo/FeatureDataWindowComponent";
import {ReportMenuComponent} from "./components/gui/ReportMenuComponent";
import {DataSetMenuComponent} from "./components/gui/DataSetMenuComponent";
import {forkJoin, ReplaySubject} from "rxjs";
import {ProjectInfo} from "@igis-common/model/ProjectInfo";
import {LayerTreeComponent} from "./components/gui/LayerTreeComponent";
import {ChangelogComponent} from "./components/gui/ChangelogComponent";
import {LogoutButtonComponent} from "./components/gui/LogoutButtonComponent";
import {LevelButtonComponent} from "./components/gui/LevelButtonComponent";
import {LevelListGUIComponent} from "./components/gui/LevelListGUIComponent";
import {DataEntryComponent} from "./components/gui/dataentry/DataEntryComponent";
import {StatusTextComponent} from "./components/gui/StatusTextComponent";
import {IExt} from "./ext-types/Ext";
import {AddFeatureComponent} from "./components/geom/AddFeatureComponent";
import {MoveFeatureComponent} from "./components/geom/MoveFeatureComponent";
import {DeleteFeatureComponent} from "./components/geom/DeleteFeatureComponent";
import {UploadButtonComponent} from "./components/gui/UploadButtonComponent";
import {SearchGUIComponent} from "./components/gui/SearchGUIComponent";
import {FeatureReportMenuComponent} from "./components/gui/FeatureReportMenuComponent";
import {CloseBatchComponent} from "./components/gui/CloseBatchComponent";
import {LegendComponent} from "./components/gui/LegendComponent";
import {PrintMapComponent} from "./components/gui/PrintMapComponent";
import {LoginComponent} from "./components/gui/LoginComponent";
import {ProjectSelectComponent} from "./components/gui/ProjectSelectComponent";
import {FeatureDataEntryMenuComponent} from "./components/gui/FeatureDataEntryMenuComponent";
import {NewsletterComponent} from "./components/gui/NewsletterComponent";
import {ListPastDueGUIComponent} from "./components/gui/ListPastDueGUIComponent";
import {SettingsWindowComponent} from "./components/gui/settings/SettingsWindowComponent";
import {DesktopGeomHandlerComponent} from "./components/map/DesktopGeomHandlerComponent";
import {ListMalfunctionGUIComponent} from "./components/gui/ListMalfunctionGUIComponent";

/**
 * Main application class for the desktop application.
 * Creates and initializes all components
 */
export class IGIS extends IGISAppBase {

  public readonly gui: GUIComponent;

  /**
   * ReplaySubject which is filled after GUI is loaded and the projectInfo is available
   */
  public startup$ = new ReplaySubject<{Ext: IExt, projectInfo: ProjectInfo}>(1);

  constructor(api: IGISApi) {
    super(api);

    // main gui component, initializes the ExtJS gui
    this.gui = new GUIComponent(this);
    this.componentList.push(this.gui);

    this.componentList.push(new DesktopMapComponent(this));
    this.componentList.push(new ChangelogComponent(this));
    this.componentList.push(new NewsletterComponent(this));
    this.componentList.push(new LogoutButtonComponent(this));
    this.componentList.push(new UploadButtonComponent(this));
    this.componentList.push(new LevelButtonComponent(this));
    this.componentList.push(new FeatureInfoDisplayComponent(this));
    this.componentList.push(new FeatureDataWindowComponent(this));
    this.componentList.push(new SettingsWindowComponent(this));
    this.componentList.push(new ReportMenuComponent(this));
    this.componentList.push(new DataSetMenuComponent(this));
    this.componentList.push(new DataEntryComponent(this));
    this.componentList.push(new LayerTreeComponent(this));
    this.componentList.push(new StatusTextComponent(this));
    this.componentList.push(new AddFeatureComponent(this));
    this.componentList.push(new MoveFeatureComponent(this));
    this.componentList.push(new DeleteFeatureComponent(this));
    this.componentList.push(new FeatureReportMenuComponent(this));
    this.componentList.push(new FeatureDataEntryMenuComponent(this));
    this.componentList.push(new LevelListGUIComponent(this));
    this.componentList.push(new SearchGUIComponent(this));
    this.componentList.push(new ListPastDueGUIComponent(this));
    this.componentList.push(new ListMalfunctionGUIComponent(this));
    this.componentList.push(new CloseBatchComponent(this));
    this.componentList.push(new LegendComponent(this));
    this.componentList.push(new PrintMapComponent(this));
    this.componentList.push(new LoginComponent(this));
    this.componentList.push(new ProjectSelectComponent(this));
    this.componentList.push(new DesktopGeomHandlerComponent(this, this.mapMain));
    this.componentList.push(new DesktopGeomHandlerComponent(this, this.mapImage));

    // wait for both the GUI and the projectinfo
    forkJoin({
      gui: this.gui.gui$,
      projectInfo: this.projectInfo$
    }).subscribe(data => {
      this.startup$.next({Ext: data.gui, projectInfo: data.projectInfo});
      this.startup$.complete();
    })

  }
}
