import {webSocket, WebSocketSubject} from "rxjs/webSocket";
import {Observable, Subject} from "rxjs";

const RECONNECT_INTERVAL = 60000; // try again every minute

export interface WsMessage {
  action: string;
  layerId?: number;
  featureGUID?: string;
}

export class WebsocketClient {

  protected socket$: WebSocketSubject<any> | undefined;

  protected messagesSubject = new Subject<WsMessage>();
  public messages$: Observable<WsMessage> = this.messagesSubject;

  protected readonly wsUrl: string;

  constructor(wsPath: string, private _accessToken: string | null, private clientUUID: string, private projectId: number) {
    this.wsUrl = ((window.location.protocol === "https:") ? "wss://" : "ws://") + window.location.host + wsPath;
    console.log('[ws] created url ', this.wsUrl);
    this.connect();
  }

  set accessToken(token: string) {
    this._accessToken = token;
  }

  public send(msg: string) {
    if (this.socket$) {
      this.socket$.next(msg);
    }
  }

  protected connect() {
    const supportsWebSockets = 'WebSocket' in window || 'MozWebSocket' in window;
    if (!supportsWebSockets) {
      console.log('no websocket support');
      return;
    }
    console.log("[ws] connect");
    this.socket$ = webSocket({
      url: this.wsUrl,
      closeObserver: {
        next: () => {
          console.log('[ws] connection closed');
          setTimeout(() => {
            this.connect()
          }, RECONNECT_INTERVAL);
        }
      },
      openObserver: {
        next: () => {
          console.log('[ws] register');
          this.socket$?.next(`${this._accessToken} ${this.projectId} ${this.clientUUID}`); // we "register" by sending token + projectid + client serial
        }
      }
    });
    this.socket$.subscribe(msg => {
      this.messagesSubject.next(msg);
    }, () => {
      console.log('[ws] close');
    })
  }
}
