import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../igis-base";
import {switchMap, map, first} from "rxjs/operators";
import {ExtButton} from "../../ext-types/button/ExtButton";
import {COMP_IDS} from "./GUIDefinitions";
import {WMSMapComponent} from "@igis-common/component/WMSMapComponent";
import {ExtPanel} from "../../ext-types/panel/ExtPanel";
import {ExtToolbar} from "../../ext-types/toolbar/ExtToolbar";
import {IExt} from "../../ext-types/Ext";
import {PrintConfig, PrintFormat, PrintOrientation} from "@igis-common/PrintConfig";
import {LatLng} from "leaflet";
import {ExtFormFieldCombo} from "../../ext-types/form/field/ExtFormFieldCombo";
import {Layer} from "@igis-common/model/Layer";
import {BasemapMapLayer} from "@igis-common/leaflet/BasemapMapLayer";
import {ExtFormFieldCheckbox} from "../../ext-types/form/field/ExtFormFieldCheckbox";


const SCALE_BOMBOX_ID = 'scale-combo';
const WITH_TABLE_ID = 'with-table-ck';

export class PrintMapComponent extends Component {

  protected mainPanel: ExtPanel;
  protected printButton: ExtButton;

  protected scaleCombobox: ExtFormFieldCombo;
  protected withTableCheckbox: ExtFormFieldCheckbox;

  protected map: WMSMapComponent;
  protected rootLayer: Layer;

  protected oldToolbar: ExtToolbar | null;
  protected ourToolbar: ExtToolbar;

  protected printFormat = PrintFormat.A4;
  protected printOrientation = PrintOrientation.q;

  protected curScale: number;
  protected curMapCenter: LatLng;

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {
    this.app.startup$.subscribe(({Ext, projectInfo}) => {
      this.printButton = <ExtButton>Ext.getCmp(COMP_IDS.PRINT_MAP);
      this.mainPanel = <ExtPanel>Ext.getCmp(COMP_IDS.PANEL_MAP);

      this.createOurToolbar(Ext);

      const items = this.mainPanel.getDockedItems('toolbar[dock="top"]');
      if (items.length == 0) {
        console.log('did not find main toolbar');
        return null;
      }
      // else:
      this.oldToolbar = <ExtToolbar>items[0];

      this.printButton.setHandler(() => {
        this.onButtonPress();
      });

      // enable only for wms map (for now)
      this.app.mapRoot$.subscribe(({curMap, rootLayer}) => {
        if (curMap instanceof WMSMapComponent) {
          this.map = curMap;
          this.rootLayer = rootLayer;
          this.printButton.setDisabled(false);
          //this.printButton.setIcon(pathIconPrintMapEnabled);
        } else {
          this.printButton.setDisabled(true);
          //this.printButton.setIcon(pathIconPrintMapDisabled);
        }
      })

      // we need to react on the first map change to set the "with table" checkbox correctly
      this.app.mapRoot$.pipe(first()).subscribe(({curMap, rootLayer}) => {
        this.onLayerChange(rootLayer);
      })

      // ...further layer changes are handled by this subscription
      this.app.mapRoot$.pipe(switchMap(({curMap, rootLayer}) => {
        return rootLayer.stateChangesRecursive$.pipe(map(() => {
          return {curMap, rootLayer};
        }));
      })).subscribe(({curMap, rootLayer}) => {
        this.onLayerChange(rootLayer);
      });

      this.app.mapChange$.pipe(switchMap((map) => {
        return map.printExtentScaleChange$;
      })).subscribe(newScaleId => {
        this.curScale = newScaleId;
        if (this.scaleCombobox) {
          this.scaleCombobox.setValue(newScaleId);
        }
      })
      this.app.mapChange$.pipe(switchMap((map) => {
        return map.printExtentMapCenterChange$;
      })).subscribe(mapCenter => {
        this.curMapCenter = mapCenter;
      })

    })
  }

  protected createOurToolbar(Ext: IExt) {
    const toolbarConfig = {
      xtype: 'toolbar',
      hidden: true,
      dock: 'top',
      items: [{
        xtype: 'button',
        text: 'Abbrechen',
        listeners: {
          'click': () => {
            this.restoreOldToolbar();
          }
        }
      }, {
        xtype: 'tbspacer'
      }, {
        xtype: 'tbtext',
        html: 'Druckoptionen: '
      }, {
        xtype: 'combo',
        width: 70,
        editable: false,
        store: {
          xtype: 'arraystore',
          data: PrintConfig.printCapabilities.formats,
          fields: ['name', 'value']
        },
        valueField: 'value',
        displayField: 'name',
        value: this.printFormat,
        listeners: {
          'select': (combo, record, index) => {
            this.printFormat = record.data.value;
            this.updateFormat();
          }
        }
      }, {
        xtype: 'tbspacer'
      }, {
        xtype: 'combo',
        width: 70,
        editable: false,
        store: {
          xtype: 'arraystore',
          data: PrintConfig.printCapabilities.orientations,
          fields: ['name', 'value']
        },
        valueField: 'value',
        displayField: 'name',
        value: this.printOrientation,
        listeners: {
          'select': (combo, record, index) => {
            this.printOrientation = record.data.value;
            this.updateFormat();
          }
        }
      }, {
        xtype: 'tbspacer'
      }, {
        xtype: 'combo',
        width: 95,
        id: SCALE_BOMBOX_ID,
        editable: false,
        store: {
          xtype: 'arraystore',
          data: PrintConfig.printCapabilities.scales,
          fields: [{
            name: 'name',
            type: 'string'
          }, {
            name: 'value',
            type: 'int'
          }]
        },
        valueField: 'value',
        displayField: 'name',
        listeners: {
          'select': (combo, record, index) => {
            const scale = record.data.value;
            this.map.setPrintScale(scale);
            this.curScale = scale;
          }
        }
      }, {
        xtype: 'tbspacer'
      }, {
        xtype: 'checkbox',
        id: WITH_TABLE_ID,
        boxLabel: 'mit Tabelle'
      }, {
        xtype: 'tbspacer'
      }, {
        xtype: 'button',
        text: 'Drucken',
        listeners: {
          click: () => {
            this.onPrint();
          }
        }
      }]
    }

    this.ourToolbar = <ExtToolbar>Ext.create(toolbarConfig);
    this.scaleCombobox = <ExtFormFieldCombo>Ext.getCmp(SCALE_BOMBOX_ID);
    this.withTableCheckbox = <ExtFormFieldCheckbox>Ext.getCmp(WITH_TABLE_ID);
    this.mainPanel.addDocked(this.ourToolbar);
  }

  protected restoreOldToolbar(): void {
    this.ourToolbar.setVisible(false);
    this.oldToolbar?.setVisible(true);
    this.app.resetMode();
    this.map.removePrintSelect();
  }

  protected onLayerChange(rootLayer: Layer) {
    console.log('something changed');
    this.withTableCheckbox.setDisabled(true);
    if (rootLayer) {
      const layers: Layer[] = [];
      rootLayer.addVisibleMapReportLayers(layers);
      if (layers.length > 0) {
        this.withTableCheckbox.setDisabled(false);
      }
    }
  }

  protected onButtonPress(): void {
    this.oldToolbar?.setVisible(false);
    this.ourToolbar.setVisible(true);

    this.updateFormat();

    this.app.setPrintMode();
  }

  protected updateFormat() {
    // generate config based on current selection
    const sizeConfig = PrintConfig.setupPrintScales(this.printFormat, this.printOrientation, new LatLng(47.69, 13.34));
    this.map.setPrintSelectSizes(sizeConfig);
  }

  protected onPrint(): void {
    const layers = this.rootLayer.getVisibleChildren(true);
    // add _orthofoto if basemap is active
    let basemap = false;
    this.rootLayer.getVisibleChildren(false).forEach((layer) => {
      if (layer instanceof BasemapMapLayer && layer.name == 'Orthofoto') {
        layers.push(layer);
      }
    })

    layers.reverse();

    let withTable = false;
    if (!this.withTableCheckbox.isDisabled() && this.withTableCheckbox.getValue()) {
      withTable = true;
    }

    this.app.printMap(this.printFormat, this.printOrientation, this.curScale, layers, this.curMapCenter, withTable);
    this.restoreOldToolbar();
  }
}
