import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../igis-base";
import {ProjectInfo} from "@igis-common/model/ProjectInfo";
import {COMP_IDS, txtNoReports} from "./GUIDefinitions";
import {ExtMenuItemConfig} from "../../ext-types/menu/ExtMenuItem";
import {ExtMenuConfig} from "../../ext-types/menu/ExtMenu";
import {ExtButton} from "../../ext-types/button/ExtButton";
import {FI} from "@igis-common/model/FI";
import {Feature} from "@igis-common/model/Feature";

export class FeatureReportMenuComponent extends Component {

  protected reportButton: ExtButton;
  protected feature: FI|null;

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {
    // wait for gui & project-info
    this.app.startup$.subscribe((data) => {
      // get handle to report button
      const Ext = data.Ext;
      this.reportButton = Ext.getCmp<ExtButton>(COMP_IDS.PRINT_FEATURE_REPORT);

      this.app.feature.selectFeature$.subscribe(feature => {
        this.onSelectFeature(data.projectInfo, feature);
      })
    })
  }

  public onReportMenuClick(reportId: number, feature: Feature): void {
    console.log('generating feature report ' + reportId);
    const featureParams = feature.getFeatureIdParams();
    if (featureParams) {
      const params = {...featureParams, reportId};
      this.app.api.printFeatureReport(params);
    }
  }

  protected onSelectFeature(projectInfo: ProjectInfo, feature: Feature | null): void {

    if (!feature || !feature.layer) {
      this.disableButton();
      return;
    }

    // delete old menu
    this.reportButton.setMenu(false);

    const layer = feature.layer;
    let reports = layer.getFeatureSelReports();

    let menuConfig: ExtMenuConfig;
    let menuItems: any = [];

    if (reports.length > 0) {

      for (let report of reports) {
        let reportName = report.name;
        // create a new menu entry
        let menuItem: ExtMenuItemConfig = {
          xtype: 'menuitem',
          text: reportName,
          handler: (item, event) => {
            this.onReportMenuClick(report.id, feature);
          }
        }
        menuItems.push(menuItem);
      }

      menuConfig = {
        items: menuItems
      }

      this.reportButton.setMenu(menuConfig);

      this.reportButton.setDisabled(false);
      this.reportButton.setTooltip('');

    }  else {
      this.disableButton();
    }
  }

  protected disableButton(): void {
    const menuConfig = {
      items: []
    }
    this.reportButton.setMenu(menuConfig); // set menu to still show the menu caret
    this.reportButton.setTooltip(txtNoReports);
    this.reportButton.setDisabled(true);
  }
}
