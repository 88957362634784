import {DivIcon, DomEvent, LatLng, Marker, Map} from "leaflet";
import {FI} from "@igis-common/model/FI";
import {Feature, LevelFeature} from "@igis-common/model/Feature";
import {Layer} from "@igis-common/model/Layer";
import {ImageFeature} from "@igis-common/leaflet/ImageFeature";
import {DataSet} from "@igis-common/model/DataSet";
import {IGISAppBase} from "@igis-common/IGISAppBase";

import sym_1_overlaySvg from '../image/sym_1_overlay.svg';
import sym_0_overlaySvg from '../image/sym_0_overlay.svg';
import searchOverlaySvg from '../image/search_overlay.svg';
import selectOverlaySvg from '../image/select_overlay.svg';

const ICON_SIZE = [50, 50];

export class ImageMapFeaturePoint extends ImageFeature {

  public readonly fi: FI;
  protected readonly marker: Marker;
  protected selected: boolean = false;
  protected searchResult: boolean = false;

  constructor(levelFeature: LevelFeature, private layer: Layer, dataSet: DataSet | null,
              selectedFeature: Feature | null, geoJsonPoint, latLng: LatLng, app: IGISAppBase) {
    super();

    // decide what image we have to use as marker based on the definition in the layer
    const featureProperties = geoJsonPoint.properties; // these are the feature info properties -> generate a feature from it
    this.fi = FI.fromGeoJSONCollection(featureProperties, layer, levelFeature, app.api);
    this.fi.renderValues();
    this.selected = selectedFeature ? selectedFeature.guid === this.fi.guid : false;

    const icon = this.getIcon(dataSet);

    this.marker = new Marker(latLng, <any>{
      icon: icon,
      guid: this.fi.guid,
      zIndexOffset: 500
    });

    // register ourself as click listener
    this.marker.on('click', (event) => {
      console.log('clicked on feature ', this.fi.guid);
      app.feature.selectFeatureInfo(this.fi);
      DomEvent.stop(event); // prevent marker click from bubbling up to map click
    })
  }

  getLatLng(): LatLng {
    return this.marker.getLatLng();
  }

  get guid(): string {
    return this.fi.guid;
  }

  get svg(): string {
    return this.getIconSVG(null, false);
  }

  get svgURL(): string {
    return this.fi.getFeatureImageURL();
  }

  get visName(): string {
    return this.fi.getVisName();
  }

  get visRawValue(): string {
    return this.fi.getVisRawValue();
  }

  protected getIcon(dataSet: DataSet | null): DivIcon {
    return new DivIcon({
      html: this.getIconSVG(dataSet),
      iconSize: [ICON_SIZE[0], ICON_SIZE[1]], // WHY?,
      className: 'leaflet-div-icon'
    })
  }

  public getIconSVG(dataSet: DataSet | null, renderWithAttributes: boolean = true): string {
    // decide which image we can use to display the marker
    const url = this.fi.getFeatureImageURL();

    // create an icon for that
    // our base image is the svg of the feature
    let svg = '<img alt="feature marker" src="' + url + '" onload="SVGInject(this)"/>';

    // determine if we need to mark the feature
    if (renderWithAttributes && dataSet && dataSet.isApplicableToLayer(this.layer)) {
      const dataSetId = dataSet.id;
      const symAttribute = `sym_${dataSetId}`;
      const symValue = this.fi.getRawAttributeValue(symAttribute);
      const overlay = symValue ? sym_1_overlaySvg : sym_0_overlaySvg;
      svg += `<img class='img-top' alt="feature overlay" src="${overlay}" onload="SVGInject(this)"/>`;
    }
    if (renderWithAttributes && this.selected) {
      const overlay = selectOverlaySvg;
      svg += `<img class='img-top' alt="feature overlay" src="${overlay}" onload="SVGInject(this)"/>`;
    } else {
      // only add search marker if not selected
      if (renderWithAttributes && this.searchResult) {
        const overlay = searchOverlaySvg;
        svg += `<img class='img-top' alt="feature overlay" src="${overlay}" onload="SVGInject(this)"/>`;
      }
    }

    return svg;
  }

  onAdd(map: Map): this {
    this.marker.addTo(map);
    return this;
  }

  onRemove(map: Map): this {
    this.marker.remove();
    return this;
  }

  public markSearch(): void {
    this.searchResult = true;
    this.redrawIcon(null);
  }

  public unmarkSearch(): void {
    if (this.searchResult) {
      this.searchResult = false;
      this.redrawIcon(null);
    }
  }

  protected redrawIcon(dataSet: DataSet | null) {
    const icon = this.getIcon(dataSet);
    this.marker.setIcon(icon);
  }

  public deselect() {
    if (this.selected) {
      this.selected = false;
      this.redrawIcon(null);
    }
  }

  public select() {
    this.selected = true;
    this.redrawIcon(null);
  }

  public setDataSetSymbol(dataSet: DataSet | null) {
    this.redrawIcon(dataSet);
  }

  public getGUID(): string {
    return this.fi.guid;
  }
}
