import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../igis-base";
import {COMP_IDS} from "./GUIDefinitions";
import {first} from "rxjs/operators";
import {ExtToolbarTextItem} from "../../ext-types/toolbar/ExtToolbarTextItem";
import {APP_MODE} from "@igis-common/IGISAppBase";

export class StatusTextComponent extends Component {

  protected statusTextField: ExtToolbarTextItem;
  protected emptyText = 'Objekt wählen';

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {
    this.app.gui.gui$.pipe(first()).subscribe((Ext) => {
      this.statusTextField = <ExtToolbarTextItem>Ext.getCmp(COMP_IDS.STATUS_TEXT);

      this.app.startup$.subscribe(() => {
        // we are done with initializing, remove "Wird geladen..."
        this.statusTextField.setHtml(this.emptyText);
      })

      this.app.modeChange$.subscribe(mode => {
        switch(mode) {
          case APP_MODE.MODE_PRINT:
            this.statusTextField.setHtml('Kartendruck, bitte Ausschnitt wählen');
            break;
          case APP_MODE.MODE_DEFAULT:
            this.statusTextField.setHtml(this.emptyText);
            break;
        }
      })

      this.app.feature.selectDataSet$.subscribe((selDataSet) => {
        if (selDataSet) {
          this.statusTextField.setHtml(selDataSet.name + ' erfassen');
        } else {
          this.statusTextField.setHtml(this.emptyText);
        }
      })

    });
  }
}
