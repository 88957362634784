
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class IntervalGen extends Model {
  protected _dataSetId: number;
  protected _dataSetName: string;
  protected _unit: string;
  protected _defaultInterval: number;
  protected _interval: number;

  constructor(json: {dataSetId: number, dataSetName: string, unit: string, defaultInterval: number, interval: number}, public _api: IGISApi) {
    super();
    this._dataSetId = json.dataSetId;
    this._dataSetName = json.dataSetName;
    this._unit = json.unit;
    this._defaultInterval = json.defaultInterval;
    this._interval = json.interval;
    this.onNew();
  }
}
