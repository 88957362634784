
import {LayerListResultGen} from "./generated/LayerListResultGen";
import {DataSetListResult} from "@igis-common/model/DataSetListResult";

export class LayerListResult extends LayerListResultGen {

  get dataSetResults(): DataSetListResult[] {
    return this._dataSetResults;
  }

  get layerId(): number {
    return this._layerId;
  }

  get layerName(): string {
    return this._layerName;
  }

  get resultCnt(): number {
    return this._resultCnt;
  }

}
