import {Component} from "@igis-common/component/Component";
import {IGISAppBase} from "@igis-common/IGISAppBase";
import {Observable, ReplaySubject} from "rxjs";
import {StorageEntry} from "@igis-common/model/StorageEntry";

export class PanoramaImageComponent extends Component {

  private panoramaImageSubject = new ReplaySubject<StorageEntry>(1);
  /**
   * Publishes panorama images to be displayed
   */
  public panoramaImage$: Observable<StorageEntry> = this.panoramaImageSubject;

  constructor(protected app: IGISAppBase) {
    super(app);
  }

  public init() {
  }

  public openParanoramaImage(image: StorageEntry) {
    this.panoramaImageSubject.next(image);
  }



}
