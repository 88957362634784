import {DataField} from "@igis-common/model/DataField";
import {FI} from "@igis-common/model/FI";
import {DataEntryFieldBase} from "./DataEntryFieldBase";
import {IExt} from "../../../ext-types/Ext";
import {ExtForm} from "../../../ext-types/form/ExtForm";
import {ExtComponent} from "../../../ext-types/ExtComponent";
import {ExtFormFieldBase} from "../../../ext-types/form/field/ExtFormFieldBase";

export interface ChangeCallback {
  (): void;
}

export abstract class DataEntryInputField extends DataEntryFieldBase {

  protected changeEventName: string | null = null;

  protected constructor(protected inputField: DataField, private feature: FI, Ext: IExt) {
    super(inputField.name, Ext);
  }

  protected getFeatureAttrValue(raw: boolean = false) {
    return this.inputField.getFeatureAttrValue(this.feature, raw);
  }

  public addToForm(): ExtFormFieldBase[] | null {
    // do not add field if is hidden
    if (!this.inputField.isHidden) {
      return super.addToForm();
    } else {
      return null;
    }
  }

  public addChangeListener(listener: ChangeCallback) {
    if (this.changeEventName) {
      this.cmpValue.addListener(this.changeEventName, () => {
        listener();
      });
    }
  }

  public getFieldId(): number {
    return this.inputField.id;
  }

  public getValue(): any {
    return this.cmpValue.getValue();
  }

  public updateComponent(fieldValues) {
    // determine if component should be active
    const active = this.inputField.isEnabled(fieldValues);
    this.cmpValue.setDisabled(!active);

    // visible?
    const visible = this.inputField.isVisible(fieldValues);
    this.cmpValue.setVisible(visible);
  }

  public check() {
    const value = this.getValue();
    if (!value && this.inputField.isMandatory) {
      this.cmpValue.markInvalid('Muss ausgefüllt werden');
      return false;
    }
    return true;
  }

  public saveLastValue() {
    // save into previous values
    this.inputField.lastValue = this.getValue();
  }
}
