
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class ProjectListEntryGen extends Model {
  protected _name: string;
  protected _pid: number;

  constructor(json: {name: string, pid: number}, public _api: IGISApi) {
    super();
    this._name = json.name;
    this._pid = json.pid;
    this.onNew();
  }
}
