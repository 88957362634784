import {DataSetLayerGen} from "./generated/DataSetLayerGen";

export class DataSetLayer extends DataSetLayerGen {

  get isDisplayOnly(): boolean {
    return this._displayOnly;
  }

  get layerId(): number {
    return this._layerId;
  }
}
