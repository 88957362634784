
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class BasemapLayerGen extends Model {
  protected _id: number;
  protected _name: string;
  protected _url: string;
  protected _attribution: string;
  protected _opacity: number;
  protected _defaultVisible: boolean;

  constructor(json: {id: number, name: string, url: string, attribution: string, opacity: number, defaultVisible: boolean}, public _api: IGISApi) {
    super();
    this._id = json.id;
    this._name = json.name;
    this._url = json.url;
    this._attribution = json.attribution;
    this._opacity = json.opacity;
    this._defaultVisible = json.defaultVisible;
    this.onNew();
  }
}
