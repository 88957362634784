import {Component} from "@igis-common/component/Component";
import {combineLatest, mergeMap, Observable, ReplaySubject, Subject, tap} from "rxjs";
import {FI} from "@igis-common/model/FI";
import {DataSet} from "@igis-common/model/DataSet";
import {filter, map, switchMap} from "rxjs/operators";
import {IGISConst} from "@igis-common/const";
import {MapComponent} from "@igis-common/component/MapComponent";
import {FIResultFromFeature} from "@igis-common/model/FIResultFromFeature";
import {Feature} from "@igis-common/model/Feature";
import {Layer} from "@igis-common/model/Layer";
import {IGISApi} from "@igis-common/api/IGISApi";

interface IBeginDataEntryEvent {
  dataSet: DataSet;
  selFeature: Feature;
}

export class FeatureComponent extends Component {

  /**
   * New selected feature info, coming from e.g. map wms request
   * @private
   */
  private selectFeatureInfo$ = new Subject<FI | null>();

  private updatedFeatureSubject = new Subject<Feature | null>();
  /**
   * Stream of incoming updated features, in any order, not necessarily limited to currently selected feature
   * @private
   */
  public updatedFeature$: Observable<Feature | null> = this.updatedFeatureSubject;


  private updateSelectedFeatureSubject = new ReplaySubject<Feature | null>(1);
  /**
   * Transmits changes to the currently selected feature. Either a new selected feature, updated feature information, or updated geometry.
   */
  public updateSelectedFeature$: Observable<Feature | null> = this.updateSelectedFeatureSubject;

  private selectFeatureSubject = new ReplaySubject<Feature | null>(1);
  /**
   * Publishes the currently selected feature. If a feature is selected twice, only one value is delivered.
   */
  public selectFeature$: Observable<Feature | null> = this.selectFeatureSubject;


  private selectDataSetSubject = new ReplaySubject<DataSet | null>(1);
  /**
   * Transmits the current selected dataset
   */
  public selectDataSet$: Observable<DataSet | null> = this.selectDataSetSubject;


  private curAvailableDataSetsSubject = new ReplaySubject<DataSet[]>(1);
  /**
   * Holds the list of currently available datasets.
   * Is only filled when a feature is selected, and the currently selected dataset is exempt.
   */
  public curAvailableDataSets$: Observable<DataSet[]> = this.curAvailableDataSetsSubject;

  private beginDataEntrySubject = new ReplaySubject<IBeginDataEntryEvent>(1);
  public beginDataEntry$: Observable<IBeginDataEntryEvent> = this.beginDataEntrySubject;

  private beginSelectDataSetSubject = new Subject<DataSet>();
  public beginSelectDataSet$: Observable<DataSet> = this.beginSelectDataSetSubject;


  private stateChangeSubject = new Subject<{ dataSet: DataSet | null, feature: Feature | null }>();
  /**
   * FeatureInfo/AddDataEntry state change observable. Use either this or the more refined begin<>$ observables.
   * @private
   */
  public stateChange$: Observable<{ dataSet: DataSet | null, feature: Feature | null }> = this.stateChangeSubject;

  public init() {

    // start with data set and feature null
    this.selectDataSetSubject.next(null);
    this.selectFeatureInfo$.next(null);

    // log currently selected feature
    this.updateSelectedFeature$.subscribe(feature => {
      console.log(`updatesel feature: ${feature?.guid}`);
    })
    this.selectFeature$.subscribe(newFeature => {
      console.log(`new sel feature: ${newFeature?.guid}`);
    })

    // subscribe to new feature info events to fetch ext feature info and then set as new selected feature
    this.selectFeatureInfo$
      .pipe(mergeMap(fi => FeatureComponent.fetchExtFeatureInfo(this.app.api, fi)))
      .subscribe(feature => {
        this.selectFeatureSubject.next(feature);
      });

    // determine the currently available datasets (depending on selected feature)
    this.selectFeature$.pipe(
      switchMap((feature) => {
        return this.selectDataSetSubject.pipe(
          map((curDataSet) => {
            return {feature, curDataSet};
          }));
      })).subscribe(vals => {
      let curDataSets: DataSet[] = [];
      const feature = vals.feature;
      const curDataSet = vals.curDataSet;
      if (feature) {
        const layer = feature.layer;
        if (layer.hasPermission(IGISConst.PERM_DATASET)) {
          // look over dataset list and filter the current-one
          for (let dataSet of layer.getFeatureDataSets()) {
            if (!curDataSet || dataSet.id !== curDataSet.id) {
              curDataSets.push(dataSet);
            }
          }
        }
      }
      this.curAvailableDataSetsSubject.next(curDataSets);
    })

    this.selectDataSetSubject.pipe(
      switchMap(dataSet => {
        return this.selectFeature$.pipe(
          map(selFeature => {
              return {selFeature, dataSet};
            }
          )
        )
      })
    ).subscribe((vals) => {
      // process changes in selected dataset & feature
      this.onStateChange(vals.selFeature, vals.dataSet);
      this.stateChangeSubject.next({
        dataSet: vals.dataSet,
        feature: vals.selFeature
      });
    })

    // there is information that our currently selected feature may have been updated in api.featureUpdate$..
    // triggers the re-fetching of feature info and feeds the updated info in updatedFeature$
    this.selectFeature$.pipe(
      filter(feature => !!feature),
      switchMap((feature) => {
        return this.app.api.featureUpdate$.pipe(map(updGUID => {
          return {feature, updGUID};
        }))
      }),
      filter(({feature, updGUID}) => {
        return !!(feature && feature.guid === updGUID);
      }),
      switchMap(({feature, updGUID}) => {
          console.log('now updating feature');
          // @ts-ignore
          return this.updateFeatureInfo(feature) // this is never null because of filter, ts-compiler does not see this...
        }
      ),
      switchMap(fi => FeatureComponent.fetchExtFeatureInfo(this.app.api, fi))
    )
      .subscribe(updatedFeature => {
        if (updatedFeature) {
          this.updatedFeatureSubject.next(updatedFeature);
        }
      })


    // transfer any changes to the selected feature to the updated selected feature...
    this.selectFeature$.subscribe(selFeature => {
      this.updateSelectedFeatureSubject.next(selFeature);
    })
    // ...and in addition combine last selected feature and updated feature into a (maybe) updated selected feature
    combineLatest([this.selectFeature$, this.updatedFeature$]).subscribe(([selFeature, updatedFeature]) => {
      if (!selFeature || !updatedFeature) {
        return; // no update
      }
      if (selFeature.guid === updatedFeature.guid) {
        this.updateSelectedFeatureSubject.next(updatedFeature);
      }
    })

    // if the map changes, we need to clear the current dataset
    this.app.mapRoot$.subscribe(() => {
      this.clearSelectedDataSet();
    })
  }

  /**
   * Triggers a call for fetching the feature info of a feature (again).
   * @param feature
   * @private
   */
  private updateFeatureInfo(feature: Feature): Promise<FI> {
    if (feature.layer.id && !feature.isOnLevel()) {
      // we have a WMS feature, we can query the distributed WMS service...
      return this.app.api.wmsFeatureInfoByGUID(feature.layer.id, feature.guid).then(newFI => {
        if (newFI) {
          // copy data into feature &
          feature.fi.copyAttributes(newFI);
        }
        return feature.fi;
      })
    } else {
      // ..we have a level feature: query the igis-srv directly
      const featureParams = feature.fi.getFeatureIdParams();
      if (!featureParams) {
        console.error(`could not update feature info for ${feature.guid}`);
        // return the un
        return Promise.resolve(feature.fi);
      }
      console.log('updating fi from igis-src');
      return this.app.api.getFeatureInfo(featureParams).then(fiResult => {
        if (fiResult && fiResult.data) {
          feature.fi.copyAttributes(fiResult.data);
        } else {
          console.error(`could not fetch fi for in-level feature ${feature.guid}`);
        }
        return feature.fi;
      })
    }
  }

  /**
   * Trigger fetching the ext-feature info from igis-srv and returns a Promise to a complete Feature object.
   * @param api
   * @param fi
   * @private
   */
  public static fetchExtFeatureInfo(api: IGISApi, fi: FI | null): Promise<Feature | null> {
    if (!fi) {
      return Promise.resolve(null);
    }
    if (!fi.layer?.id) {
      return Promise.resolve(null);
    }
    const featureParams = fi.getFeatureIdParams();
    if (!featureParams) {
      return Promise.resolve(null); // we cannot fetch feature info without parameters
    }
    const layer = fi.layer;
    // we need to fetch it from server
    return Promise.all([api.getExtFeatureInfo(featureParams), this.fetchLayerInfo(api, fi.layer)])
      .then(([featureEntryResult, layerInfo]) => {
        if (featureEntryResult && featureEntryResult.data) {
          // construct the complete feature object
          return new Feature(fi, layer, featureEntryResult.data);
        } else {
          return null;
        }
      });
  }

  private static fetchLayerInfo(api: IGISApi, layer: Layer | null): Promise<boolean> {
    if (!layer) {
      return Promise.resolve(false);
    }
    if (layer.layerInfo) {
      return Promise.resolve(true); // we already have the layer info object
    }
    if (!layer?.id) {
      console.warn('try to fetch layerinfo for FI w/o layer');
      return Promise.resolve(false);
    }
    // for now just return result from server
    return api.getLayerInfo({
      layerId: layer.id
    }).then(layerInfoResult => {
      if (layerInfoResult && layerInfoResult.data) {
        layer.layerInfo = layerInfoResult.data;
        return true;
      } else {
        return false;
      }
    })
  }

  /**
   * Translates the state changes into separate observables.
   * @param selFeature
   * @param dataSet
   * @protected
   */
  protected onStateChange(selFeature: Feature | null, dataSet: DataSet | null): void {
    if (selFeature) {
      if (dataSet) {
        // when both a dataset is selected and a feature matching this dataset -> emit beginDataEntry$
        if (dataSet.isApplicableToLayer(selFeature.layer)) {
          this.beginDataEntrySubject.next({
            dataSet,
            selFeature
          });
        }
      } else {
        // we don't have a data-set selected: show feature info
        //this.beginFeatureInfoSubject.next(selFeature);
      }
    } else {
      // we don't have a feature selected, maybe we can select a dataset?
      if (dataSet) {
        this.beginSelectDataSetSubject.next(dataSet);
      }
    }
  }

  public selectDataSet(dataSet: DataSet): void {
    this.selectDataSetSubject.next(dataSet);
  }

  public clearSelectedDataSet(): void {
    this.selectDataSetSubject.next(null);
  }

  /**
   * Tries to set the given feature info as selected (After fetching needed ext-info from server)
   * @param fi
   */
  public selectFeatureInfo(fi: FI) {
    this.selectFeatureInfo$.next(fi);
  }

  /**
   * Forces the given Feature as new selected feature.
   * @param feature
   */
  public selectFeature(feature: Feature): void {
    this.selectFeatureSubject.next(feature);
  }

  /**
   * Sets this feature data as new currently selected feature.
   * If this is the same feature as the currently selected one, the selectFeature$ observable is not triggered.
   * @param feature
   */
  public updateFeature(feature: Feature): void {
    console.log(`updating feature: ${feature.guid}`);
    this.updatedFeatureSubject.next(feature);
  }

  public selectFeatureSingle(feature: FI, map: MapComponent): void {
    // we need to inject a fake fi result
    if (feature.layer) {
      const fiResult = new FIResultFromFeature(feature, feature.layer);
      map.injectFIResult(fiResult); // this autoselects the feature
    }
  }

  /**
   * Clears the currently selected feature
   */
  public clearSelectedFeature(): void {
    console.log('clear selected');
    this.selectFeatureSubject.next(null);
  }

  public beginDataEntry(dataSet: DataSet, selFeature: Feature): void {
    this.beginDataEntrySubject.next({dataSet, selFeature});
  }
}
