import {Component} from "@igis-common/component/Component";
import 'leaflet/dist/leaflet.css';
import '../../css/map.css'; // override some css values (e.g. for pointers)
import {CRS, Map} from 'leaflet';
import {IGIS} from "../../igis-base";
import {COMP_IDS} from "./GUIDefinitions";
import {ExtPanel} from "../../ext-types/panel/ExtPanel";
import {first} from "rxjs/operators";

export class DesktopMapComponent extends Component {

  protected mapWMS: Map;
  protected mapImage: Map;

  constructor(public app: IGIS) {
    super(app);
  }

  init(): void {
    this.app.gui.gui$.pipe(first()).subscribe((Ext) => {
      // create the WMS map
      let options = {};
      // get map html element
      const elem = document.getElementById('map_main');
      if (!elem) {
        console.log('elem is null!');
      } else {
        this.mapWMS = new Map(elem, options);
        // register map
        this.app.mapMain.registerMap(this.mapWMS);
      }

      // create the image map behind
      options = {
        crs: CRS.Simple
      }

      const elemImage = document.getElementById('map_image');
      if (!elemImage) {
        console.log('elemImage is null!');
      } else {
        this.mapImage = new Map(elemImage, options);
        // register map
        this.app.mapImage.registerMap(this.mapImage);
      }

      // register for resize events on the map panel
      const mapPanel = Ext.getCmp<ExtPanel>(COMP_IDS.PANEL_MAP);
      mapPanel.addListener('resize', (panel, adjWidth, adjHeight, rawWidth, rawHeight) => {
        this.mapWMS.invalidateSize();
        this.mapImage.invalidateSize();
      });

    })
  }

}
