import {IGISConst} from "./const";
import {Attribute} from "@igis-common/model/Attribute";

/**
 * A class for collecting all feature info formatters
 */
export class Formatter {

  static readonly locale = ['de-AT', 'de'];

  public static format(value: any, attr: Attribute): string {

    let displayValue = '';

    if (value === undefined || value === null) {
      value = '';
    }
    if (typeof value === 'string') {
      value = value.trim();
    }

    // basic: we display value as-is
    displayValue = value;

    const options = attr.options;
    if (options.length > 0) { // when we have options, try to translate the value
      // try to translate
      for (let option of options) {
        if (option.value == value) {
          displayValue = option.name;
          break;
        }
      }
    } else {
      // we format the raw data according to type and add the
      switch (attr.type) {
        case IGISConst.TYPE_DATE:
          displayValue = Formatter.formatDate(value);
          break;
        case IGISConst.TYPE_INTEGER:
          displayValue = Formatter.formatNumber(value, 0);
          break;
        case IGISConst.TYPE_FLOAT:
          displayValue = Formatter.formatNumber(value, attr.digits);
          break;
        case IGISConst.TYPE_BOOLEAN:
          displayValue = Formatter.formatBoolean(value);
          break;
      }
    }

    if (attr.unit && attr.unit.length > 0) {
      displayValue += " " + attr.unit;
    }

    return displayValue;
  }

  private static formatDate(value: string): string {
    if (!value) {
      return '';
    }

    if (value.length == 0) {
      return '';
    }
    if (value == '9999-99-99') {
      return '';
    }
    if (value.toLowerCase() == 'null') {
      return '';
    }
    // else: format the date

    const parts = value.split('-');
    if (parts.length != 3) {
      return '';
    }
    // else: we have a proper date
    return parts[2] + '.' + parts[1] + '.' + parts[0];
  }

  private static formatNumber(value: string, digits: number): string {
    if (value === undefined) {
      return '';
    }
    // try to parse as float
    const fValue = parseFloat(value);
    if (isNaN(fValue)) {
      // TODO: log me?
      return '';
    }

    if (digits === undefined) {
      return fValue.toLocaleString(Formatter.locale, {
        useGrouping: false,
      });
    } else {
      return fValue.toLocaleString(Formatter.locale, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
        useGrouping: false,
      });
    }
  }

  private static formatBoolean(value: string): string {
    if (value === '') {
      return '';
    }
    // we have something of a value
    if (Number.parseInt(value) > 0 || value === 'true' || value === 't') {
      return 'ja';
    } else {
      return 'nein';
    }
  }

}
