import {OptionGen} from "./generated/OptionGen";
import {StorageEntry} from "@igis-common/model/StorageEntry";

export class Option extends OptionGen {

  get name(): string {
    return this._name;
  }

  get value(): string {
    return this._value;
  }

  get visStorageEntry(): StorageEntry | undefined {
    return this._visImage;
  }
}
