import {FIGen} from "./generated/FIGen";
import {Layer} from "@igis-common/model/Layer";
import {FIAttr} from "@igis-common/model/FIAttr";
import {IGISApi} from "@igis-common/api/IGISApi";
import {FIGroupDto} from "@igis-common/model/FIGroupDto";
import {LevelFeature} from "@igis-common/model/Feature";
import {FeatureParams} from "@igis-common/base";
import {first} from "rxjs/operators";

interface AttrMap {
  [attrName: string]: FIAttr
}


/**
 * FeatureInfo, result from GetFeatureInfo requests
 */
export class FI extends FIGen {

  private attrs: AttrMap = {};
  protected _hasLevels: boolean = false;
  protected _layer: Layer | null = null;

  private _levelFeature: LevelFeature | null = null;

  constructor(json: {guid: string, wkt: string, attrs: {name: string, value: string}[], layerId: number}, api: IGISApi) {
    super(json, api);
    // convert _attr array
    for (let attr of this._attrs) {
      this.addAttribute(attr);
    }
    // set layer from layer-id
    api.projectInfo$.pipe(first()).subscribe(projInfo => {
      const layer = projInfo.getLayerById(this._layerId);
      if (layer) {
        this.layer = layer;
      }
    })
  }

  set levelFeature(levelFeature: LevelFeature | null) {
    this._levelFeature = levelFeature;
  }

  get levelFeature(): LevelFeature | null {
    return this._levelFeature;
  }

  set layer(layer: Layer | null) {
    this._layer = layer;
  }

  get layer(): Layer | null {
    return this._layer;
  }

  get wkt(): string {
    return this._wkt;
  }

  public setWKT(wkt: string): void {
    this._wkt = wkt;
  }

  get guid(): string {
    return this._guid;
  }

  get hasLevels(): boolean {
    return this._hasLevels;
  }

  get name(): string {
    // compile name from display attributes in layer
    const attributes = this.layer?.nameAttributes;
    // fallback
    let name: string;
    if (this.attrs['name']) {
      name = this.attrs['name'].displayValue;
    } else {
      name = this.guid;
    }
    if (attributes) {
      const displayValues: string[] = [];
      for (let attribute of attributes) {
        const attrValue = this.getAttributeValueString(attribute.name);
        if (attrValue.length > 0) {
          displayValues.push(attrValue);
        }
      }
      name = displayValues.join(' ');
    }

    return name;
  }

  public hasAttribute(attrName: string): boolean {
    return !!this.attrs[attrName];
  }

  public renderValues(): boolean {
    // we should layer info
    if (!this.layer?.layerInfo) {
      console.warn('no layer info');
      return false;
    }

    const attrMap = this.layer.layerInfo.attributes;
    for (let attrName in this.attrs) {
      const attr = this.attrs[attrName];
      attr.renderValue(attrMap);
    }

    return true;
  }

  public addAttribute(attribute: FIAttr): void {
    switch (attribute.name) {
      case 'guid':
        this._guid = attribute.value;
        break;
      case 'sub_levels':
        console.log('has levels');
        this._hasLevels = attribute.value === 'true' || attribute.value === 't' || parseInt(attribute.value) > 0;
        break;
      default:
        attribute.feature = this;
        this.attrs[attribute.name] = attribute;
        break;
    }
  }

  public getRawAttributeValue(attrName: string): string | null {
    const attr = this.attrs[attrName];
    if (attr) {
      return attr.value;
    }
    return null;
  }

  public getAttributeValue(attrName: string): string | null {
    const attr = this.attrs[attrName];
    if (attr) {
      return attr.displayValue;
    }
    return null;
  }

  public getAttributeValueString(attrName: string): string {
    const maybeNullValue = this.getAttributeValue(attrName);
    return maybeNullValue ? maybeNullValue : '';
  }

  /**
   * Returns the feature-identifying parameters for api calls.
   * If this feature is on an invalid layer (e.g. loaded from wms info until project generation) null is returned.
   */
  public getFeatureIdParams(): FeatureParams | null {
    if (!this.layer?.id) {
      console.log("layer " + this.layer?.name + " does not exist in project");
      return null;
    }

    // compile list of attributes to query
    const levelFeature = this.levelFeature;
    return {
      layerId: this.layer.id,
      fguid: this.guid,
      levelFeatureGUID: levelFeature ? levelFeature.feature.guid : undefined,
      levelId: levelFeature ? levelFeature.level.id : undefined,
      levelLayerId: levelFeature ? levelFeature.feature.layer.id : undefined
    };
  }

  public getFeatureImageURL(): string {
    // we need the visualization column for this to happen
    return this.layer ? this.layer.getFeatureImageURL(this) : '';
  }

  public getVisName(): string {
    return this.layer ? this.layer.getFeatureImageName(this) : '';
  }

  public getVisRawValue(): string {
    return this.layer ? this.layer.getFeatureImageRawValue(this) : '';
  }

  public copyAttributes(sourceFeature: FI) {
    this.attrs = {}; // reset attributes
    for (let attributeName in sourceFeature.attrs) {
      const attr = sourceFeature.attrs[attributeName];
      this.addAttribute(attr);
    }
    this.renderValues();
  }

  public getGroupedAttributes(): FIGroupDto[] {
    const groups: FIGroupDto[] = [];

    const layerInfo = this.layer?.layerInfo;
    if (!layerInfo) {
      console.warn('no grouping info for feature');
      return groups; // empty, we don't have any grouping info
    }
    for (let fiGroupInfo of layerInfo.fiGroups) {
      const group = new FIGroupDto(fiGroupInfo);
      const groupAttributes = fiGroupInfo.members;

      for (let groupAttributeName of groupAttributes) {
        // look in our attribute list for this attribute-name
        const fiAttr = this.attrs[groupAttributeName];
        if (fiAttr) {
          // add attribute to this group
          group.attributes.push(fiAttr);
        }
      }
      // add group to group list
      groups.push(group);
    }

    return groups;
  }

  /**
   * Constructs a new FI object from a GeoJSON collection item.
   * @param properties feature info properties
   * @param layer
   * @param levelFeature
   * @param api
   */
  public static fromGeoJSONCollection(properties, layer: Layer, levelFeature: LevelFeature, api: IGISApi): FI {
    const fi = new FI({guid: 'null', attrs: [], layerId: layer.id, wkt: ''}, api);
    fi.levelFeature = levelFeature;
    fi.layer = layer;
    for (let attribute in properties) {
      const value = properties[attribute];
      if (attribute == 'wkt') {
        fi.setWKT(value);
      } else {
        fi.addAttribute(new FIAttr({
          name: attribute,
          value
        }, api))
      }
    }
    return fi;
  }
}
