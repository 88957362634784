/**
 * Helper methods for sorting.
 */
interface IName {
  name: string;
}
interface IText {
  text: string;
}
interface ICategory {
  category: string;
  name: string;
}
interface IOrderNr {
  orderNr: number;
}

/**
 * Sort an array of objects by their lowercase <b>name</b> property.
 * Sorts umlauts correctly.
 * @param list
 */
export function sortByLCName(list: IName[]) {
  list.sort((ar: IName, br: IName): number => {
    let a = ar.name ? ar.name.toLowerCase() : '';
    a = a.replace(/ä/g, "a");
    a = a.replace(/ö/g, "o");
    a = a.replace(/ü/g, "u");
    a = a.replace(/ß/g, "s");

    let b = br.name ? br.name.toLowerCase() : '';
    b = b.replace(/ä/g, "a");
    b = b.replace(/ö/g, "o");
    b = b.replace(/ü/g, "u");
    b = b.replace(/ß/g, "s");

    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  });
}

/**
 * Sort an array of objects by their lowercase <b>name</b> property.
 * Sorts umlauts correctly.
 * @param list
 */
export function sortByLCText(list: IText[]) {
  list.sort((ar: IText, br: IText): number => {
    let a = ar.text.toLowerCase();
    a = a.replace(/ä/g, "a");
    a = a.replace(/ö/g, "o");
    a = a.replace(/ü/g, "u");
    a = a.replace(/ß/g, "s");

    let b = br.text.toLowerCase();
    b = b.replace(/ä/g, "a");
    b = b.replace(/ö/g, "o");
    b = b.replace(/ü/g, "u");
    b = b.replace(/ß/g, "s");

    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  });
}

/**
 * Sort an array of objects by their lowercase <b>category</b> property, and then by their name
 * Sorts umlauts correctly.
 * @param list
 */
export function sortByLCCategoryName(list: ICategory[]) {
  list.sort((ar: ICategory, br: ICategory): number => {
    let a = ar.category.toLowerCase();
    a = a.replace(/ä/g, "a");
    a = a.replace(/ö/g, "o");
    a = a.replace(/ü/g, "u");
    a = a.replace(/ß/g, "s");

    let b = br.category.toLowerCase();
    b = b.replace(/ä/g, "a");
    b = b.replace(/ö/g, "o");
    b = b.replace(/ü/g, "u");
    b = b.replace(/ß/g, "s");

    if (a > b) return 1;
    if (a < b) return -1;

    // sort by name next if categories are the same
    a = ar.name.toLowerCase();
    a = a.replace(/ä/g, "a");
    a = a.replace(/ö/g, "o");
    a = a.replace(/ü/g, "u");
    a = a.replace(/ß/g, "s");

    b = br.name.toLowerCase();
    b = b.replace(/ä/g, "a");
    b = b.replace(/ö/g, "o");
    b = b.replace(/ü/g, "u");
    b = b.replace(/ß/g, "s");

    if (a > b) return 1;
    if (a < b) return -1;

    return 0;
  });
}

export function sortByOrderNr(list: IOrderNr[]) {
  list.sort((ar: IOrderNr, br: IOrderNr): number => {
    return (ar.orderNr > br.orderNr) ? 1 : -1;
  });
}
