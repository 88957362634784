import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../igis-base";
import {ProjectInfo} from "@igis-common/model/ProjectInfo";
import {COMP_IDS} from "./GUIDefinitions";
import {DataSet} from "@igis-common/model/DataSet";
import {ExtMenuConfig} from "../../ext-types/menu/ExtMenu";
import {ExtMenuItemConfig} from "../../ext-types/menu/ExtMenuItem";
import {ExtButton} from "../../ext-types/button/ExtButton";
import {Layer} from "@igis-common/model/Layer";
import {sortByLCText} from "@igis-common/api/Util";
import {IGISConst} from "@igis-common/const";

export class DataSetMenuComponent extends Component {

  protected dataSetButton: ExtButton;
  protected stopDataEntryButton: ExtButton;

  protected projectInfo: ProjectInfo;

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {
    // wait for gui & project-info
    this.app.startup$.subscribe((data) => {
      const Ext = data.Ext;
      this.projectInfo = data.projectInfo;

      // get handle to dataset combo & data store
      this.dataSetButton = <ExtButton>Ext.getCmp(COMP_IDS.DATA_ENTRY.START);

      this.stopDataEntryButton = <ExtButton>Ext.getCmp(COMP_IDS.DATA_ENTRY.STOP);
      this.stopDataEntryButton.setHandler(this.onStopDataEntryClick, this);

      this.app.feature.selectDataSet$.subscribe((dataSet) => {
        if (dataSet) {
          this.stopDataEntryButton.setDisabled(false);
        } else {
          this.stopDataEntryButton.setDisabled(true);
        }
      })

      this.app.mapRoot$.subscribe(({curMap, rootLayer}) => {
        this.updateDataSetList(rootLayer);
      })
      this.app.layerVisChange$.subscribe(({curMap, rootLayer}) => {
        this.updateDataSetList(rootLayer);
      })
    })
  }

  public onDataSetClick(id: number): void {
    //const id = menuItem.id;
    const dataSet = this.projectInfo.getDataSetById(id);
    if (dataSet) {
      console.log('start data entry with id ' + id);
      this.app.startAddingDataEntry(dataSet, true);
    }
  }

  public onStopDataEntryClick(): void {
    console.log('stop data entry');
    this.app.feature.clearSelectedDataSet();
  }

  protected updateDataSetList(rootLayer: Layer): void {

    // get all layers associated with at least one dataset
    let layers = rootLayer.getDataSetChildren();

    // delete previous menu
    this.dataSetButton.setMenu(false);
    let menuConfig: ExtMenuConfig;

    let dsCnt = 0;

    // default values
    const emptyMenuItem: ExtMenuItemConfig = {
      xtype: 'menuitem',
      text: 'Keine Datenerfassungen verfügbar',
      disabled: true
    }

    menuConfig = {
      xtype: 'menu',
      items: [emptyMenuItem]
    }

    if (layers.length > 0) {

      const layerMenuItems: ExtMenuItemConfig[] = [];

      for (let layer of layers) {
        const menuItems: any = [];
        if (!layer.hasPermission(IGISConst.PERM_DATASET)) {
          continue;
        }
        for (let dataSet of layer.getFeatureDataSets()) {
          // create a new menu entry
          const menuItem: ExtMenuItemConfig = {
            xtype: 'menuitem',
            text: dataSet.name,
            handler: (item, event) => { this.onDataSetClick(dataSet.id);}
          }
          menuItems.push(menuItem);
          dsCnt++;
        }

        const layerMenuItem: ExtMenuItemConfig = {
          text: layer.name,
          menu: {
            xtype: 'menu',
            items: menuItems
          }
        }

        layerMenuItems.push(layerMenuItem);
      }

      sortByLCText(layerMenuItems);

      if (layerMenuItems.length > 0) {
        menuConfig = {
          xtype: 'menu',
          items: layerMenuItems
        }
      }
    }

    this.dataSetButton.setMenu(menuConfig);
  }

  protected updateOpenDataEntryCnt(curDataSet: DataSet): void {
    let intOpenMaintCnt = 0;
    if (curDataSet) {
      intOpenMaintCnt = curDataSet.openDataEntryCount;
    }

    // update permissions
    // get overlay for maintenance
    /*
    const layer = this.projectInfo.getLayerById(curDataSet.layerId);
    if (layer) {
      Ext.getCmp('AddMaintTool').disabled = !layer.hasPermission(IGISConst.PERM_DATASET);
      Ext.getCmp('FinishDataSet').disabled = !layer.hasPermission(IGISConst.PERM_DATASET);
    } else {
      Ext.getCmp('AddMaintTool').disabled = true;
      Ext.getCmp('FinishDataSet').disabled = true;
    }*/
    // TODO: re-enable me
  }

}
