import {FI} from "@igis-common/model/FI";
import {DataField} from "@igis-common/model/DataField";
import {DataEntryInputField} from "./DataEntryInputField";
import {IExt} from "../../../ext-types/Ext";
import {ExtFormFieldBase} from "../../../ext-types/form/field/ExtFormFieldBase";
import {DataEntryNumberField} from "./DataEntryNumberField";

export class DataEntryFloatField extends DataEntryNumberField {

  protected changeEventName = 'keypress';

  constructor(inputField: DataField, feature: FI, Ext: IExt) {

    super(inputField, feature, Ext);

    // create integer textbox
    const attrValue = this.getFeatureAttrValue(true);
    const cmp = {
      xtype: 'numberfield',
      value: attrValue,
      allowDecimals: true,
      enableKeyEvents: true
    };

    this.cmpValue = <ExtFormFieldBase>Ext.create(cmp);
  }
}
