
import {Option} from '../Option';
import {IGISApi} from "@igis-common/api/IGISApi";
import {Model} from "@igis-common/api/Model";

export class VisualizationColumnGen extends Model {
  protected _attributeName: string;
  protected _visOptions: Option[];

  constructor(json: {attributeName: string, visOptions: {name: string, value: string, visImage?: {uuid: string, filename: string, isPic: boolean, isPanorama: boolean, hasThumb: boolean, comment: string, href: string}}[]}, public _api: IGISApi) {
    super();
    this._attributeName = json.attributeName;
    this._visOptions =  json.visOptions.map(item => new Option(item, _api));
    this.onNew();
  }
}
