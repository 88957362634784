// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../desktop/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../desktop/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../desktop/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("img/resize-handle.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("img/move-handle.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.leaflet-marker-icon.print-extent.resize-marker {
    background: url( ${___CSS_LOADER_URL_REPLACEMENT_0___} ) no-repeat;
}
div.leaflet-marker-icon.print-extent.resize-marker-nwse {
    cursor: nwse-resize;
}
div.leaflet-marker-icon.print-extent.resize-marker-nesw {
    cursor: nesw-resize;
}
div.leaflet-marker-icon.print-extent.move-marker {
    background: url( ${___CSS_LOADER_URL_REPLACEMENT_1___} ) no-repeat;
    cursor: move;
}
`, "",{"version":3,"sources":["webpack://./../igis-common/src/leaflet/printextent/printextent.css"],"names":[],"mappings":"AAAA;IACI,+DAAkD;AACtD;AACA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,+DAAgD;IAChD,YAAY;AAChB","sourcesContent":["div.leaflet-marker-icon.print-extent.resize-marker {\n    background: url( img/resize-handle.png ) no-repeat;\n}\ndiv.leaflet-marker-icon.print-extent.resize-marker-nwse {\n    cursor: nwse-resize;\n}\ndiv.leaflet-marker-icon.print-extent.resize-marker-nesw {\n    cursor: nesw-resize;\n}\ndiv.leaflet-marker-icon.print-extent.move-marker {\n    background: url( img/move-handle.png ) no-repeat;\n    cursor: move;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
