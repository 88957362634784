import {Component} from "@igis-common/component/Component";
import {IGIS} from "../../../igis-base";
import {FI} from "@igis-common/model/FI";
import {StorageEntry} from "@igis-common/model/StorageEntry";
import {COMP_IDS} from "../GUIDefinitions";
import {ExtPanel, ExtPanelConfig} from "../../../ext-types/panel/ExtPanel";
import {ExtGridPanel, ExtGridPanelConfig} from "../../../ext-types/grid/ExtGridPanel";
import {ExtArrayStoreConfig} from "../../../ext-types/data/ExtArrayStore";
import {IExt} from "../../../ext-types/Ext";
import {ExtGridColumnConfig} from "../../../ext-types/grid/ExtGridColumn";
import {first} from "rxjs/operators";
import './feature-short-info.css';
import {Layer} from "@igis-common/model/Layer";

export class FeatureInfoDisplayComponent extends Component {

  private dataPanel: ExtPanel;
  private shortInfoPanel: ExtPanel;
  private Ext: IExt;
  private layer: Layer | null;

  constructor(protected app: IGIS) {
    super(app);
  }

  public init() {
    // wait for gui
    this.app.gui.gui$.pipe(first()).subscribe(() => {
      this.Ext = this.app.gui.Ext;

      // get handles to gui components
      this.dataPanel = <ExtPanel>this.Ext.getCmp(COMP_IDS.FI_PANEL);
      this.shortInfoPanel = <ExtPanel>this.Ext.getCmp(COMP_IDS.SHORT_INFO_PANEL);

      this.app.feature.updateSelectedFeature$.subscribe(feature => {
        if (feature) {
          // this is sadly very slow (ExtJS bug?), so we do this after all other handlers
          setTimeout(() => {
            this.displayFeatureData(feature.fi, this.Ext);
          }, 0);
        } else {
          this.displayFeatureData(null, this.Ext);
        }
      })
    })
  }

  protected displayFeatureData(feature: FI | null, Ext: IExt): void {

    this.dataPanel.removeAll(); // delete data

    if (!feature) {
      this.layer = null;
      this.shortInfoPanel.setHtml('');
      return;
    }

    this.shortInfoPanel.setHtml(this.renderShortInfo(feature));

    // group it!
    const grouping = feature.getGroupedAttributes();
    const panelConfigs: any[] = [];

    let indexToSelect = -1;

    this.layer = feature.layer;

    grouping.forEach((group, index) => {
      const groupName = group.name;
      const attributes = group.attributes;

      // is this group equal to the last selected group?
      if (feature.layer?.isLastOpenFIGroup(group.fiGroup)) {
        indexToSelect = index;
      }

      // create a new array from the attribute data
      // TODO: find a way to read directly from attributes array via a ExtJS-store/reader?
      const attrArray: [string, string, boolean, string, boolean][] = [];
      for (let attribute of attributes) {
        const infoString = attribute.infoString;
        const hasInfo = infoString.length > 0 || attribute.searchable;
        attrArray.push([attribute.displayName, attribute.displayValue, hasInfo, infoString, attribute.searchable]);
      }

      const attrStoreConfig: ExtArrayStoreConfig = {
        xtype: 'arraystore',
        data: attrArray,
        fields: ['Name', 'Wert', 'hasInfo', 'infoString', 'searchable']
      };

      // create a panel with a grid for every group
      const items: ExtGridPanelConfig = {
        xtype: 'gridpanel',
        autoDestroy: true,
        store: attrStoreConfig,
        enableColumnHide: false,
        enableColumnsMove: false,
        layout: 'fit',
        columns: [{
          text: 'Name',
          dataIndex: 'Name',
          flex: 1,
          sortable: false
        }, {
          text: 'Wert',
          dataIndex: 'Wert',
          flex: 1,
          cellWrap: true,
          sortable: false
        }, {
          text: '',
          width: 30,
          dataIndex: 'hasInfo',
          renderer: function (hasInfo, metaData, record, rowIdx, colIdx, store) {
            if (hasInfo) {
              const infoString = record.get('infoString');
              const searchable = record.get('searchable');
              let output = '';
              if (infoString.length > 0) {
                output = Ext.util.Format.htmlEncode(infoString);
                if (searchable) {
                  output += '<br>';
                }
              }
              if (searchable) {
                output += 'Dieses Feld wird bei der Suche miteinbezogen.';
              }
              return '<i width="20px" class="fa-duotone fa-circle-info" data-qtitle="Feldinfo" data-qtip="<p>' + output + '</p>" />';
            }
            return '';
          },
          resizable: false,
          sortable: false
        }]
      }
      const groupPanelConfig: ExtPanelConfig = {
        xtype: 'panel',
        title: groupName,
        layout: 'fit',
        height: '100%',
        items: [items],
        listeners: {
          expand: function () { // every time an accordion panel is opened: save this panel as the last opened for this layer
            feature.layer?.setLastOpenFIGroup(group.fiGroup);
          }
        },
      }

      panelConfigs.push(groupPanelConfig);
    });

    this.dataPanel.add(panelConfigs);
    if (indexToSelect != -1) {
      // @ts-ignore
      this.dataPanel.items.getAt(indexToSelect).expand();
    }
  }

  /**
   * Render the table for displaying feature short info.
   * @param feature
   * @protected
   */
  protected renderShortInfo(feature: FI): string {
    let r = '<div class="f-short-info">';
    // first: add layer name in a larger font

    const layerName = feature.layer?.nameSingular;
    r += "<div>" + layerName + "</div>";
    r += '<div style="clear: both;"></div>';
    r += '<hr>';

    // add the table with the short-info
    r += '<table class="f-short-info">';

    const attributes = feature.layer?.shortInfoAttributes;
    if (attributes) {
      for (let attribute of attributes) {
        const attributeName = attribute.name;
        if (!feature.hasAttribute(attributeName)) {
          continue;
        }
        const attrValue = feature.getAttributeValueString(attributeName);
        r += '<tr><td valign="bottom">' + attribute.displayName + '</td>';
        r += '<td>' + attrValue + '</td>';
        r += '</tr>';
      }
    }

    r += '</table>';
    r += '</div>';
    return r;
  }
}
