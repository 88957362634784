

export type ValueMap = Record<number, any>;

export class Model {
  /**
   * Called in generated API code after the object is instantiated from the JSON result.
   * Can be overridden to fetch objects just given by id, etc.
   */
  protected onNew() {}

  /**
   * Evaluates the given js code (parameter: fieldValues)
   * Default return value (on error,..) is true
   * @param fieldValues
   * @param conditionCode a js function body
   */
  static evalCond(fieldValues: ValueMap, conditionCode: string): boolean {
    if (conditionCode == null || conditionCode.length == 0) {
      return true;
    }
    const condMethod = new Function('fieldValues', "'use strict'; " + conditionCode);

    // call the dynamic method
    let val = condMethod(fieldValues);
    if (val == null) {
      return true;
    } else {
      return val; // cast to boolean?
    }
  }


}
